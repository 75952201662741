<template>
  <metainfo/>
  <div
      class="flex flex-col md:flex-row justify-between
             py-2
             bg-white
             shadow-md
             sticky top-0 z-40"
  >
    <main-navigation class="hidden md:flex"/>
    <form @submit.prevent="search" class="p-2 flex flex-row">
      <input
          type="text"
          placeholder="Suche&hellip;"
          class="flex-grow md:flex-grow-0 rounded-md"
          v-model="searchKeyword"
      >
      <button
          type="submit"
          class="rounded-md bg-blue-500 text-white ml-1 py-2 px-3"
      >
        <SearchIcon class="w-5 h-5"/>
      </button>
    </form>
  </div>

  <div
      v-if="!developmentWarningDismissed"
      class="fixed top-0 right-0 bottom-0 left-0
             bg-gray-200 bg-opacity-80
             backdrop-filter backdrop-blur backdrop-sepia
             flex flex-col justify-center items-center
             z-50"
  >
    <div class="bg-white rounded text-red-700 text-center p-5">
      <strong>Achtung!</strong><br>
      Dies ist eine Entwicklungsversion, die der Vorschau und stetigen Verbesserung dient!<br>
      Sei gewarnt: Manche Funktionen fehlen und mit Fehlerverhalten ist zu rechnen!<br>
      <button
          @click="developmentWarningDismissed = true"
          class="bg-blue-500 text-white p-2 rounded mt-3"
      >
        Okay, zeig mir das Projekt!
      </button>
    </div>
  </div>

  <router-view class="mb-16"/>

  <main-tabs-navigation class="flex md:hidden
                               bg-white
                               fixed left-0 right-0 bottom-0 w-full
                               bg-gradient-to-b from-gray-100 to-gray-200
                               z-40"/>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from 'vue';
import MainNavigation from "@/components/MainNavigation.vue";
import MainTabsNavigation from "@/components/MainTabsNavigation.vue";
import {SearchIcon} from "@heroicons/vue/outline";
import {useRoute, useRouter} from "vue-router";
import {useMeta} from "vue-meta";

export default defineComponent({
  name: 'App',
  components: {
    MainNavigation,
    MainTabsNavigation,
    SearchIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const developmentWarningDismissed = ref<boolean>(false);

    try {
      developmentWarningDismissed.value = !!sessionStorage.getItem("DEVELOPMENT_WARNING_DISMISSED")
    } catch (e) {
      console.warn("Failed to read session storage", e);
    }

    watch(developmentWarningDismissed, newValue => {
      try {
        sessionStorage.setItem("DEVELOPMENT_WARNING_DISMISSED", `${newValue}`);
      } catch (e) {
        console.warn("Failed to write to session storage", e);
      }
    });

    const searchKeywordQuery = computed<string | null>(() => {
      const q = route.query.q;
      if (Array.isArray(q)) {
        return q[0] || "";
      } else {
        return q || "";
      }
    });

    const searchKeyword = ref<string | null>(searchKeywordQuery.value);

    watch(searchKeywordQuery, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        searchKeyword.value = newValue;
      }
    });

    useMeta({
      title: 'Taktische-Zeichen.org'
    });

    const search = function() {
      const q = searchKeyword.value;
      const l = undefined;
      const path = route.path;
      const params = route.params;
      const query = Object.assign({}, route.query, {q, l});
      router.push({path, params, query});
    };

    return {
      developmentWarningDismissed,
      searchKeywordQuery,
      searchKeyword,
      search
    };
  }
});
</script>
