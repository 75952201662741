import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {createMetaManager, plugin as metaPlugin} from 'vue-meta';
import './main.css';

const metaManager = createMetaManager();

createApp(App)
    .use(store)
    .use(router)
    .use(metaManager)
    .use(metaPlugin)
    .mount('#app')
