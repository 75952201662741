
import {defineComponent} from 'vue';
import {SearchIcon, PrinterIcon} from "@heroicons/vue/outline";

export default defineComponent({
  name: 'MainNavigation',
  components: {
    SearchIcon,
    PrinterIcon,
  }
});
