export interface Author {
  id: string;
  name: string;
  sourceUrl: string;
  license: string;
}

export interface Size {
  width: number;
  height: number;
}

export interface File {
    path: string;
    fileType: string;
    size?: Size;
}

export interface TacticalSymbol {
  id: string;
  group: string;
  name: string;
  author: Author;
  sizes: File[];
}

export const symbols: {[key: string]: TacticalSymbol} = {
  "jonas-koeritz:thw-einheiten:1-bergungsgruppe" : {
    "id" : "jonas-koeritz:thw-einheiten:1-bergungsgruppe",
    "group" : "THW Einheiten",
    "name" : "1. Bergungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/1._Bergungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/1._Bergungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/1._Bergungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/1._Bergungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:1-bergungsgruppe-ash" : {
    "id" : "jonas-koeritz:thw-einheiten:1-bergungsgruppe-ash",
    "group" : "THW Einheiten",
    "name" : "1. Bergungsgruppe ASH",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/1._Bergungsgruppe_ASH.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/1._Bergungsgruppe_ASH.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/1._Bergungsgruppe_ASH.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/1._Bergungsgruppe_ASH.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:1-bergungsgruppe-egs" : {
    "id" : "jonas-koeritz:thw-einheiten:1-bergungsgruppe-egs",
    "group" : "THW Einheiten",
    "name" : "1. Bergungsgruppe EGS",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/1._Bergungsgruppe_EGS.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/1._Bergungsgruppe_EGS.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/1._Bergungsgruppe_EGS.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/1._Bergungsgruppe_EGS.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:2-bergungsgruppe" : {
    "id" : "jonas-koeritz:thw-einheiten:2-bergungsgruppe",
    "group" : "THW Einheiten",
    "name" : "2. Bergungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/2._Bergungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/2._Bergungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/2._Bergungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/2._Bergungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:2-bergungsgruppe-a" : {
    "id" : "jonas-koeritz:thw-einheiten:2-bergungsgruppe-a",
    "group" : "THW Einheiten",
    "name" : "2. Bergungsgruppe A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/2._Bergungsgruppe_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/2._Bergungsgruppe_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/2._Bergungsgruppe_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/2._Bergungsgruppe_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:2-bergungsgruppe-b" : {
    "id" : "jonas-koeritz:thw-einheiten:2-bergungsgruppe-b",
    "group" : "THW Einheiten",
    "name" : "2. Bergungsgruppe B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/2._Bergungsgruppe_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/2._Bergungsgruppe_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/2._Bergungsgruppe_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/2._Bergungsgruppe_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:abc-erkundungsgruppe" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:abc-erkundungsgruppe",
    "group" : "Feuerwehr Einheiten",
    "name" : "ABC Erkundungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/ABC_Erkundungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/ABC_Erkundungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/ABC_Erkundungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/ABC_Erkundungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:awitel-" : {
    "id" : "jonas-koeritz:fernmeldewesen:awitel-",
    "group" : "Fernmeldewesen",
    "name" : "AWITEL-",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/AWITEL-.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/AWITEL-.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/AWITEL-.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/AWITEL-.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:awitel-awitel" : {
    "id" : "jonas-koeritz:fernmeldewesen:awitel-awitel",
    "group" : "Fernmeldewesen",
    "name" : "AWITEL-AWITEL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/AWITEL-AWITEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/AWITEL-AWITEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/AWITEL-AWITEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/AWITEL-AWITEL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:awitel-funkbrucke" : {
    "id" : "jonas-koeritz:fernmeldewesen:awitel-funkbrucke",
    "group" : "Fernmeldewesen",
    "name" : "AWITEL-Funkbrücke",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/AWITEL-Funkbrücke.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/AWITEL-Funkbrücke.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/AWITEL-Funkbrücke.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/AWITEL-Funkbrücke.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:awitel-funkbrucke-2m" : {
    "id" : "jonas-koeritz:fernmeldewesen:awitel-funkbrucke-2m",
    "group" : "Fernmeldewesen",
    "name" : "AWITEL-Funkbrücke-2m",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/AWITEL-Funkbrücke-2m.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/AWITEL-Funkbrücke-2m.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/AWITEL-Funkbrücke-2m.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/AWITEL-Funkbrücke-2m.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:awitel-funkbrucke-4m" : {
    "id" : "jonas-koeritz:fernmeldewesen:awitel-funkbrucke-4m",
    "group" : "Fernmeldewesen",
    "name" : "AWITEL-Funkbrücke-4m",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/AWITEL-Funkbrücke-4m.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/AWITEL-Funkbrücke-4m.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/AWITEL-Funkbrücke-4m.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/AWITEL-Funkbrücke-4m.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:awitel-ob" : {
    "id" : "jonas-koeritz:fernmeldewesen:awitel-ob",
    "group" : "Fernmeldewesen",
    "name" : "AWITEL-OB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/AWITEL-OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/AWITEL-OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/AWITEL-OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/AWITEL-OB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:awitel-telefon" : {
    "id" : "jonas-koeritz:fernmeldewesen:awitel-telefon",
    "group" : "Fernmeldewesen",
    "name" : "AWITEL-Telefon",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/AWITEL-Telefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/AWITEL-Telefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/AWITEL-Telefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/AWITEL-Telefon.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:abholpunkt" : {
    "id" : "jonas-koeritz:fernmeldewesen:abholpunkt",
    "group" : "Fernmeldewesen",
    "name" : "Abholpunkt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Abholpunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Abholpunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Abholpunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Abholpunkt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:abrollbehalter-container" : {
    "id" : "jonas-koeritz:fahrzeuge:abrollbehalter-container",
    "group" : "Fahrzeuge",
    "name" : "Abrollbehälter Container",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Abrollbehälter_Container.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Abrollbehälter_Container.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Abrollbehälter_Container.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Abrollbehälter_Container.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:kommunal-fahrzeuge:abrollbehalter-container" : {
    "id" : "jonas-koeritz:kommunal-fahrzeuge:abrollbehalter-container",
    "group" : "Kommunal Fahrzeuge",
    "name" : "Abrollbehälter Container",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Kommunal_Fahrzeuge/Abrollbehälter_Container.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Kommunal_Fahrzeuge/Abrollbehälter_Container.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Kommunal_Fahrzeuge/Abrollbehälter_Container.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Kommunal_Fahrzeuge/Abrollbehälter_Container.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:abrollbehalter-container" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:abrollbehalter-container",
    "group" : "THW Fahrzeuge",
    "name" : "Abrollbehälter Container",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Abrollbehälter_Container.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Abrollbehälter_Container.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Abrollbehälter_Container.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Abrollbehälter_Container.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:abschnittsfuhrungsstelle" : {
    "id" : "jonas-koeritz:fuhrungsstellen:abschnittsfuhrungsstelle",
    "group" : "Führungsstellen",
    "name" : "Abschnittsführungsstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/Abschnittsführungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/Abschnittsführungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/Abschnittsführungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/Abschnittsführungsstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:abschnittsleiter-betreuung" : {
    "id" : "jonas-koeritz:rettungswesen-personen:abschnittsleiter-betreuung",
    "group" : "Rettungswesen Personen",
    "name" : "Abschnittsleiter Betreuung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Abschnittsleiter_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Abschnittsleiter_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Abschnittsleiter_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Abschnittsleiter_Betreuung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:abschnittsleiter-feuerwehr" : {
    "id" : "jonas-koeritz:feuerwehr-personen:abschnittsleiter-feuerwehr",
    "group" : "Feuerwehr Personen",
    "name" : "Abschnittsleiter Feuerwehr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Abschnittsleiter_Feuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Abschnittsleiter_Feuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Abschnittsleiter_Feuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Abschnittsleiter_Feuerwehr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:abschnittsleiter-rettungsdienst" : {
    "id" : "jonas-koeritz:rettungswesen-personen:abschnittsleiter-rettungsdienst",
    "group" : "Rettungswesen Personen",
    "name" : "Abschnittsleiter Rettungsdienst",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Abschnittsleiter_Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Abschnittsleiter_Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Abschnittsleiter_Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Abschnittsleiter_Rettungsdienst.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:abschnittsleiter-thw" : {
    "id" : "jonas-koeritz:thw-personen:abschnittsleiter-thw",
    "group" : "THW Personen",
    "name" : "Abschnittsleiter THW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Abschnittsleiter_THW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Abschnittsleiter_THW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Abschnittsleiter_THW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Abschnittsleiter_THW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:abschnittsleiter-technik-und-sicherheit" : {
    "id" : "jonas-koeritz:rettungswesen-personen:abschnittsleiter-technik-und-sicherheit",
    "group" : "Rettungswesen Personen",
    "name" : "Abschnittsleiter Technik und Sicherheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Abschnittsleiter_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Abschnittsleiter_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Abschnittsleiter_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Abschnittsleiter_Technik_und_Sicherheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:abschnittsleiter-verpflegung" : {
    "id" : "jonas-koeritz:rettungswesen-personen:abschnittsleiter-verpflegung",
    "group" : "Rettungswesen Personen",
    "name" : "Abschnittsleiter Verpflegung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Abschnittsleiter_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Abschnittsleiter_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Abschnittsleiter_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Abschnittsleiter_Verpflegung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:akute-gefahr" : {
    "id" : "jonas-koeritz:gefahren:akute-gefahr",
    "group" : "Gefahren",
    "name" : "Akute Gefahr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Akute_Gefahr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Akute_Gefahr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Akute_Gefahr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Akute_Gefahr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:akute-gefahr-durch-explosion" : {
    "id" : "jonas-koeritz:gefahren:akute-gefahr-durch-explosion",
    "group" : "Gefahren",
    "name" : "Akute Gefahr durch Explosion",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Akute_Gefahr_durch_Explosion.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Akute_Gefahr_durch_Explosion.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Akute_Gefahr_durch_Explosion.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Akute_Gefahr_durch_Explosion.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:akute-gefahr-durch-explosivstoffe" : {
    "id" : "jonas-koeritz:gefahren:akute-gefahr-durch-explosivstoffe",
    "group" : "Gefahren",
    "name" : "Akute Gefahr durch Explosivstoffe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Akute_Gefahr_durch_Explosivstoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Akute_Gefahr_durch_Explosivstoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Akute_Gefahr_durch_Explosivstoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Akute_Gefahr_durch_Explosivstoffe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:akute-gefahr-durch-gas" : {
    "id" : "jonas-koeritz:gefahren:akute-gefahr-durch-gas",
    "group" : "Gefahren",
    "name" : "Akute Gefahr durch Gas",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Akute_Gefahr_durch_Gas.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Akute_Gefahr_durch_Gas.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Akute_Gefahr_durch_Gas.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Akute_Gefahr_durch_Gas.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:akute-gefahr-durch-luftmangel" : {
    "id" : "jonas-koeritz:gefahren:akute-gefahr-durch-luftmangel",
    "group" : "Gefahren",
    "name" : "Akute Gefahr durch Luftmangel",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Akute_Gefahr_durch_Luftmangel.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Akute_Gefahr_durch_Luftmangel.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Akute_Gefahr_durch_Luftmangel.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Akute_Gefahr_durch_Luftmangel.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:akute-gefahr-durch-mineralol" : {
    "id" : "jonas-koeritz:gefahren:akute-gefahr-durch-mineralol",
    "group" : "Gefahren",
    "name" : "Akute Gefahr durch Mineralöl",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Akute_Gefahr_durch_Mineralöl.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Akute_Gefahr_durch_Mineralöl.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Akute_Gefahr_durch_Mineralöl.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Akute_Gefahr_durch_Mineralöl.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:akute-gefahr-durch-radioaktivitat" : {
    "id" : "jonas-koeritz:gefahren:akute-gefahr-durch-radioaktivitat",
    "group" : "Gefahren",
    "name" : "Akute Gefahr durch Radioaktivität",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Akute_Gefahr_durch_Radioaktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Akute_Gefahr_durch_Radioaktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Akute_Gefahr_durch_Radioaktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Akute_Gefahr_durch_Radioaktivität.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:akute-gefahr-durch-wassereinbruch" : {
    "id" : "jonas-koeritz:gefahren:akute-gefahr-durch-wassereinbruch",
    "group" : "Gefahren",
    "name" : "Akute Gefahr durch Wassereinbruch",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Akute_Gefahr_durch_Wassereinbruch.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Akute_Gefahr_durch_Wassereinbruch.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Akute_Gefahr_durch_Wassereinbruch.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Akute_Gefahr_durch_Wassereinbruch.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:akute-gefahr-durch-elektrischen-strom" : {
    "id" : "jonas-koeritz:gefahren:akute-gefahr-durch-elektrischen-strom",
    "group" : "Gefahren",
    "name" : "Akute Gefahr durch elektrischen Strom",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Akute_Gefahr_durch_elektrischen_Strom.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Akute_Gefahr_durch_elektrischen_Strom.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Akute_Gefahr_durch_elektrischen_Strom.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Akute_Gefahr_durch_elektrischen_Strom.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:akute-gefahr-durch-gefahrliche-stoffe" : {
    "id" : "jonas-koeritz:gefahren:akute-gefahr-durch-gefahrliche-stoffe",
    "group" : "Gefahren",
    "name" : "Akute Gefahr durch gefährliche Stoffe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Akute_Gefahr_durch_gefährliche_Stoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Akute_Gefahr_durch_gefährliche_Stoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Akute_Gefahr_durch_gefährliche_Stoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Akute_Gefahr_durch_gefährliche_Stoffe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:alters-und-ehrengruppe" : {
    "id" : "jonas-koeritz:thw-einheiten:alters-und-ehrengruppe",
    "group" : "THW Einheiten",
    "name" : "Alters und Ehrengruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Alters_und_Ehrengruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Alters_und_Ehrengruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Alters_und_Ehrengruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Alters_und_Ehrengruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:angeschlagen" : {
    "id" : "jonas-koeritz:schaden:angeschlagen",
    "group" : "Schäden",
    "name" : "Angeschlagen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Angeschlagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Angeschlagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Angeschlagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Angeschlagen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:angeschlagener-raum" : {
    "id" : "jonas-koeritz:schaden:angeschlagener-raum",
    "group" : "Schäden",
    "name" : "Angeschlagener Raum",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Angeschlagener_Raum.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Angeschlagener_Raum.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Angeschlagener_Raum.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Angeschlagener_Raum.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:angeschlagener-raum-halber-raum" : {
    "id" : "jonas-koeritz:schaden:angeschlagener-raum-halber-raum",
    "group" : "Schäden",
    "name" : "Angeschlagener Raum Halber Raum",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Angeschlagener_Raum_Halber_Raum.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Angeschlagener_Raum_Halber_Raum.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Angeschlagener_Raum_Halber_Raum.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Angeschlagener_Raum_Halber_Raum.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:angeschlagener-raum-schwalbennest" : {
    "id" : "jonas-koeritz:schaden:angeschlagener-raum-schwalbennest",
    "group" : "Schäden",
    "name" : "Angeschlagener Raum Schwalbennest",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Angeschlagener_Raum_Schwalbennest.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Angeschlagener_Raum_Schwalbennest.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Angeschlagener_Raum_Schwalbennest.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Angeschlagener_Raum_Schwalbennest.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:angriffstruppfuhrer" : {
    "id" : "jonas-koeritz:feuerwehr-personen:angriffstruppfuhrer",
    "group" : "Feuerwehr Personen",
    "name" : "Angriffstruppführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Angriffstruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Angriffstruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Angriffstruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Angriffstruppführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:angriffstruppmann" : {
    "id" : "jonas-koeritz:feuerwehr-personen:angriffstruppmann",
    "group" : "Feuerwehr Personen",
    "name" : "Angriffstruppmann",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Angriffstruppmann.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Angriffstruppmann.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Angriffstruppmann.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Angriffstruppmann.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:anhanger" : {
    "id" : "jonas-koeritz:fahrzeuge:anhanger",
    "group" : "Fahrzeuge",
    "name" : "Anhänger",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Anhänger.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:polizei-fahrzeuge:anhanger" : {
    "id" : "jonas-koeritz:polizei-fahrzeuge:anhanger",
    "group" : "Polizei Fahrzeuge",
    "name" : "Anhänger",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Polizei_Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Polizei_Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Polizei_Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Polizei_Fahrzeuge/Anhänger.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:kommunal-fahrzeuge:anhanger" : {
    "id" : "jonas-koeritz:kommunal-fahrzeuge:anhanger",
    "group" : "Kommunal Fahrzeuge",
    "name" : "Anhänger",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Kommunal_Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Kommunal_Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Kommunal_Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Kommunal_Fahrzeuge/Anhänger.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-0-5t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-0-5t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger 0 5t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_0_5t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_0_5t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_0_5t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_0_5t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-2t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-2t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger 2t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_2t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-6t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-6t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger 6t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_6t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_6t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_6t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_6t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-7t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-7t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger 7t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_7t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_7t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_7t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_7t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-dle" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-dle",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger DLE",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_DLE.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_DLE.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_DLE.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_DLE.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-fkh" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-fkh",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger FKH",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_FKH.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_FKH.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_FKH.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_FKH.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-fula" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-fula",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger FüLa",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_FüLa.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_FüLa.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_FüLa.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_FüLa.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-i-2t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-i-2t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger I 2t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_I_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_I_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_I_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_I_2t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-k-1t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-k-1t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger K 1t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_K_1t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_K_1t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_K_1t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_K_1t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-lichtmast" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-lichtmast",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger Lichtmast",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_Lichtmast.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_Lichtmast.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_Lichtmast.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_Lichtmast.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-mzab" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-mzab",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger MzAB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_MzAB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_MzAB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_MzAB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_MzAB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-mzb" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-mzb",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger MzB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_MzB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_MzB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_MzB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_MzB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-mzpt" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-mzpt",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger MzPt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_MzPt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_MzPt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_MzPt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_MzPt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-nea-200kva" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-nea-200kva",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger NEA 200kVA",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_NEA_200kVA.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_NEA_200kVA.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_NEA_200kVA.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_NEA_200kVA.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-nea-50kva-lima" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-nea-50kva-lima",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger NEA 50kVA LiMa",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_NEA_50kVA_LiMa.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_NEA_50kVA_LiMa.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_NEA_50kVA_LiMa.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_NEA_50kVA_LiMa.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-nea-650kva" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-nea-650kva",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger NEA 650kVA",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_NEA_650kVA.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_NEA_650kVA.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_NEA_650kVA.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_NEA_650kVA.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-netzersatzanlage" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-netzersatzanlage",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger Netzersatzanlage",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_Netzersatzanlage.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_Netzersatzanlage.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_Netzersatzanlage.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_Netzersatzanlage.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-o-a-2t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-o-a-2t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger O A 2t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_O_A_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_O_A_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_O_A_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_O_A_2t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-o-b-2t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-o-b-2t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger O B 2t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_O_B_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_O_B_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_O_B_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_O_B_2t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-pf-12t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-pf-12t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger PF 12t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_PF_12t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_PF_12t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_PF_12t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_PF_12t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-rettungshunde" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-rettungshunde",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger Rettungshunde",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_Rettungshunde.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_Rettungshunde.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_Rettungshunde.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_Rettungshunde.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-ru12t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-ru12t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger Ru12t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_Ru12t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_Ru12t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_Ru12t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_Ru12t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-schlb" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-schlb",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger SchlB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_SchlB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_SchlB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_SchlB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_SchlB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-sp-2t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-sp-2t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger Sp 2t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_Sp_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_Sp_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_Sp_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_Sp_2t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-spul" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-spul",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger Spül",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_Spül.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_Spül.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_Spül.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_Spül.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-stromerzeuger" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-stromerzeuger",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger Stromerzeuger",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_Stromerzeuger.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_Stromerzeuger.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_Stromerzeuger.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_Stromerzeuger.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-swpu-15000" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-swpu-15000",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger SwPu 15000",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_SwPu_15000.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_SwPu_15000.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_SwPu_15000.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_SwPu_15000.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-swpu-25000" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-swpu-25000",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger SwPu 25000",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_SwPu_25000.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_SwPu_25000.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_SwPu_25000.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_SwPu_25000.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-swpu-5000" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-swpu-5000",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger SwPu 5000",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_SwPu_5000.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_SwPu_5000.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_SwPu_5000.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_SwPu_5000.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-twaa" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-twaa",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger TWAA",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_TWAA.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_TWAA.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_TWAA.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_TWAA.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-tieflader-18t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-tieflader-18t",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger Tieflader 18t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_Tieflader_18t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_Tieflader_18t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_Tieflader_18t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_Tieflader_18t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-werkstatt" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-werkstatt",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger Werkstatt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_Werkstatt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_Werkstatt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_Werkstatt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_Werkstatt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:anhanger-kl-boot" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:anhanger-kl-boot",
    "group" : "THW Fahrzeuge",
    "name" : "Anhänger kl Boot",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Anhänger_kl_Boot.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Anhänger_kl_Boot.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Anhänger_kl_Boot.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Anhänger_kl_Boot.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:anschlusspunkt-1" : {
    "id" : "jonas-koeritz:fernmeldewesen:anschlusspunkt-1",
    "group" : "Fernmeldewesen",
    "name" : "Anschlusspunkt-1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Anschlusspunkt-1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Anschlusspunkt-1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Anschlusspunkt-1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Anschlusspunkt-1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne",
    "group" : "Fernmeldewesen",
    "name" : "Antenne",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-bake" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-bake",
    "group" : "Fernmeldewesen",
    "name" : "Antenne Bake",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_Bake.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_Bake.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_Bake.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_Bake.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-empfang" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-empfang",
    "group" : "Fernmeldewesen",
    "name" : "Antenne Empfang",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_Empfang.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_Empfang.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_Empfang.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_Empfang.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-peil" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-peil",
    "group" : "Fernmeldewesen",
    "name" : "Antenne Peil",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_Peil.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_Peil.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_Peil.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_Peil.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-peiler" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-peiler",
    "group" : "Fernmeldewesen",
    "name" : "Antenne Peiler",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_Peiler.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_Peiler.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_Peiler.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_Peiler.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-pol-h" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-pol-h",
    "group" : "Fernmeldewesen",
    "name" : "Antenne Pol H",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_Pol_H.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_Pol_H.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_Pol_H.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_Pol_H.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-pol-v" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-pol-v",
    "group" : "Fernmeldewesen",
    "name" : "Antenne Pol V",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_Pol_V.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_Pol_V.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_Pol_V.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_Pol_V.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-pol-zirk" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-pol-zirk",
    "group" : "Fernmeldewesen",
    "name" : "Antenne Pol Zirk",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_Pol_Zirk.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_Pol_Zirk.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_Pol_Zirk.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_Pol_Zirk.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-richt" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-richt",
    "group" : "Fernmeldewesen",
    "name" : "Antenne Richt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_Richt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_Richt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_Richt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_Richt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-se-gleichzeitig" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-se-gleichzeitig",
    "group" : "Fernmeldewesen",
    "name" : "Antenne SE Gleichzeitig",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_SE_Gleichzeitig.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_SE_Gleichzeitig.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_SE_Gleichzeitig.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_SE_Gleichzeitig.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-se-wechselnd" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-se-wechselnd",
    "group" : "Fernmeldewesen",
    "name" : "Antenne SE Wechselnd",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_SE_Wechselnd.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_SE_Wechselnd.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_SE_Wechselnd.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_SE_Wechselnd.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:antenne-sende" : {
    "id" : "jonas-koeritz:fernmeldewesen:antenne-sende",
    "group" : "Fernmeldewesen",
    "name" : "Antenne Sende",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Antenne_Sende.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Antenne_Sende.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Antenne_Sende.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Antenne_Sende.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einrichtungen:arztbesetzte-patientenablage" : {
    "id" : "jonas-koeritz:rettungswesen-einrichtungen:arztbesetzte-patientenablage",
    "group" : "Rettungswesen Einrichtungen",
    "name" : "Arztbesetzte Patientenablage",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einrichtungen/Arztbesetzte_Patientenablage.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einrichtungen/Arztbesetzte_Patientenablage.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einrichtungen/Arztbesetzte_Patientenablage.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einrichtungen/Arztbesetzte_Patientenablage.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-gebaude:ausbildungszentrum" : {
    "id" : "jonas-koeritz:thw-gebaude:ausbildungszentrum",
    "group" : "THW Gebäude",
    "name" : "Ausbildungszentrum",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Gebäude/Ausbildungszentrum.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Gebäude/Ausbildungszentrum.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Gebäude/Ausbildungszentrum.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Gebäude/Ausbildungszentrum.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:ausgangspunkt" : {
    "id" : "jonas-koeritz:sonstiges:ausgangspunkt",
    "group" : "Sonstiges",
    "name" : "Ausgangspunkt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Ausgangspunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Ausgangspunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Ausgangspunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Ausgangspunkt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:ausgefullter-raum" : {
    "id" : "jonas-koeritz:schaden:ausgefullter-raum",
    "group" : "Schäden",
    "name" : "Ausgefüllter Raum",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Ausgefüllter_Raum.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Ausgefüllter_Raum.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Ausgefüllter_Raum.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Ausgefüllter_Raum.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:ausgefullter-raum-schichtung" : {
    "id" : "jonas-koeritz:schaden:ausgefullter-raum-schichtung",
    "group" : "Schäden",
    "name" : "Ausgefüllter Raum Schichtung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Ausgefüllter_Raum_Schichtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Ausgefüllter_Raum_Schichtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Ausgefüllter_Raum_Schichtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Ausgefüllter_Raum_Schichtung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:ausgefullter-raum-wasser" : {
    "id" : "jonas-koeritz:schaden:ausgefullter-raum-wasser",
    "group" : "Schäden",
    "name" : "Ausgefüllter Raum Wasser",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Ausgefüllter_Raum_Wasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Ausgefüllter_Raum_Wasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Ausgefüllter_Raum_Wasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Ausgefüllter_Raum_Wasser.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:br-stab" : {
    "id" : "jonas-koeritz:thw-einheiten:br-stab",
    "group" : "THW Einheiten",
    "name" : "BR Stab",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/BR_Stab.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/BR_Stab.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/BR_Stab.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/BR_Stab.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:br500" : {
    "id" : "jonas-koeritz:fuhrungsstellen:br500",
    "group" : "Führungsstellen",
    "name" : "BR500",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/BR500.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/BR500.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/BR500.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/BR500.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:basisstation" : {
    "id" : "jonas-koeritz:fernmeldewesen:basisstation",
    "group" : "Fernmeldewesen",
    "name" : "Basisstation",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Basisstation.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Basisstation.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Basisstation.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Basisstation.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:basisstation-dect" : {
    "id" : "jonas-koeritz:fernmeldewesen:basisstation-dect",
    "group" : "Fernmeldewesen",
    "name" : "Basisstation DECT",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Basisstation_DECT.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Basisstation_DECT.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Basisstation_DECT.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Basisstation_DECT.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:baufachberater" : {
    "id" : "jonas-koeritz:thw-personen:baufachberater",
    "group" : "THW Personen",
    "name" : "Baufachberater",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Baufachberater.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Baufachberater.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Baufachberater.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Baufachberater.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:katastrophenschutz-einheiten:baugruppe" : {
    "id" : "jonas-koeritz:katastrophenschutz-einheiten:baugruppe",
    "group" : "Katastrophenschutz Einheiten",
    "name" : "Baugruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Katastrophenschutz_Einheiten/Baugruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Katastrophenschutz_Einheiten/Baugruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Katastrophenschutz_Einheiten/Baugruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Katastrophenschutz_Einheiten/Baugruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:bedingung" : {
    "id" : "jonas-koeritz:fernmeldewesen:bedingung",
    "group" : "Fernmeldewesen",
    "name" : "Bedingung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Bedingung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Bedingung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Bedingung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Bedingung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:bedingung-awitel" : {
    "id" : "jonas-koeritz:fernmeldewesen:bedingung-awitel",
    "group" : "Fernmeldewesen",
    "name" : "Bedingung AWITEL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Bedingung_AWITEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Bedingung_AWITEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Bedingung_AWITEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Bedingung_AWITEL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:bedingung-dmo" : {
    "id" : "jonas-koeritz:fernmeldewesen:bedingung-dmo",
    "group" : "Fernmeldewesen",
    "name" : "Bedingung DMO",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Bedingung_DMO.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Bedingung_DMO.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Bedingung_DMO.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Bedingung_DMO.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:bedingung-fax" : {
    "id" : "jonas-koeritz:fernmeldewesen:bedingung-fax",
    "group" : "Fernmeldewesen",
    "name" : "Bedingung Fax",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Bedingung_Fax.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Bedingung_Fax.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Bedingung_Fax.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Bedingung_Fax.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:bedingung-nebenstelle" : {
    "id" : "jonas-koeritz:fernmeldewesen:bedingung-nebenstelle",
    "group" : "Fernmeldewesen",
    "name" : "Bedingung Nebenstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Bedingung_Nebenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Bedingung_Nebenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Bedingung_Nebenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Bedingung_Nebenstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:bedingung-ob" : {
    "id" : "jonas-koeritz:fernmeldewesen:bedingung-ob",
    "group" : "Fernmeldewesen",
    "name" : "Bedingung OB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Bedingung_OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Bedingung_OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Bedingung_OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Bedingung_OB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:bedingung-tmo" : {
    "id" : "jonas-koeritz:fernmeldewesen:bedingung-tmo",
    "group" : "Fernmeldewesen",
    "name" : "Bedingung TMO",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Bedingung_TMO.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Bedingung_TMO.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Bedingung_TMO.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Bedingung_TMO.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:bedingung-telefon" : {
    "id" : "jonas-koeritz:fernmeldewesen:bedingung-telefon",
    "group" : "Fernmeldewesen",
    "name" : "Bedingung Telefon",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Bedingung_Telefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Bedingung_Telefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Bedingung_Telefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Bedingung_Telefon.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:behandlungsplatz" : {
    "id" : "jonas-koeritz:einrichtungen:behandlungsplatz",
    "group" : "Einrichtungen",
    "name" : "Behandlungsplatz",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Behandlungsplatz.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Behandlungsplatz.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Behandlungsplatz.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Behandlungsplatz.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:belegungsstelle" : {
    "id" : "jonas-koeritz:einrichtungen:belegungsstelle",
    "group" : "Einrichtungen",
    "name" : "Belegungsstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Belegungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Belegungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Belegungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Belegungsstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:bereitstellungsrau-mit-fuhrungsstelle" : {
    "id" : "jonas-koeritz:einrichtungen:bereitstellungsrau-mit-fuhrungsstelle",
    "group" : "Einrichtungen",
    "name" : "Bereitstellungsrau mit Führungsstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Bereitstellungsrau_mit_Führungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Bereitstellungsrau_mit_Führungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Bereitstellungsrau_mit_Führungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Bereitstellungsrau_mit_Führungsstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:bereitstellungsrau-mit-meldekopf" : {
    "id" : "jonas-koeritz:einrichtungen:bereitstellungsrau-mit-meldekopf",
    "group" : "Einrichtungen",
    "name" : "Bereitstellungsrau mit Meldekopf",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Bereitstellungsrau_mit_Meldekopf.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Bereitstellungsrau_mit_Meldekopf.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Bereitstellungsrau_mit_Meldekopf.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Bereitstellungsrau_mit_Meldekopf.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:bereitstellungsraum" : {
    "id" : "jonas-koeritz:einrichtungen:bereitstellungsraum",
    "group" : "Einrichtungen",
    "name" : "Bereitstellungsraum",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Bereitstellungsraum.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Bereitstellungsraum.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Bereitstellungsraum.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Bereitstellungsraum.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:bergungsgruppe" : {
    "id" : "jonas-koeritz:thw-einheiten:bergungsgruppe",
    "group" : "THW Einheiten",
    "name" : "Bergungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Bergungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Bergungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Bergungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Bergungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:bergungsgruppe-ash" : {
    "id" : "jonas-koeritz:thw-einheiten:bergungsgruppe-ash",
    "group" : "THW Einheiten",
    "name" : "Bergungsgruppe ASH",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Bergungsgruppe_ASH.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Bergungsgruppe_ASH.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Bergungsgruppe_ASH.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Bergungsgruppe_ASH.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:bergungsraumgerat" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:bergungsraumgerat",
    "group" : "THW Fahrzeuge",
    "name" : "Bergungsräumgerät",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Bergungsräumgerät.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Bergungsräumgerät.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Bergungsräumgerät.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Bergungsräumgerät.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:ma-nahmen:betreuung" : {
    "id" : "jonas-koeritz:ma-nahmen:betreuung",
    "group" : "Maßnahmen",
    "name" : "Betreuung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Maßnahmen/Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Maßnahmen/Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Maßnahmen/Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Maßnahmen/Betreuung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:betreuungsgruppe" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:betreuungsgruppe",
    "group" : "Rettungswesen Einheiten",
    "name" : "Betreuungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Betreuungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Betreuungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Betreuungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Betreuungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:betreuungsstelle" : {
    "id" : "jonas-koeritz:einrichtungen:betreuungsstelle",
    "group" : "Einrichtungen",
    "name" : "Betreuungsstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Betreuungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Betreuungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Betreuungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Betreuungsstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:betreuungstrupp" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:betreuungstrupp",
    "group" : "Rettungswesen Einheiten",
    "name" : "Betreuungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Betreuungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Betreuungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Betreuungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Betreuungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:betreuungszug" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:betreuungszug",
    "group" : "Rettungswesen Einheiten",
    "name" : "Betreuungszug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Betreuungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Betreuungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Betreuungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Betreuungszug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:bewegung-in-zwei-richtungen" : {
    "id" : "jonas-koeritz:sonstiges:bewegung-in-zwei-richtungen",
    "group" : "Sonstiges",
    "name" : "Bewegung in zwei Richtungen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Bewegung_in_zwei_Richtungen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Bewegung_in_zwei_Richtungen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Bewegung_in_zwei_Richtungen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Bewegung_in_zwei_Richtungen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:blockiert" : {
    "id" : "jonas-koeritz:schaden:blockiert",
    "group" : "Schäden",
    "name" : "Blockiert",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Blockiert.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Blockiert.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Blockiert.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Blockiert.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:boot" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:boot",
    "group" : "THW Fahrzeuge",
    "name" : "Boot",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Boot.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Boot.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Boot.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Boot.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:bootsgruppe" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:bootsgruppe",
    "group" : "Wasserrettung Einheiten",
    "name" : "Bootsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Bootsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Bootsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Bootsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Bootsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:bootstrupp" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:bootstrupp",
    "group" : "Wasserrettung Einheiten",
    "name" : "Bootstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Bootstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Bootstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Bootstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Bootstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:ma-nahmen:bruckenbau" : {
    "id" : "jonas-koeritz:ma-nahmen:bruckenbau",
    "group" : "Maßnahmen",
    "name" : "Brückenbau",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Maßnahmen/Brückenbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Maßnahmen/Brückenbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Maßnahmen/Brückenbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Maßnahmen/Brückenbau.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:daten-fernsprechverbindung" : {
    "id" : "jonas-koeritz:fernmeldewesen:daten-fernsprechverbindung",
    "group" : "Fernmeldewesen",
    "name" : "Daten Fernsprechverbindung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Daten_Fernsprechverbindung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Daten_Fernsprechverbindung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Daten_Fernsprechverbindung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Daten_Fernsprechverbindung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:datenfunk" : {
    "id" : "jonas-koeritz:fernmeldewesen:datenfunk",
    "group" : "Fernmeldewesen",
    "name" : "Datenfunk",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Datenfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Datenfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Datenfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Datenfunk.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:datenverbindung" : {
    "id" : "jonas-koeritz:fernmeldewesen:datenverbindung",
    "group" : "Fernmeldewesen",
    "name" : "Datenverbindung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Datenverbindung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Datenverbindung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Datenverbindung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Datenverbindung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:dekontaminationsgruppe-gerat" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:dekontaminationsgruppe-gerat",
    "group" : "Feuerwehr Einheiten",
    "name" : "Dekontaminationsgruppe Gerät",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Dekontaminationsgruppe_Gerät.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Dekontaminationsgruppe_Gerät.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Dekontaminationsgruppe_Gerät.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Dekontaminationsgruppe_Gerät.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:dekontaminationsgruppe-personen" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:dekontaminationsgruppe-personen",
    "group" : "Feuerwehr Einheiten",
    "name" : "Dekontaminationsgruppe Personen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Dekontaminationsgruppe_Personen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Dekontaminationsgruppe_Personen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Dekontaminationsgruppe_Personen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Dekontaminationsgruppe_Personen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:ma-nahmen:dekontaminieren" : {
    "id" : "jonas-koeritz:ma-nahmen:dekontaminieren",
    "group" : "Maßnahmen",
    "name" : "Dekontaminieren",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Maßnahmen/Dekontaminieren.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Maßnahmen/Dekontaminieren.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Maßnahmen/Dekontaminieren.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Maßnahmen/Dekontaminieren.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:deutlich-erhohte-aktivitat" : {
    "id" : "jonas-koeritz:sonstiges:deutlich-erhohte-aktivitat",
    "group" : "Sonstiges",
    "name" : "Deutlich erhöhte Aktivität",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Deutlich_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Deutlich_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Deutlich_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Deutlich_erhöhte_Aktivität.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:digitaler-sprechfunk" : {
    "id" : "jonas-koeritz:fernmeldewesen:digitaler-sprechfunk",
    "group" : "Fernmeldewesen",
    "name" : "Digitaler Sprechfunk",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Digitaler_Sprechfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Digitaler_Sprechfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Digitaler_Sprechfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Digitaler_Sprechfunk.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:drehleiter-mit-korb" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:drehleiter-mit-korb",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Drehleiter mit Korb",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Drehleiter_mit_Korb.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Drehleiter_mit_Korb.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Drehleiter_mit_Korb.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Drehleiter_mit_Korb.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:eal" : {
    "id" : "jonas-koeritz:fuhrungsstellen:eal",
    "group" : "Führungsstellen",
    "name" : "EAL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/EAL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/EAL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/EAL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/EAL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:el" : {
    "id" : "jonas-koeritz:fuhrungsstellen:el",
    "group" : "Führungsstellen",
    "name" : "EL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/EL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/EL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/EL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/EL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:elw-1" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:elw-1",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "ELW 1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/ELW_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/ELW_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/ELW_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/ELW_1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:elw-2" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:elw-2",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "ELW 2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/ELW_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/ELW_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/ELW_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/ELW_2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:ent" : {
    "id" : "jonas-koeritz:thw-einheiten:ent",
    "group" : "THW Einheiten",
    "name" : "ENT",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/ENT.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/ENT.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/ENT.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/ENT.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:ers" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:ers",
    "group" : "THW Fahrzeuge",
    "name" : "ERS",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/ERS.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/ERS.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/ERS.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/ERS.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:polizei-einheiten:einheit" : {
    "id" : "jonas-koeritz:polizei-einheiten:einheit",
    "group" : "Polizei Einheiten",
    "name" : "Einheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Polizei_Einheiten/Einheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Polizei_Einheiten/Einheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Polizei_Einheiten/Einheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Polizei_Einheiten/Einheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:polizei-einheiten:einheit-der-bundespolizei" : {
    "id" : "jonas-koeritz:polizei-einheiten:einheit-der-bundespolizei",
    "group" : "Polizei Einheiten",
    "name" : "Einheit der Bundespolizei",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Polizei_Einheiten/Einheit_der_Bundespolizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Polizei_Einheiten/Einheit_der_Bundespolizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Polizei_Einheiten/Einheit_der_Bundespolizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Polizei_Einheiten/Einheit_der_Bundespolizei.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:bundeswehr-einheiten:einheit-der-bundeswehr" : {
    "id" : "jonas-koeritz:bundeswehr-einheiten:einheit-der-bundeswehr",
    "group" : "Bundeswehr Einheiten",
    "name" : "Einheit der Bundeswehr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Bundeswehr_Einheiten/Einheit_der_Bundeswehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Bundeswehr_Einheiten/Einheit_der_Bundeswehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Bundeswehr_Einheiten/Einheit_der_Bundeswehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Bundeswehr_Einheiten/Einheit_der_Bundeswehr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:polizei-einheiten:einheit-der-polizei" : {
    "id" : "jonas-koeritz:polizei-einheiten:einheit-der-polizei",
    "group" : "Polizei Einheiten",
    "name" : "Einheit der Polizei",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Polizei_Einheiten/Einheit_der_Polizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Polizei_Einheiten/Einheit_der_Polizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Polizei_Einheiten/Einheit_der_Polizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Polizei_Einheiten/Einheit_der_Polizei.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:zoll-einheiten:einheit-des-zoll" : {
    "id" : "jonas-koeritz:zoll-einheiten:einheit-des-zoll",
    "group" : "Zoll Einheiten",
    "name" : "Einheit des Zoll",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Zoll_Einheiten/Einheit_des_Zoll.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Zoll_Einheiten/Einheit_des_Zoll.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Zoll_Einheiten/Einheit_des_Zoll.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Zoll_Einheiten/Einheit_des_Zoll.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:einheit-fur-psychosoziale-notfallversorgung" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:einheit-fur-psychosoziale-notfallversorgung",
    "group" : "Rettungswesen Einheiten",
    "name" : "Einheit für Psychosoziale Notfallversorgung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Einheit_für_Psychosoziale_Notfallversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Einheit_für_Psychosoziale_Notfallversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Einheit_für_Psychosoziale_Notfallversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Einheit_für_Psychosoziale_Notfallversorgung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:einsatzabschnittsleiter" : {
    "id" : "jonas-koeritz:personen:einsatzabschnittsleiter",
    "group" : "Personen",
    "name" : "Einsatzabschnittsleiter",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Einsatzabschnittsleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Einsatzabschnittsleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Einsatzabschnittsleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Einsatzabschnittsleiter.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:einsatzeinheit" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:einsatzeinheit",
    "group" : "Rettungswesen Einheiten",
    "name" : "Einsatzeinheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Einsatzeinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Einsatzeinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Einsatzeinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Einsatzeinheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:einsatzleiter" : {
    "id" : "jonas-koeritz:personen:einsatzleiter",
    "group" : "Personen",
    "name" : "Einsatzleiter",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Einsatzleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Einsatzleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Einsatzleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Einsatzleiter.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:einsatzleiter-rettungsdienst" : {
    "id" : "jonas-koeritz:rettungswesen-personen:einsatzleiter-rettungsdienst",
    "group" : "Rettungswesen Personen",
    "name" : "Einsatzleiter Rettungsdienst",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Einsatzleiter_Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Einsatzleiter_Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Einsatzleiter_Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Einsatzleiter_Rettungsdienst.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:elektroversorgung" : {
    "id" : "jonas-koeritz:einrichtungen:elektroversorgung",
    "group" : "Einrichtungen",
    "name" : "Elektroversorgung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Elektroversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Elektroversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Elektroversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Elektroversorgung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:endgerat-fernsprechen" : {
    "id" : "jonas-koeritz:fernmeldewesen:endgerat-fernsprechen",
    "group" : "Fernmeldewesen",
    "name" : "Endgerät Fernsprechen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Endgerät_Fernsprechen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Endgerät_Fernsprechen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Endgerät_Fernsprechen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Endgerät_Fernsprechen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:endgerat-sprechfunk" : {
    "id" : "jonas-koeritz:fernmeldewesen:endgerat-sprechfunk",
    "group" : "Fernmeldewesen",
    "name" : "Endgerät Sprechfunk",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Endgerät_Sprechfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Endgerät_Sprechfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Endgerät_Sprechfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Endgerät_Sprechfunk.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:endpunkt" : {
    "id" : "jonas-koeritz:sonstiges:endpunkt",
    "group" : "Sonstiges",
    "name" : "Endpunkt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Endpunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Endpunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Endpunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Endpunkt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:entsorgungsstelle" : {
    "id" : "jonas-koeritz:einrichtungen:entsorgungsstelle",
    "group" : "Einrichtungen",
    "name" : "Entsorgungsstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Entsorgungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Entsorgungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Entsorgungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Entsorgungsstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:entstehungsbrand" : {
    "id" : "jonas-koeritz:gefahren:entstehungsbrand",
    "group" : "Gefahren",
    "name" : "Entstehungsbrand",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Entstehungsbrand.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Entstehungsbrand.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Entstehungsbrand.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Entstehungsbrand.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:ereignis" : {
    "id" : "jonas-koeritz:sonstiges:ereignis",
    "group" : "Sonstiges",
    "name" : "Ereignis",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Ereignis.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Ereignis.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Ereignis.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Ereignis.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:ma-nahmen:erkunden" : {
    "id" : "jonas-koeritz:ma-nahmen:erkunden",
    "group" : "Maßnahmen",
    "name" : "Erkunden",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Maßnahmen/Erkunden.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Maßnahmen/Erkunden.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Maßnahmen/Erkunden.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Maßnahmen/Erkunden.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-beleuchtung" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-beleuchtung",
    "group" : "THW Einheiten",
    "name" : "FGr Beleuchtung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Beleuchtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Beleuchtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Beleuchtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Beleuchtung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-beleuchtung-a" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-beleuchtung-a",
    "group" : "THW Einheiten",
    "name" : "FGr Beleuchtung A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Beleuchtung_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Beleuchtung_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Beleuchtung_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Beleuchtung_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-beleuchtung-b" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-beleuchtung-b",
    "group" : "THW Einheiten",
    "name" : "FGr Beleuchtung B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Beleuchtung_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Beleuchtung_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Beleuchtung_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Beleuchtung_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-bruckenbau" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-bruckenbau",
    "group" : "THW Einheiten",
    "name" : "FGr Brückenbau",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Brückenbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Brückenbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Brückenbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Brückenbau.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-bruckenbau-a" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-bruckenbau-a",
    "group" : "THW Einheiten",
    "name" : "FGr Brückenbau A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Brückenbau_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Brückenbau_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Brückenbau_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Brückenbau_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-bruckenbau-b" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-bruckenbau-b",
    "group" : "THW Einheiten",
    "name" : "FGr Brückenbau B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Brückenbau_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Brückenbau_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Brückenbau_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Brückenbau_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-elektroversorgung" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-elektroversorgung",
    "group" : "THW Einheiten",
    "name" : "FGr Elektroversorgung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Elektroversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Elektroversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Elektroversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Elektroversorgung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-fachgruppe" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-fachgruppe",
    "group" : "THW Einheiten",
    "name" : "FGr Fachgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Fachgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Fachgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Fachgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Fachgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation",
    "group" : "THW Einheiten",
    "name" : "FGr Führung-Kommunikation",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Führung-Kommunikation.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Führung-Kommunikation.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Führung-Kommunikation.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Führung-Kommunikation.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-a" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-a",
    "group" : "THW Einheiten",
    "name" : "FGr Führung-Kommunikation A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Führung-Kommunikation_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Führung-Kommunikation_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Führung-Kommunikation_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Führung-Kommunikation_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-b" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-b",
    "group" : "THW Einheiten",
    "name" : "FGr Führung-Kommunikation B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Führung-Kommunikation_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Führung-Kommunikation_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Führung-Kommunikation_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Führung-Kommunikation_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-fernmeldetrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-fernmeldetrupp",
    "group" : "THW Einheiten",
    "name" : "FGr Führung-Kommunikation Fernmeldetrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Führung-Kommunikation_Fernmeldetrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Führung-Kommunikation_Fernmeldetrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Führung-Kommunikation_Fernmeldetrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Führung-Kommunikation_Fernmeldetrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-fuhrungs-und-kommunikationstrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-fuhrungs-und-kommunikationstrupp",
    "group" : "THW Einheiten",
    "name" : "FGr Führung-Kommunikation Führungs und Kommunikationstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Führung-Kommunikation_Führungs_und_Kommunikationstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Führung-Kommunikation_Führungs_und_Kommunikationstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Führung-Kommunikation_Führungs_und_Kommunikationstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Führung-Kommunikation_Führungs_und_Kommunikationstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-fuhrungstrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-fuhrungstrupp",
    "group" : "THW Einheiten",
    "name" : "FGr Führung-Kommunikation Führungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Führung-Kommunikation_Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Führung-Kommunikation_Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Führung-Kommunikation_Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Führung-Kommunikation_Führungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-weitverkehrstrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-fuhrung-kommunikation-weitverkehrstrupp",
    "group" : "THW Einheiten",
    "name" : "FGr Führung-Kommunikation Weitverkehrstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Führung-Kommunikation_Weitverkehrstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Führung-Kommunikation_Weitverkehrstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Führung-Kommunikation_Weitverkehrstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Führung-Kommunikation_Weitverkehrstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-fuhrungsunterstutzung" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-fuhrungsunterstutzung",
    "group" : "THW Einheiten",
    "name" : "FGr Führungsunterstützung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Führungsunterstützung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Führungsunterstützung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Führungsunterstützung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Führungsunterstützung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-infrastruktur" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-infrastruktur",
    "group" : "THW Einheiten",
    "name" : "FGr Infrastruktur",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Infrastruktur.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Infrastruktur.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Infrastruktur.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Infrastruktur.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-kommunikation-a" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-kommunikation-a",
    "group" : "THW Einheiten",
    "name" : "FGr Kommunikation A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Kommunikation_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Kommunikation_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Kommunikation_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Kommunikation_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-kommunikation-b" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-kommunikation-b",
    "group" : "THW Einheiten",
    "name" : "FGr Kommunikation B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Kommunikation_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Kommunikation_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Kommunikation_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Kommunikation_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-logistik" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-logistik",
    "group" : "THW Einheiten",
    "name" : "FGr Logistik",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Logistik.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-logistik-fuhrungstrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-logistik-fuhrungstrupp",
    "group" : "THW Einheiten",
    "name" : "FGr Logistik Führungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Logistik_Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Logistik_Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Logistik_Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Logistik_Führungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-logistik-materialerhaltungstrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-logistik-materialerhaltungstrupp",
    "group" : "THW Einheiten",
    "name" : "FGr Logistik Materialerhaltungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Logistik_Materialerhaltungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Logistik_Materialerhaltungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Logistik_Materialerhaltungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Logistik_Materialerhaltungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-logistik-materialwirtschaft" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-logistik-materialwirtschaft",
    "group" : "THW Einheiten",
    "name" : "FGr Logistik Materialwirtschaft",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Logistik_Materialwirtschaft.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Logistik_Materialwirtschaft.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Logistik_Materialwirtschaft.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Logistik_Materialwirtschaft.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-logistik-materialwirtschaft-verbrauchsguterversorgungstrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-logistik-materialwirtschaft-verbrauchsguterversorgungstrupp",
    "group" : "THW Einheiten",
    "name" : "FGr Logistik Materialwirtschaft Verbrauchsgüterversorgungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Logistik_Materialwirtschaft_Verbrauchsgüterversorgungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Logistik_Materialwirtschaft_Verbrauchsgüterversorgungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Logistik_Materialwirtschaft_Verbrauchsgüterversorgungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Logistik_Materialwirtschaft_Verbrauchsgüterversorgungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-logistik-versorgungstrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-logistik-versorgungstrupp",
    "group" : "THW Einheiten",
    "name" : "FGr Logistik Versorgungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Logistik_Versorgungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Logistik_Versorgungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Logistik_Versorgungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Logistik_Versorgungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-logistik-verpflegung" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-logistik-verpflegung",
    "group" : "THW Einheiten",
    "name" : "FGr Logistik-Verpflegung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Logistik-Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Logistik-Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Logistik-Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Logistik-Verpflegung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-notversorgung-und-notinstandsetzung" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-notversorgung-und-notinstandsetzung",
    "group" : "THW Einheiten",
    "name" : "FGr Notversorgung und Notinstandsetzung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Notversorgung_und_Notinstandsetzung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Notversorgung_und_Notinstandsetzung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Notversorgung_und_Notinstandsetzung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Notversorgung_und_Notinstandsetzung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-ortung-biologisch" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-ortung-biologisch",
    "group" : "THW Einheiten",
    "name" : "FGr Ortung biologisch",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Ortung_biologisch.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Ortung_biologisch.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Ortung_biologisch.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Ortung_biologisch.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-ortung-biologisch-und-technisch" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-ortung-biologisch-und-technisch",
    "group" : "THW Einheiten",
    "name" : "FGr Ortung biologisch und technisch",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Ortung_biologisch_und_technisch.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Ortung_biologisch_und_technisch.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Ortung_biologisch_und_technisch.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Ortung_biologisch_und_technisch.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-ortung-technisch" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-ortung-technisch",
    "group" : "THW Einheiten",
    "name" : "FGr Ortung technisch",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Ortung_technisch.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Ortung_technisch.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Ortung_technisch.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Ortung_technisch.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-raumen" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-raumen",
    "group" : "THW Einheiten",
    "name" : "FGr Räumen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Räumen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Räumen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Räumen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Räumen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-raumen-a" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-raumen-a",
    "group" : "THW Einheiten",
    "name" : "FGr Räumen A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Räumen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Räumen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Räumen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Räumen_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-raumen-a-bagger" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-raumen-a-bagger",
    "group" : "THW Einheiten",
    "name" : "FGr Räumen A Bagger",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Räumen_A_Bagger.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Räumen_A_Bagger.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Räumen_A_Bagger.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Räumen_A_Bagger.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-raumen-a-radlader" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-raumen-a-radlader",
    "group" : "THW Einheiten",
    "name" : "FGr Räumen A Radlader",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Räumen_A_Radlader.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Räumen_A_Radlader.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Räumen_A_Radlader.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Räumen_A_Radlader.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-raumen-b" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-raumen-b",
    "group" : "THW Einheiten",
    "name" : "FGr Räumen B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Räumen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Räumen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Räumen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Räumen_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-raumen-c" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-raumen-c",
    "group" : "THW Einheiten",
    "name" : "FGr Räumen C",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Räumen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Räumen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Räumen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Räumen_C.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-schwere-bergung" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-schwere-bergung",
    "group" : "THW Einheiten",
    "name" : "FGr Schwere Bergung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Schwere_Bergung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Schwere_Bergung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Schwere_Bergung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Schwere_Bergung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-schwere-bergung-a" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-schwere-bergung-a",
    "group" : "THW Einheiten",
    "name" : "FGr Schwere Bergung A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Schwere_Bergung_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Schwere_Bergung_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Schwere_Bergung_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Schwere_Bergung_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-schwere-bergung-b" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-schwere-bergung-b",
    "group" : "THW Einheiten",
    "name" : "FGr Schwere Bergung B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Schwere_Bergung_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Schwere_Bergung_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Schwere_Bergung_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Schwere_Bergung_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-sprengen" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-sprengen",
    "group" : "THW Einheiten",
    "name" : "FGr Sprengen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Sprengen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Sprengen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Sprengen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Sprengen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-trinkwasser" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-trinkwasser",
    "group" : "THW Einheiten",
    "name" : "FGr Trinkwasser",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Trinkwasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Trinkwasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Trinkwasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Trinkwasser.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-trinkwasser-a" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-trinkwasser-a",
    "group" : "THW Einheiten",
    "name" : "FGr Trinkwasser A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Trinkwasser_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Trinkwasser_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Trinkwasser_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Trinkwasser_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-trinkwasser-b" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-trinkwasser-b",
    "group" : "THW Einheiten",
    "name" : "FGr Trinkwasser B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Trinkwasser_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Trinkwasser_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Trinkwasser_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Trinkwasser_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-wassergefahren" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-wassergefahren",
    "group" : "THW Einheiten",
    "name" : "FGr Wassergefahren",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Wassergefahren.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Wassergefahren.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Wassergefahren.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Wassergefahren.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-wassergefahren-a" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-wassergefahren-a",
    "group" : "THW Einheiten",
    "name" : "FGr Wassergefahren A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Wassergefahren_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Wassergefahren_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Wassergefahren_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Wassergefahren_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-wassergefahren-b" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-wassergefahren-b",
    "group" : "THW Einheiten",
    "name" : "FGr Wassergefahren B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Wassergefahren_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Wassergefahren_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Wassergefahren_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Wassergefahren_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-wasserschaden-pumpen" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-wasserschaden-pumpen",
    "group" : "THW Einheiten",
    "name" : "FGr Wasserschaden-Pumpen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Wasserschaden-Pumpen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Wasserschaden-Pumpen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Wasserschaden-Pumpen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Wasserschaden-Pumpen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-wassserschaden-pumpen-a" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-wassserschaden-pumpen-a",
    "group" : "THW Einheiten",
    "name" : "FGr Wassserschaden-Pumpen A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Wassserschaden-Pumpen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Wassserschaden-Pumpen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Wassserschaden-Pumpen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Wassserschaden-Pumpen_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-wassserschaden-pumpen-b" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-wassserschaden-pumpen-b",
    "group" : "THW Einheiten",
    "name" : "FGr Wassserschaden-Pumpen B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Wassserschaden-Pumpen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Wassserschaden-Pumpen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Wassserschaden-Pumpen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Wassserschaden-Pumpen_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-wassserschaden-pumpen-c" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-wassserschaden-pumpen-c",
    "group" : "THW Einheiten",
    "name" : "FGr Wassserschaden-Pumpen C",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Wassserschaden-Pumpen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Wassserschaden-Pumpen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Wassserschaden-Pumpen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Wassserschaden-Pumpen_C.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-olschaden" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-olschaden",
    "group" : "THW Einheiten",
    "name" : "FGr Ölschaden",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Ölschaden.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Ölschaden.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Ölschaden.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Ölschaden.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-olschaden-a" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-olschaden-a",
    "group" : "THW Einheiten",
    "name" : "FGr Ölschaden A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Ölschaden_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Ölschaden_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Ölschaden_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Ölschaden_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-olschaden-b" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-olschaden-b",
    "group" : "THW Einheiten",
    "name" : "FGr Ölschaden B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Ölschaden_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Ölschaden_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Ölschaden_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Ölschaden_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fgr-olschaden-c" : {
    "id" : "jonas-koeritz:thw-einheiten:fgr-olschaden-c",
    "group" : "THW Einheiten",
    "name" : "FGr Ölschaden C",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/FGr_Ölschaden_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/FGr_Ölschaden_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/FGr_Ölschaden_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/FGr_Ölschaden_C.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:fachberater" : {
    "id" : "jonas-koeritz:thw-personen:fachberater",
    "group" : "THW Personen",
    "name" : "Fachberater",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Fachberater.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Fachberater.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Fachberater.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Fachberater.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:katastrophenschutz-einheiten:facheinheit-iuk" : {
    "id" : "jonas-koeritz:katastrophenschutz-einheiten:facheinheit-iuk",
    "group" : "Katastrophenschutz Einheiten",
    "name" : "Facheinheit IuK",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Katastrophenschutz_Einheiten/Facheinheit_IuK.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Katastrophenschutz_Einheiten/Facheinheit_IuK.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Katastrophenschutz_Einheiten/Facheinheit_IuK.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Katastrophenschutz_Einheiten/Facheinheit_IuK.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fachzug-fuhrung-kommunikation" : {
    "id" : "jonas-koeritz:thw-einheiten:fachzug-fuhrung-kommunikation",
    "group" : "THW Einheiten",
    "name" : "Fachzug Führung Kommunikation",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Fachzug_Führung_Kommunikation.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Fachzug_Führung_Kommunikation.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Fachzug_Führung_Kommunikation.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Fachzug_Führung_Kommunikation.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fachzug-logistik" : {
    "id" : "jonas-koeritz:thw-einheiten:fachzug-logistik",
    "group" : "THW Einheiten",
    "name" : "Fachzug Logistik",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Fachzug_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Fachzug_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Fachzug_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Fachzug_Logistik.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:fahrrad" : {
    "id" : "jonas-koeritz:fahrzeuge:fahrrad",
    "group" : "Fahrzeuge",
    "name" : "Fahrrad",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Fahrrad.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Fahrrad.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Fahrrad.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Fahrrad.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:fahrzeug" : {
    "id" : "jonas-koeritz:fahrzeuge:fahrzeug",
    "group" : "Fahrzeuge",
    "name" : "Fahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Fahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Fahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Fahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Fahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fahrzeugfunkgerat-digital" : {
    "id" : "jonas-koeritz:fernmeldewesen:fahrzeugfunkgerat-digital",
    "group" : "Fernmeldewesen",
    "name" : "Fahrzeugfunkgerät digital",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Fahrzeugfunkgerät_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Fahrzeugfunkgerät_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Fahrzeugfunkgerät_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Fahrzeugfunkgerät_digital.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:faxgerat" : {
    "id" : "jonas-koeritz:fernmeldewesen:faxgerat",
    "group" : "Fernmeldewesen",
    "name" : "Faxgerät",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Faxgerät.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Faxgerät.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Faxgerät.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Faxgerät.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:feldlager" : {
    "id" : "jonas-koeritz:einrichtungen:feldlager",
    "group" : "Einrichtungen",
    "name" : "Feldlager",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Feldlager.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Feldlager.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Feldlager.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Feldlager.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:feldlager" : {
    "id" : "jonas-koeritz:fuhrungsstellen:feldlager",
    "group" : "Führungsstellen",
    "name" : "Feldlager",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/Feldlager.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/Feldlager.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/Feldlager.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/Feldlager.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:fernsprech-bau-und-betriebstrupp" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:fernsprech-bau-und-betriebstrupp",
    "group" : "Feuerwehr Einheiten",
    "name" : "Fernsprech Bau und Betriebstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Fernsprech_Bau_und_Betriebstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Fernsprech_Bau_und_Betriebstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Fernsprech_Bau_und_Betriebstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Fernsprech_Bau_und_Betriebstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fernsprechen-uber-draht" : {
    "id" : "jonas-koeritz:fernmeldewesen:fernsprechen-uber-draht",
    "group" : "Fernmeldewesen",
    "name" : "Fernsprechen über Draht",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Fernsprechen_über_Draht.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Fernsprechen_über_Draht.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Fernsprechen_über_Draht.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Fernsprechen_über_Draht.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fernsprechen-uber-funk" : {
    "id" : "jonas-koeritz:fernmeldewesen:fernsprechen-uber-funk",
    "group" : "Fernmeldewesen",
    "name" : "Fernsprechen über Funk",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Fernsprechen_über_Funk.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Fernsprechen_über_Funk.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Fernsprechen_über_Funk.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Fernsprechen_über_Funk.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fernsprechvermittlung" : {
    "id" : "jonas-koeritz:fernmeldewesen:fernsprechvermittlung",
    "group" : "Fernmeldewesen",
    "name" : "Fernsprechvermittlung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Fernsprechvermittlung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Fernsprechvermittlung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Fernsprechvermittlung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Fernsprechvermittlung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-ob10" : {
    "id" : "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-ob10",
    "group" : "Fernmeldewesen",
    "name" : "Fernsprechvermittlung OB10",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Fernsprechvermittlung_OB10.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Fernsprechvermittlung_OB10.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Fernsprechvermittlung_OB10.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Fernsprechvermittlung_OB10.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-ob10-2az" : {
    "id" : "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-ob10-2az",
    "group" : "Fernmeldewesen",
    "name" : "Fernsprechvermittlung OB10 2AZ",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Fernsprechvermittlung_OB10_2AZ.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Fernsprechvermittlung_OB10_2AZ.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Fernsprechvermittlung_OB10_2AZ.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Fernsprechvermittlung_OB10_2AZ.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-ob20" : {
    "id" : "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-ob20",
    "group" : "Fernmeldewesen",
    "name" : "Fernsprechvermittlung OB20",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Fernsprechvermittlung_OB20.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Fernsprechvermittlung_OB20.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Fernsprechvermittlung_OB20.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Fernsprechvermittlung_OB20.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-ob30" : {
    "id" : "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-ob30",
    "group" : "Fernmeldewesen",
    "name" : "Fernsprechvermittlung OB30",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Fernsprechvermittlung_OB30.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Fernsprechvermittlung_OB30.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Fernsprechvermittlung_OB30.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Fernsprechvermittlung_OB30.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-ob30-4az" : {
    "id" : "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-ob30-4az",
    "group" : "Fernmeldewesen",
    "name" : "Fernsprechvermittlung OB30 4AZ",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Fernsprechvermittlung_OB30_4AZ.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Fernsprechvermittlung_OB30_4AZ.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Fernsprechvermittlung_OB30_4AZ.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Fernsprechvermittlung_OB30_4AZ.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-wahlbetrieb" : {
    "id" : "jonas-koeritz:fernmeldewesen:fernsprechvermittlung-wahlbetrieb",
    "group" : "Fernmeldewesen",
    "name" : "Fernsprechvermittlung Wählbetrieb",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Fernsprechvermittlung_Wählbetrieb.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Fernsprechvermittlung_Wählbetrieb.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Fernsprechvermittlung_Wählbetrieb.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Fernsprechvermittlung_Wählbetrieb.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:feuerwehranhanger" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:feuerwehranhanger",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Feuerwehranhänger",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Feuerwehranhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Feuerwehranhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Feuerwehranhänger.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Feuerwehranhänger.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:feuerwehrboot" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:feuerwehrboot",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Feuerwehrboot",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Feuerwehrboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Feuerwehrboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Feuerwehrboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Feuerwehrboot.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-gebaude:feuerwehrgeratehaus" : {
    "id" : "jonas-koeritz:feuerwehr-gebaude:feuerwehrgeratehaus",
    "group" : "Feuerwehr Gebäude",
    "name" : "Feuerwehrgerätehaus",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Gebäude/Feuerwehrgerätehaus.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Gebäude/Feuerwehrgerätehaus.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Gebäude/Feuerwehrgerätehaus.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Gebäude/Feuerwehrgerätehaus.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:firewall" : {
    "id" : "jonas-koeritz:fernmeldewesen:firewall",
    "group" : "Fernmeldewesen",
    "name" : "Firewall",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Firewall.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Firewall.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Firewall.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Firewall.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:flugleiter" : {
    "id" : "jonas-koeritz:personen:flugleiter",
    "group" : "Personen",
    "name" : "Flugleiter",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Flugleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Flugleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Flugleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Flugleiter.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:flugleiter" : {
    "id" : "jonas-koeritz:feuerwehr-personen:flugleiter",
    "group" : "Feuerwehr Personen",
    "name" : "Flugleiter",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Flugleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Flugleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Flugleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Flugleiter.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:flugleiter" : {
    "id" : "jonas-koeritz:thw-personen:flugleiter",
    "group" : "THW Personen",
    "name" : "Flugleiter",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Flugleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Flugleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Flugleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Flugleiter.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:flugzeug" : {
    "id" : "jonas-koeritz:fahrzeuge:flugzeug",
    "group" : "Fahrzeuge",
    "name" : "Flugzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Flugzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Flugzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Flugzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Flugzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:flachenbrand" : {
    "id" : "jonas-koeritz:gefahren:flachenbrand",
    "group" : "Gefahren",
    "name" : "Flächenbrand",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Flächenbrand.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Flächenbrand.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Flächenbrand.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Flächenbrand.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:fmkw" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:fmkw",
    "group" : "THW Fahrzeuge",
    "name" : "FmKW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/FmKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/FmKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/FmKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/FmKW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:fortentwickelter-brand" : {
    "id" : "jonas-koeritz:gefahren:fortentwickelter-brand",
    "group" : "Gefahren",
    "name" : "Fortentwickelter Brand",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Fortentwickelter_Brand.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Fortentwickelter_Brand.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Fortentwickelter_Brand.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Fortentwickelter_Brand.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-10" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-10",
    "group" : "Fernmeldewesen",
    "name" : "FuG 10",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_10.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-10a" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-10a",
    "group" : "Fernmeldewesen",
    "name" : "FuG 10a",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_10a.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_10a.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_10a.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_10a.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-11b" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-11b",
    "group" : "Fernmeldewesen",
    "name" : "FuG 11b",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_11b.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_11b.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_11b.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_11b.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-13b" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-13b",
    "group" : "Fernmeldewesen",
    "name" : "FuG 13b",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_13b.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_13b.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_13b.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_13b.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-7b" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-7b",
    "group" : "Fernmeldewesen",
    "name" : "FuG 7b",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_7b.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_7b.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_7b.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_7b.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-8" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-8",
    "group" : "Fernmeldewesen",
    "name" : "FuG 8",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_8.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-8b" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-8b",
    "group" : "Fernmeldewesen",
    "name" : "FuG 8b",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_8b.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_8b.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_8b.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_8b.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-8b1" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-8b1",
    "group" : "Fernmeldewesen",
    "name" : "FuG 8b1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_8b1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_8b1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_8b1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_8b1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-8b1-relais" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-8b1-relais",
    "group" : "Fernmeldewesen",
    "name" : "FuG 8b1 Relais",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_8b1_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_8b1_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_8b1_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_8b1_Relais.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-8c" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-8c",
    "group" : "Fernmeldewesen",
    "name" : "FuG 8c",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_8c.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_8c.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_8c.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_8c.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-8c-relais" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-8c-relais",
    "group" : "Fernmeldewesen",
    "name" : "FuG 8c Relais",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_8c_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_8c_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_8c_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_8c_Relais.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-9" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-9",
    "group" : "Fernmeldewesen",
    "name" : "FuG 9",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_9.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-9c" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-9c",
    "group" : "Fernmeldewesen",
    "name" : "FuG 9c",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_9c.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_9c.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_9c.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_9c.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-9c-relais" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-9c-relais",
    "group" : "Fernmeldewesen",
    "name" : "FuG 9c Relais",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_9c_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_9c_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_9c_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_9c_Relais.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:fug-relais" : {
    "id" : "jonas-koeritz:fernmeldewesen:fug-relais",
    "group" : "Fernmeldewesen",
    "name" : "FuG Relais",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/FuG_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/FuG_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/FuG_Relais.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/FuG_Relais.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:funk-feststation-digital" : {
    "id" : "jonas-koeritz:fernmeldewesen:funk-feststation-digital",
    "group" : "Fernmeldewesen",
    "name" : "Funk Feststation digital",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Funk_Feststation_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Funk_Feststation_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Funk_Feststation_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Funk_Feststation_digital.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:katastrophenschutz-einheiten:funkgruppe" : {
    "id" : "jonas-koeritz:katastrophenschutz-einheiten:funkgruppe",
    "group" : "Katastrophenschutz Einheiten",
    "name" : "Funkgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Katastrophenschutz_Einheiten/Funkgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Katastrophenschutz_Einheiten/Funkgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Katastrophenschutz_Einheiten/Funkgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Katastrophenschutz_Einheiten/Funkgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:funkstation" : {
    "id" : "jonas-koeritz:fernmeldewesen:funkstation",
    "group" : "Fernmeldewesen",
    "name" : "Funkstation",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Funkstation.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Funkstation.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Funkstation.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Funkstation.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:fukomkw" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:fukomkw",
    "group" : "THW Fahrzeuge",
    "name" : "FüKomKW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/FüKomKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/FüKomKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/FüKomKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/FüKomKW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fuhrungseinheit" : {
    "id" : "jonas-koeritz:thw-einheiten:fuhrungseinheit",
    "group" : "THW Einheiten",
    "name" : "Führungseinheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Führungseinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Führungseinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Führungseinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Führungseinheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einheiten:fuhrungsgruppe" : {
    "id" : "jonas-koeritz:einheiten:fuhrungsgruppe",
    "group" : "Einheiten",
    "name" : "Führungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einheiten/Führungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einheiten/Führungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einheiten/Führungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einheiten/Führungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:fuhrungsgruppe-feuerwehrbereitschaft" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:fuhrungsgruppe-feuerwehrbereitschaft",
    "group" : "Feuerwehr Einheiten",
    "name" : "Führungsgruppe Feuerwehrbereitschaft",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Führungsgruppe_Feuerwehrbereitschaft.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Führungsgruppe_Feuerwehrbereitschaft.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Führungsgruppe_Feuerwehrbereitschaft.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Führungsgruppe_Feuerwehrbereitschaft.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:fuhrungsgruppe-tel" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:fuhrungsgruppe-tel",
    "group" : "Feuerwehr Einheiten",
    "name" : "Führungsgruppe TEL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Führungsgruppe_TEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Führungsgruppe_TEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Führungsgruppe_TEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Führungsgruppe_TEL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:bundeswehr-personen:fuhrungskraft" : {
    "id" : "jonas-koeritz:bundeswehr-personen:fuhrungskraft",
    "group" : "Bundeswehr Personen",
    "name" : "Führungskraft",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Bundeswehr_Personen/Führungskraft.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Bundeswehr_Personen/Führungskraft.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Bundeswehr_Personen/Führungskraft.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Bundeswehr_Personen/Führungskraft.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:fuhrungskraftwagen" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:fuhrungskraftwagen",
    "group" : "THW Fahrzeuge",
    "name" : "Führungskraftwagen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Führungskraftwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Führungskraftwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Führungskraftwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Führungskraftwagen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:fuhrungsstab" : {
    "id" : "jonas-koeritz:fuhrungsstellen:fuhrungsstab",
    "group" : "Führungsstellen",
    "name" : "Führungsstab",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/Führungsstab.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/Führungsstab.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/Führungsstab.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/Führungsstab.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einheiten:fuhrungsstaffel" : {
    "id" : "jonas-koeritz:einheiten:fuhrungsstaffel",
    "group" : "Einheiten",
    "name" : "Führungsstaffel",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einheiten/Führungsstaffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einheiten/Führungsstaffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einheiten/Führungsstaffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einheiten/Führungsstaffel.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:fuhrungsstelle" : {
    "id" : "jonas-koeritz:fuhrungsstellen:fuhrungsstelle",
    "group" : "Führungsstellen",
    "name" : "Führungsstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/Führungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/Führungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/Führungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/Führungsstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:fuhrungstrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:fuhrungstrupp",
    "group" : "THW Einheiten",
    "name" : "Führungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Führungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einheiten:fuhrungstrupp" : {
    "id" : "jonas-koeritz:einheiten:fuhrungstrupp",
    "group" : "Einheiten",
    "name" : "Führungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einheiten/Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einheiten/Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einheiten/Führungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einheiten/Führungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:gkw-7t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:gkw-7t",
    "group" : "THW Fahrzeuge",
    "name" : "GKW 7t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/GKW_7t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/GKW_7t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/GKW_7t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/GKW_7t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:gkw-i" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:gkw-i",
    "group" : "THW Fahrzeuge",
    "name" : "GKW I",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/GKW_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/GKW_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/GKW_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/GKW_I.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:gkw-ii" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:gkw-ii",
    "group" : "THW Fahrzeuge",
    "name" : "GKW II",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/GKW_II.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/GKW_II.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/GKW_II.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/GKW_II.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gebaude:gebaude" : {
    "id" : "jonas-koeritz:gebaude:gebaude",
    "group" : "Gebäude",
    "name" : "Gebäude",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gebäude/Gebäude.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gebäude/Gebäude.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gebäude/Gebäude.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gebäude/Gebäude.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:gefahr" : {
    "id" : "jonas-koeritz:gefahren:gefahr",
    "group" : "Gefahren",
    "name" : "Gefahr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Gefahr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Gefahr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Gefahr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Gefahr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:gefahr-durch-explosion" : {
    "id" : "jonas-koeritz:gefahren:gefahr-durch-explosion",
    "group" : "Gefahren",
    "name" : "Gefahr durch Explosion",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Gefahr_durch_Explosion.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Gefahr_durch_Explosion.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Gefahr_durch_Explosion.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Gefahr_durch_Explosion.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:gefahr-durch-explosivstoffe" : {
    "id" : "jonas-koeritz:gefahren:gefahr-durch-explosivstoffe",
    "group" : "Gefahren",
    "name" : "Gefahr durch Explosivstoffe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Gefahr_durch_Explosivstoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Gefahr_durch_Explosivstoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Gefahr_durch_Explosivstoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Gefahr_durch_Explosivstoffe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:gefahr-durch-gas" : {
    "id" : "jonas-koeritz:gefahren:gefahr-durch-gas",
    "group" : "Gefahren",
    "name" : "Gefahr durch Gas",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Gefahr_durch_Gas.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Gefahr_durch_Gas.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Gefahr_durch_Gas.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Gefahr_durch_Gas.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:gefahr-durch-luftmangel" : {
    "id" : "jonas-koeritz:gefahren:gefahr-durch-luftmangel",
    "group" : "Gefahren",
    "name" : "Gefahr durch Luftmangel",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Gefahr_durch_Luftmangel.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Gefahr_durch_Luftmangel.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Gefahr_durch_Luftmangel.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Gefahr_durch_Luftmangel.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:gefahr-durch-mineralol" : {
    "id" : "jonas-koeritz:gefahren:gefahr-durch-mineralol",
    "group" : "Gefahren",
    "name" : "Gefahr durch Mineralöl",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Gefahr_durch_Mineralöl.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Gefahr_durch_Mineralöl.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Gefahr_durch_Mineralöl.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Gefahr_durch_Mineralöl.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:gefahr-durch-radioaktivitat" : {
    "id" : "jonas-koeritz:gefahren:gefahr-durch-radioaktivitat",
    "group" : "Gefahren",
    "name" : "Gefahr durch Radioaktivität",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Gefahr_durch_Radioaktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Gefahr_durch_Radioaktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Gefahr_durch_Radioaktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Gefahr_durch_Radioaktivität.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:gefahr-durch-wassereinbruch" : {
    "id" : "jonas-koeritz:gefahren:gefahr-durch-wassereinbruch",
    "group" : "Gefahren",
    "name" : "Gefahr durch Wassereinbruch",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Gefahr_durch_Wassereinbruch.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Gefahr_durch_Wassereinbruch.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Gefahr_durch_Wassereinbruch.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Gefahr_durch_Wassereinbruch.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:gefahr-durch-elektrischen-strom" : {
    "id" : "jonas-koeritz:gefahren:gefahr-durch-elektrischen-strom",
    "group" : "Gefahren",
    "name" : "Gefahr durch elektrischen Strom",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Gefahr_durch_elektrischen_Strom.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Gefahr_durch_elektrischen_Strom.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Gefahr_durch_elektrischen_Strom.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Gefahr_durch_elektrischen_Strom.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:gefahr-durch-gefahrliche-stoffe" : {
    "id" : "jonas-koeritz:gefahren:gefahr-durch-gefahrliche-stoffe",
    "group" : "Gefahren",
    "name" : "Gefahr durch gefährliche Stoffe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Gefahr_durch_gefährliche_Stoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Gefahr_durch_gefährliche_Stoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Gefahr_durch_gefährliche_Stoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Gefahr_durch_gefährliche_Stoffe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:gefahrstoffzug-abc-zug" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:gefahrstoffzug-abc-zug",
    "group" : "Feuerwehr Einheiten",
    "name" : "Gefahrstoffzug ABC Zug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Gefahrstoffzug_ABC_Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Gefahrstoffzug_ABC_Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Gefahrstoffzug_ABC_Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Gefahrstoffzug_ABC_Zug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:gelandestapler" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:gelandestapler",
    "group" : "THW Fahrzeuge",
    "name" : "Geländestapler",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Geländestapler.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Geländestapler.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Geländestapler.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Geländestapler.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:gerettete-person" : {
    "id" : "jonas-koeritz:personen:gerettete-person",
    "group" : "Personen",
    "name" : "Gerettete Person",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Gerettete_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Gerettete_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Gerettete_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Gerettete_Person.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:geringfugig-erhohte-aktivitat" : {
    "id" : "jonas-koeritz:sonstiges:geringfugig-erhohte-aktivitat",
    "group" : "Sonstiges",
    "name" : "Geringfügig erhöhte Aktivität",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Geringfügig_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Geringfügig_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Geringfügig_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Geringfügig_erhöhte_Aktivität.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:geratewagen-atemschutz" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:geratewagen-atemschutz",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Gerätewagen Atemschutz",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Gerätewagen_Atemschutz.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Gerätewagen_Atemschutz.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Gerätewagen_Atemschutz.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Gerätewagen_Atemschutz.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:geratewagen-gefahrgut" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:geratewagen-gefahrgut",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Gerätewagen Gefahrgut",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Gerätewagen_Gefahrgut.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Gerätewagen_Gefahrgut.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Gerätewagen_Gefahrgut.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Gerätewagen_Gefahrgut.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:geratewagen-logistik" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:geratewagen-logistik",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Gerätewagen Logistik",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:geratewagen-logistik-1" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:geratewagen-logistik-1",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Gerätewagen Logistik 1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik_1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:geratewagen-logistik-2" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:geratewagen-logistik-2",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Gerätewagen Logistik 2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Gerätewagen_Logistik_2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-fahrzeuge:geratewagen-sanitat" : {
    "id" : "jonas-koeritz:rettungswesen-fahrzeuge:geratewagen-sanitat",
    "group" : "Rettungswesen Fahrzeuge",
    "name" : "Gerätewagen Sanität",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Fahrzeuge/Gerätewagen_Sanität.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Fahrzeuge/Gerätewagen_Sanität.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Fahrzeuge/Gerätewagen_Sanität.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Fahrzeuge/Gerätewagen_Sanität.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:grundausbildungsgruppe" : {
    "id" : "jonas-koeritz:thw-einheiten:grundausbildungsgruppe",
    "group" : "THW Einheiten",
    "name" : "Grundausbildungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Grundausbildungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Grundausbildungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Grundausbildungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Grundausbildungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:gruppe" : {
    "id" : "jonas-koeritz:thw-einheiten:gruppe",
    "group" : "THW Einheiten",
    "name" : "Gruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Gruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Gruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Gruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Gruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einheiten:gruppe" : {
    "id" : "jonas-koeritz:einheiten:gruppe",
    "group" : "Einheiten",
    "name" : "Gruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einheiten/Gruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einheiten/Gruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einheiten/Gruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einheiten/Gruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:gruppe-technik-und-sicherheit" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:gruppe-technik-und-sicherheit",
    "group" : "Rettungswesen Einheiten",
    "name" : "Gruppe Technik und Sicherheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Gruppe_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Gruppe_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Gruppe_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Gruppe_Technik_und_Sicherheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:gruppe-fur-soziale-betreuung" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:gruppe-fur-soziale-betreuung",
    "group" : "Rettungswesen Einheiten",
    "name" : "Gruppe für soziale Betreuung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Gruppe_für_soziale_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Gruppe_für_soziale_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Gruppe_für_soziale_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Gruppe_für_soziale_Betreuung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:gruppenfuhrer" : {
    "id" : "jonas-koeritz:personen:gruppenfuhrer",
    "group" : "Personen",
    "name" : "Gruppenführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Gruppenführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Gruppenführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Gruppenführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Gruppenführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:gruppenfuhrer" : {
    "id" : "jonas-koeritz:feuerwehr-personen:gruppenfuhrer",
    "group" : "Feuerwehr Personen",
    "name" : "Gruppenführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Gruppenführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Gruppenführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Gruppenführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Gruppenführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:bundeswehr-personen:gruppenfuhrer" : {
    "id" : "jonas-koeritz:bundeswehr-personen:gruppenfuhrer",
    "group" : "Bundeswehr Personen",
    "name" : "Gruppenführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Bundeswehr_Personen/Gruppenführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Bundeswehr_Personen/Gruppenführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Bundeswehr_Personen/Gruppenführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Bundeswehr_Personen/Gruppenführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-b" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-b",
    "group" : "THW Personen",
    "name" : "Gruppenführer B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-b-1" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-b-1",
    "group" : "THW Personen",
    "name" : "Gruppenführer B 1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_B_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_B_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_B_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_B_1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-b-2" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-b-2",
    "group" : "THW Personen",
    "name" : "Gruppenführer B 2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_B_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_B_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_B_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_B_2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-bel" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-bel",
    "group" : "THW Personen",
    "name" : "Gruppenführer Bel",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_Bel.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_Bel.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_Bel.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_Bel.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:gruppenfuhrer-betreuungsgruppe" : {
    "id" : "jonas-koeritz:rettungswesen-personen:gruppenfuhrer-betreuungsgruppe",
    "group" : "Rettungswesen Personen",
    "name" : "Gruppenführer Betreuungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Gruppenführer_Betreuungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Gruppenführer_Betreuungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Gruppenführer_Betreuungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Gruppenführer_Betreuungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-brb" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-brb",
    "group" : "THW Personen",
    "name" : "Gruppenführer BrB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_BrB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_BrB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_BrB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_BrB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-e" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-e",
    "group" : "THW Personen",
    "name" : "Gruppenführer E",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_E.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_E.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_E.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_E.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-f" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-f",
    "group" : "THW Personen",
    "name" : "Gruppenführer F",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_F.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_F.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_F.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_F.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-i" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-i",
    "group" : "THW Personen",
    "name" : "Gruppenführer I",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_I.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-k" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-k",
    "group" : "THW Personen",
    "name" : "Gruppenführer K",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_K.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_K.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_K.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_K.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-ltr-fk" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-ltr-fk",
    "group" : "THW Personen",
    "name" : "Gruppenführer Ltr FK",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_Ltr_FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_Ltr_FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_Ltr_FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_Ltr_FK.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-n" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-n",
    "group" : "THW Personen",
    "name" : "Gruppenführer N",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_N.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_N.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_N.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_N.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-o" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-o",
    "group" : "THW Personen",
    "name" : "Gruppenführer O",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_O.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_O.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_O.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_O.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-r" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-r",
    "group" : "THW Personen",
    "name" : "Gruppenführer R",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_R.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_R.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_R.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_R.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-sb" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-sb",
    "group" : "THW Personen",
    "name" : "Gruppenführer SB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_SB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_SB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_SB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_SB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:gruppenfuhrer-sanitatsgruppe" : {
    "id" : "jonas-koeritz:rettungswesen-personen:gruppenfuhrer-sanitatsgruppe",
    "group" : "Rettungswesen Personen",
    "name" : "Gruppenführer Sanitätsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Gruppenführer_Sanitätsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Gruppenführer_Sanitätsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Gruppenführer_Sanitätsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Gruppenführer_Sanitätsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-sp" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-sp",
    "group" : "THW Personen",
    "name" : "Gruppenführer Sp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_Sp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_Sp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_Sp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_Sp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-tw" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-tw",
    "group" : "THW Personen",
    "name" : "Gruppenführer TW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_TW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_TW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_TW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_TW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:gruppenfuhrer-technik-und-sicherheit" : {
    "id" : "jonas-koeritz:rettungswesen-personen:gruppenfuhrer-technik-und-sicherheit",
    "group" : "Rettungswesen Personen",
    "name" : "Gruppenführer Technik und Sicherheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Gruppenführer_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Gruppenführer_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Gruppenführer_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Gruppenführer_Technik_und_Sicherheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:gruppenfuhrer-verpflegung" : {
    "id" : "jonas-koeritz:rettungswesen-personen:gruppenfuhrer-verpflegung",
    "group" : "Rettungswesen Personen",
    "name" : "Gruppenführer Verpflegung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Gruppenführer_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Gruppenführer_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Gruppenführer_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Gruppenführer_Verpflegung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-w" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-w",
    "group" : "THW Personen",
    "name" : "Gruppenführer W",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_W.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_W.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_W.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_W.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-wp" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-wp",
    "group" : "THW Personen",
    "name" : "Gruppenführer WP",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_WP.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_WP.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_WP.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_WP.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:gruppenfuhrer-ol" : {
    "id" : "jonas-koeritz:thw-personen:gruppenfuhrer-ol",
    "group" : "THW Personen",
    "name" : "Gruppenführer Öl",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Gruppenführer_Öl.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Gruppenführer_Öl.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Gruppenführer_Öl.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Gruppenführer_Öl.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:hlf" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:hlf",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "HLF",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/HLF.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/HLF.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/HLF.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/HLF.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:halteplatz-fur-fahrzeuge-zum-verletztentransport" : {
    "id" : "jonas-koeritz:einrichtungen:halteplatz-fur-fahrzeuge-zum-verletztentransport",
    "group" : "Einrichtungen",
    "name" : "Halteplatz für Fahrzeuge zum Verletztentransport",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Halteplatz_für_Fahrzeuge_zum_Verletztentransport.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Halteplatz_für_Fahrzeuge_zum_Verletztentransport.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Halteplatz_für_Fahrzeuge_zum_Verletztentransport.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Halteplatz_für_Fahrzeuge_zum_Verletztentransport.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:handfunkgerat-digital" : {
    "id" : "jonas-koeritz:fernmeldewesen:handfunkgerat-digital",
    "group" : "Fernmeldewesen",
    "name" : "Handfunkgerät digital",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Handfunkgerät_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Handfunkgerät_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Handfunkgerät_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Handfunkgerät_digital.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:helfer" : {
    "id" : "jonas-koeritz:thw-personen:helfer",
    "group" : "THW Personen",
    "name" : "Helfer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Helfer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Helfer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Helfer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Helfer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:helfer-fm" : {
    "id" : "jonas-koeritz:thw-personen:helfer-fm",
    "group" : "THW Personen",
    "name" : "Helfer Fm",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Helfer_Fm.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Helfer_Fm.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Helfer_Fm.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Helfer_Fm.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:helfer-kf" : {
    "id" : "jonas-koeritz:thw-personen:helfer-kf",
    "group" : "THW Personen",
    "name" : "Helfer Kf",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Helfer_Kf.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Helfer_Kf.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Helfer_Kf.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Helfer_Kf.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:helfer-peer" : {
    "id" : "jonas-koeritz:thw-personen:helfer-peer",
    "group" : "THW Personen",
    "name" : "Helfer Peer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Helfer_Peer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Helfer_Peer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Helfer_Peer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Helfer_Peer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:helfer-sgl" : {
    "id" : "jonas-koeritz:thw-personen:helfer-sgl",
    "group" : "THW Personen",
    "name" : "Helfer SGL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Helfer_SGL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Helfer_SGL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Helfer_SGL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Helfer_SGL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:helfer-psfk" : {
    "id" : "jonas-koeritz:thw-personen:helfer-psfk",
    "group" : "THW Personen",
    "name" : "Helfer psFK",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Helfer_psFK.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Helfer_psFK.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Helfer_psFK.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Helfer_psFK.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:hub" : {
    "id" : "jonas-koeritz:fernmeldewesen:hub",
    "group" : "Fernmeldewesen",
    "name" : "Hub",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Hub.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Hub.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Hub.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Hub.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:hubschrauber" : {
    "id" : "jonas-koeritz:fahrzeuge:hubschrauber",
    "group" : "Fahrzeuge",
    "name" : "Hubschrauber",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Hubschrauber.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Hubschrauber.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Hubschrauber.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Hubschrauber.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:hubschrauberlandeplatz" : {
    "id" : "jonas-koeritz:einrichtungen:hubschrauberlandeplatz",
    "group" : "Einrichtungen",
    "name" : "Hubschrauberlandeplatz",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Hubschrauberlandeplatz.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Hubschrauberlandeplatz.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Hubschrauberlandeplatz.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Hubschrauberlandeplatz.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:kommunal-fahrzeuge:hagglunds" : {
    "id" : "jonas-koeritz:kommunal-fahrzeuge:hagglunds",
    "group" : "Kommunal Fahrzeuge",
    "name" : "Hägglunds",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Kommunal_Fahrzeuge/Hägglunds.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Kommunal_Fahrzeuge/Hägglunds.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Kommunal_Fahrzeuge/Hägglunds.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Kommunal_Fahrzeuge/Hägglunds.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:iuk-zug" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:iuk-zug",
    "group" : "Feuerwehr Einheiten",
    "name" : "IuK Zug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/IuK_Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/IuK_Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/IuK_Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/IuK_Zug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:jugendgruppe" : {
    "id" : "jonas-koeritz:thw-einheiten:jugendgruppe",
    "group" : "THW Einheiten",
    "name" : "Jugendgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Jugendgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Jugendgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Jugendgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Jugendgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-fahrzeuge:ktw" : {
    "id" : "jonas-koeritz:rettungswesen-fahrzeuge:ktw",
    "group" : "Rettungswesen Fahrzeuge",
    "name" : "KTW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Fahrzeuge/KTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Fahrzeuge/KTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Fahrzeuge/KTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Fahrzeuge/KTW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:kabel-akb" : {
    "id" : "jonas-koeritz:fernmeldewesen:kabel-akb",
    "group" : "Fernmeldewesen",
    "name" : "Kabel Akb",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Kabel_Akb.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Kabel_Akb.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Kabel_Akb.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Kabel_Akb.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:kabel-ffkb" : {
    "id" : "jonas-koeritz:fernmeldewesen:kabel-ffkb",
    "group" : "Fernmeldewesen",
    "name" : "Kabel FFkb",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Kabel_FFkb.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Kabel_FFkb.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Kabel_FFkb.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Kabel_FFkb.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:kabel-fkb" : {
    "id" : "jonas-koeritz:fernmeldewesen:kabel-fkb",
    "group" : "Fernmeldewesen",
    "name" : "Kabel Fkb",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Kabel_Fkb.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Kabel_Fkb.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Kabel_Fkb.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Kabel_Fkb.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:kabel-lwl" : {
    "id" : "jonas-koeritz:fernmeldewesen:kabel-lwl",
    "group" : "Fernmeldewesen",
    "name" : "Kabel LWL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Kabel_LWL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Kabel_LWL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Kabel_LWL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Kabel_LWL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:kabel-vkb" : {
    "id" : "jonas-koeritz:fernmeldewesen:kabel-vkb",
    "group" : "Fernmeldewesen",
    "name" : "Kabel Vkb",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Kabel_Vkb.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Kabel_Vkb.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Kabel_Vkb.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Kabel_Vkb.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:kabelbau" : {
    "id" : "jonas-koeritz:fernmeldewesen:kabelbau",
    "group" : "Fernmeldewesen",
    "name" : "Kabelbau",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Kabelbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Kabelbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Kabelbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Kabelbau.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:kabelbau-leicht" : {
    "id" : "jonas-koeritz:fernmeldewesen:kabelbau-leicht",
    "group" : "Fernmeldewesen",
    "name" : "Kabelbau leicht",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Kabelbau_leicht.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Kabelbau_leicht.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Kabelbau_leicht.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Kabelbau_leicht.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:kabelbau-schwer" : {
    "id" : "jonas-koeritz:fernmeldewesen:kabelbau-schwer",
    "group" : "Fernmeldewesen",
    "name" : "Kabelbau schwer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Kabelbau_schwer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Kabelbau_schwer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Kabelbau_schwer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Kabelbau_schwer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:katsl" : {
    "id" : "jonas-koeritz:fuhrungsstellen:katsl",
    "group" : "Führungsstellen",
    "name" : "KatSL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/KatSL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/KatSL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/KatSL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/KatSL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:kettenbagger" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:kettenbagger",
    "group" : "THW Fahrzeuge",
    "name" : "Kettenbagger",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Kettenbagger.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Kettenbagger.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Kettenbagger.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Kettenbagger.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:kettenfahrzeug" : {
    "id" : "jonas-koeritz:fahrzeuge:kettenfahrzeug",
    "group" : "Fahrzeuge",
    "name" : "Kettenfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Kettenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Kettenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Kettenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Kettenfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:bundeswehr-fahrzeuge:kettenfahrzeug" : {
    "id" : "jonas-koeritz:bundeswehr-fahrzeuge:kettenfahrzeug",
    "group" : "Bundeswehr Fahrzeuge",
    "name" : "Kettenfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Bundeswehr_Fahrzeuge/Kettenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Bundeswehr_Fahrzeuge/Kettenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Bundeswehr_Fahrzeuge/Kettenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Bundeswehr_Fahrzeuge/Kettenfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:kettenfahrzeug" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:kettenfahrzeug",
    "group" : "THW Fahrzeuge",
    "name" : "Kettenfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Kettenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Kettenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Kettenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Kettenfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:kipper" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:kipper",
    "group" : "THW Fahrzeuge",
    "name" : "Kipper",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Kipper.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Kipper.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Kipper.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Kipper.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:kofferfunkgerat-digital" : {
    "id" : "jonas-koeritz:fernmeldewesen:kofferfunkgerat-digital",
    "group" : "Fernmeldewesen",
    "name" : "Kofferfunkgerät digital",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Kofferfunkgerät_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Kofferfunkgerät_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Kofferfunkgerät_digital.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Kofferfunkgerät_digital.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:kraftfahrzeug" : {
    "id" : "jonas-koeritz:fahrzeuge:kraftfahrzeug",
    "group" : "Fahrzeuge",
    "name" : "Kraftfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Kraftfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:polizei-fahrzeuge:kraftfahrzeug" : {
    "id" : "jonas-koeritz:polizei-fahrzeuge:kraftfahrzeug",
    "group" : "Polizei Fahrzeuge",
    "name" : "Kraftfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Polizei_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Polizei_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Polizei_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Polizei_Fahrzeuge/Kraftfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:kommunal-fahrzeuge:kraftfahrzeug" : {
    "id" : "jonas-koeritz:kommunal-fahrzeuge:kraftfahrzeug",
    "group" : "Kommunal Fahrzeuge",
    "name" : "Kraftfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Kommunal_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Kommunal_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Kommunal_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Kommunal_Fahrzeuge/Kraftfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:bundeswehr-fahrzeuge:kraftfahrzeug" : {
    "id" : "jonas-koeritz:bundeswehr-fahrzeuge:kraftfahrzeug",
    "group" : "Bundeswehr Fahrzeuge",
    "name" : "Kraftfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Bundeswehr_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Bundeswehr_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Bundeswehr_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Bundeswehr_Fahrzeuge/Kraftfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-fahrzeuge:kraftfahrzeug" : {
    "id" : "jonas-koeritz:rettungswesen-fahrzeuge:kraftfahrzeug",
    "group" : "Rettungswesen Fahrzeuge",
    "name" : "Kraftfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Fahrzeuge/Kraftfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:kraftfahrzeug" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:kraftfahrzeug",
    "group" : "THW Fahrzeuge",
    "name" : "Kraftfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Kraftfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:kraftfahrzeug" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:kraftfahrzeug",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Kraftfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Kraftfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Kraftfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-fahrzeuge:kraftfahrzeug-gelandegangig" : {
    "id" : "jonas-koeritz:rettungswesen-fahrzeuge:kraftfahrzeug-gelandegangig",
    "group" : "Rettungswesen Fahrzeuge",
    "name" : "Kraftfahrzeug Geländegängig",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Fahrzeuge/Kraftfahrzeug_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Fahrzeuge/Kraftfahrzeug_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Fahrzeuge/Kraftfahrzeug_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Fahrzeuge/Kraftfahrzeug_Geländegängig.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:kraftfahrzeug-gelandegangig" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:kraftfahrzeug-gelandegangig",
    "group" : "THW Fahrzeuge",
    "name" : "Kraftfahrzeug Geländegängig",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Kraftfahrzeug_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Kraftfahrzeug_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Kraftfahrzeug_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Kraftfahrzeug_Geländegängig.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:kraftfahrzeug-gelandegangig" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:kraftfahrzeug-gelandegangig",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Kraftfahrzeug Geländegängig",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Kraftfahrzeug_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Kraftfahrzeug_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Kraftfahrzeug_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Kraftfahrzeug_Geländegängig.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:kraftfahrzeug-gelandegangig" : {
    "id" : "jonas-koeritz:fahrzeuge:kraftfahrzeug-gelandegangig",
    "group" : "Fahrzeuge",
    "name" : "Kraftfahrzeug geländegängig",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Kraftfahrzeug_geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Kraftfahrzeug_geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Kraftfahrzeug_geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Kraftfahrzeug_geländegängig.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:kommunal-fahrzeuge:kraftfahrzeug-gelandegangig" : {
    "id" : "jonas-koeritz:kommunal-fahrzeuge:kraftfahrzeug-gelandegangig",
    "group" : "Kommunal Fahrzeuge",
    "name" : "Kraftfahrzeug geländegängig",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Kommunal_Fahrzeuge/Kraftfahrzeug_geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Kommunal_Fahrzeuge/Kraftfahrzeug_geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Kommunal_Fahrzeuge/Kraftfahrzeug_geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Kommunal_Fahrzeuge/Kraftfahrzeug_geländegängig.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:bundeswehr-fahrzeuge:kraftfahrzeug-gelandegangig" : {
    "id" : "jonas-koeritz:bundeswehr-fahrzeuge:kraftfahrzeug-gelandegangig",
    "group" : "Bundeswehr Fahrzeuge",
    "name" : "Kraftfahrzeug geländegängig",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Bundeswehr_Fahrzeuge/Kraftfahrzeug_geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Bundeswehr_Fahrzeuge/Kraftfahrzeug_geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Bundeswehr_Fahrzeuge/Kraftfahrzeug_geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Bundeswehr_Fahrzeuge/Kraftfahrzeug_geländegängig.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:kraftrad" : {
    "id" : "jonas-koeritz:fahrzeuge:kraftrad",
    "group" : "Fahrzeuge",
    "name" : "Kraftrad",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Kraftrad.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Kraftrad.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Kraftrad.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Kraftrad.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gebaude:krankenhaus" : {
    "id" : "jonas-koeritz:gebaude:krankenhaus",
    "group" : "Gebäude",
    "name" : "Krankenhaus",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gebäude/Krankenhaus.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gebäude/Krankenhaus.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gebäude/Krankenhaus.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gebäude/Krankenhaus.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:lf-16-ts" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:lf-16-ts",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "LF 16-TS",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/LF_16-TS.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/LF_16-TS.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/LF_16-TS.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/LF_16-TS.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:lf-24-50" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:lf-24-50",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "LF 24-50",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/LF_24-50.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/LF_24-50.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/LF_24-50.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/LF_24-50.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:lf-8-6" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:lf-8-6",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "LF 8-6",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/LF_8-6.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/LF_8-6.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/LF_8-6.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/LF_8-6.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:lf-kats" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:lf-kats",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "LF KatS",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/LF_KatS.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/LF_KatS.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/LF_KatS.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/LF_KatS.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:lkw" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:lkw",
    "group" : "THW Fahrzeuge",
    "name" : "LKW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/LKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/LKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/LKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/LKW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:lkw-k-9t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:lkw-k-9t",
    "group" : "THW Fahrzeuge",
    "name" : "LKW K 9t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/LKW_K_9t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/LKW_K_9t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/LKW_K_9t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/LKW_K_9t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:lkw-k-lkr" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:lkw-k-lkr",
    "group" : "THW Fahrzeuge",
    "name" : "LKW K Lkr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/LKW_K_Lkr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/LKW_K_Lkr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/LKW_K_Lkr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/LKW_K_Lkr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:lkw-k-lkr-1-5t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:lkw-k-lkr-1-5t",
    "group" : "THW Fahrzeuge",
    "name" : "LKW K Lkr 1 5t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/LKW_K_Lkr_1_5t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/LKW_K_Lkr_1_5t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/LKW_K_Lkr_1_5t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/LKW_K_Lkr_1_5t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:lkw-lbw" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:lkw-lbw",
    "group" : "THW Fahrzeuge",
    "name" : "LKW Lbw",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/LKW_Lbw.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/LKW_Lbw.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/LKW_Lbw.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/LKW_Lbw.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:lkw-lbw-7t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:lkw-lbw-7t",
    "group" : "THW Fahrzeuge",
    "name" : "LKW Lbw 7t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/LKW_Lbw_7t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/LKW_Lbw_7t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/LKW_Lbw_7t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/LKW_Lbw_7t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:lkw-ladekran" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:lkw-ladekran",
    "group" : "THW Fahrzeuge",
    "name" : "LKW-Ladekran",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/LKW-Ladekran.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/LKW-Ladekran.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/LKW-Ladekran.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/LKW-Ladekran.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-gebaude:landesverband" : {
    "id" : "jonas-koeritz:thw-gebaude:landesverband",
    "group" : "THW Gebäude",
    "name" : "Landesverband",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Gebäude/Landesverband.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Gebäude/Landesverband.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Gebäude/Landesverband.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Gebäude/Landesverband.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:leitender-notarzt" : {
    "id" : "jonas-koeritz:rettungswesen-personen:leitender-notarzt",
    "group" : "Rettungswesen Personen",
    "name" : "Leitender Notarzt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Leitender_Notarzt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Leitender_Notarzt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Leitender_Notarzt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Leitender_Notarzt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:leitstelle" : {
    "id" : "jonas-koeritz:einrichtungen:leitstelle",
    "group" : "Einrichtungen",
    "name" : "Leitstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Leitstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Leitstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Leitstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Leitstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-gebaude:leitung" : {
    "id" : "jonas-koeritz:thw-gebaude:leitung",
    "group" : "THW Gebäude",
    "name" : "Leitung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Gebäude/Leitung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Gebäude/Leitung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Gebäude/Leitung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Gebäude/Leitung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:logistikeinheit" : {
    "id" : "jonas-koeritz:thw-einheiten:logistikeinheit",
    "group" : "THW Einheiten",
    "name" : "Logistikeinheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Logistikeinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Logistikeinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Logistikeinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Logistikeinheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:logistikstutzpunkt" : {
    "id" : "jonas-koeritz:einrichtungen:logistikstutzpunkt",
    "group" : "Einrichtungen",
    "name" : "Logistikstützpunkt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Logistikstützpunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Logistikstützpunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Logistikstützpunkt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Logistikstützpunkt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:logistiktrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:logistiktrupp",
    "group" : "THW Einheiten",
    "name" : "Logistiktrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Logistiktrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Logistiktrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Logistiktrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Logistiktrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-gebaude:logistikzentrum" : {
    "id" : "jonas-koeritz:thw-gebaude:logistikzentrum",
    "group" : "THW Gebäude",
    "name" : "Logistikzentrum",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Gebäude/Logistikzentrum.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Gebäude/Logistikzentrum.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Gebäude/Logistikzentrum.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Gebäude/Logistikzentrum.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:lotsengruppe" : {
    "id" : "jonas-koeritz:thw-einheiten:lotsengruppe",
    "group" : "THW Einheiten",
    "name" : "Lotsengruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Lotsengruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Lotsengruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Lotsengruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Lotsengruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:lotsenstelle" : {
    "id" : "jonas-koeritz:einrichtungen:lotsenstelle",
    "group" : "Einrichtungen",
    "name" : "Lotsenstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Lotsenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Lotsenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Lotsenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Lotsenstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:langenverbindung-1" : {
    "id" : "jonas-koeritz:fernmeldewesen:langenverbindung-1",
    "group" : "Fernmeldewesen",
    "name" : "Längenverbindung-1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Längenverbindung-1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Längenverbindung-1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Längenverbindung-1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Längenverbindung-1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:langenverbindung-2" : {
    "id" : "jonas-koeritz:fernmeldewesen:langenverbindung-2",
    "group" : "Fernmeldewesen",
    "name" : "Längenverbindung-2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Längenverbindung-2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Längenverbindung-2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Längenverbindung-2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Längenverbindung-2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:loschfahrzeug" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:loschfahrzeug",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Löschfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Löschfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Löschfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Löschfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Löschfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:loschgruppe" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:loschgruppe",
    "group" : "Feuerwehr Einheiten",
    "name" : "Löschgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Löschgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Löschgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Löschgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Löschgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:loschstaffel" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:loschstaffel",
    "group" : "Feuerwehr Einheiten",
    "name" : "Löschstaffel",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Löschstaffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Löschstaffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Löschstaffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Löschstaffel.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:loschtrupp" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:loschtrupp",
    "group" : "Feuerwehr Einheiten",
    "name" : "Löschtrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Löschtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Löschtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Löschtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Löschtrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:loschzug" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:loschzug",
    "group" : "Feuerwehr Einheiten",
    "name" : "Löschzug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Löschzug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Löschzug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Löschzug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Löschzug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:loschzug-berufsfeuerwehr" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:loschzug-berufsfeuerwehr",
    "group" : "Feuerwehr Einheiten",
    "name" : "Löschzug Berufsfeuerwehr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Löschzug_Berufsfeuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Löschzug_Berufsfeuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Löschzug_Berufsfeuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Löschzug_Berufsfeuerwehr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:loschzug-freiwillige-feuerwehr" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:loschzug-freiwillige-feuerwehr",
    "group" : "Feuerwehr Einheiten",
    "name" : "Löschzug Freiwillige Feuerwehr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Löschzug_Freiwillige_Feuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Löschzug_Freiwillige_Feuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Löschzug_Freiwillige_Feuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Löschzug_Freiwillige_Feuerwehr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:loschzug-wasser" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:loschzug-wasser",
    "group" : "Feuerwehr Einheiten",
    "name" : "Löschzug Wasser",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Löschzug_Wasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Löschzug_Wasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Löschzug_Wasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Löschzug_Wasser.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:loschzug-werksfeuerwehr" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:loschzug-werksfeuerwehr",
    "group" : "Feuerwehr Einheiten",
    "name" : "Löschzug Werksfeuerwehr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Löschzug_Werksfeuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Löschzug_Werksfeuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Löschzug_Werksfeuerwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Löschzug_Werksfeuerwehr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mlw-i" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mlw-i",
    "group" : "THW Fahrzeuge",
    "name" : "MLW I",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/MLW_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/MLW_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/MLW_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/MLW_I.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mlw-ii" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mlw-ii",
    "group" : "THW Fahrzeuge",
    "name" : "MLW II",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/MLW_II.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/MLW_II.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/MLW_II.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/MLW_II.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mlw-iii" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mlw-iii",
    "group" : "THW Fahrzeuge",
    "name" : "MLW III",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/MLW_III.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/MLW_III.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/MLW_III.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/MLW_III.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mlw-iv" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mlw-iv",
    "group" : "THW Fahrzeuge",
    "name" : "MLW IV",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/MLW_IV.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/MLW_IV.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/MLW_IV.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/MLW_IV.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mlw-v" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mlw-v",
    "group" : "THW Fahrzeuge",
    "name" : "MLW V",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/MLW_V.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/MLW_V.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/MLW_V.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/MLW_V.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:kommunal-fahrzeuge:mtw" : {
    "id" : "jonas-koeritz:kommunal-fahrzeuge:mtw",
    "group" : "Kommunal Fahrzeuge",
    "name" : "MTW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Kommunal_Fahrzeuge/MTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Kommunal_Fahrzeuge/MTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Kommunal_Fahrzeuge/MTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Kommunal_Fahrzeuge/MTW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mtw" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mtw",
    "group" : "THW Fahrzeuge",
    "name" : "MTW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/MTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/MTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/MTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/MTW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mtw-fgr" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mtw-fgr",
    "group" : "THW Fahrzeuge",
    "name" : "MTW FGr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/MTW_FGr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/MTW_FGr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/MTW_FGr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/MTW_FGr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:maschinist" : {
    "id" : "jonas-koeritz:feuerwehr-personen:maschinist",
    "group" : "Feuerwehr Personen",
    "name" : "Maschinist",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Maschinist.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Maschinist.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Maschinist.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Maschinist.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:ma-nahmen:ma-nahme" : {
    "id" : "jonas-koeritz:ma-nahmen:ma-nahme",
    "group" : "Maßnahmen",
    "name" : "Maßnahme",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Maßnahmen/Maßnahme.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Maßnahmen/Maßnahme.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Maßnahmen/Maßnahme.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Maßnahmen/Maßnahme.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:kommunal-fahrzeuge:mehrzweckarbeitsboot" : {
    "id" : "jonas-koeritz:kommunal-fahrzeuge:mehrzweckarbeitsboot",
    "group" : "Kommunal Fahrzeuge",
    "name" : "Mehrzweckarbeitsboot",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Kommunal_Fahrzeuge/Mehrzweckarbeitsboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Kommunal_Fahrzeuge/Mehrzweckarbeitsboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Kommunal_Fahrzeuge/Mehrzweckarbeitsboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Kommunal_Fahrzeuge/Mehrzweckarbeitsboot.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mehrzweckarbeitsboot" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mehrzweckarbeitsboot",
    "group" : "THW Fahrzeuge",
    "name" : "Mehrzweckarbeitsboot",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Mehrzweckarbeitsboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Mehrzweckarbeitsboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Mehrzweckarbeitsboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Mehrzweckarbeitsboot.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mehrzweckboot" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mehrzweckboot",
    "group" : "THW Fahrzeuge",
    "name" : "Mehrzweckboot",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Mehrzweckboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Mehrzweckboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Mehrzweckboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Mehrzweckboot.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mehrzweckponton" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mehrzweckponton",
    "group" : "THW Fahrzeuge",
    "name" : "Mehrzweckponton",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Mehrzweckponton.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Mehrzweckponton.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Mehrzweckponton.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Mehrzweckponton.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:meldekopf" : {
    "id" : "jonas-koeritz:einrichtungen:meldekopf",
    "group" : "Einrichtungen",
    "name" : "Meldekopf",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Meldekopf.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Meldekopf.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Meldekopf.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Meldekopf.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:melder" : {
    "id" : "jonas-koeritz:personen:melder",
    "group" : "Personen",
    "name" : "Melder",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Melder.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Melder.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Melder.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Melder.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:melder" : {
    "id" : "jonas-koeritz:feuerwehr-personen:melder",
    "group" : "Feuerwehr Personen",
    "name" : "Melder",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Melder.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Melder.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Melder.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Melder.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:messfahrzeug" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:messfahrzeug",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Messfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Messfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Messfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Messfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Messfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:modem" : {
    "id" : "jonas-koeritz:fernmeldewesen:modem",
    "group" : "Fernmeldewesen",
    "name" : "Modem",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Modem.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Modem.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Modem.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Modem.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:moderat-erhohte-aktivitat" : {
    "id" : "jonas-koeritz:sonstiges:moderat-erhohte-aktivitat",
    "group" : "Sonstiges",
    "name" : "Moderat erhöhte Aktivität",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Moderat_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Moderat_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Moderat_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Moderat_erhöhte_Aktivität.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mzgw" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mzgw",
    "group" : "THW Fahrzeuge",
    "name" : "MzGW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/MzGW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/MzGW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/MzGW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/MzGW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:mzkw" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:mzkw",
    "group" : "THW Fahrzeuge",
    "name" : "MzKW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/MzKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/MzKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/MzKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/MzKW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:netzwerk-brucke" : {
    "id" : "jonas-koeritz:fernmeldewesen:netzwerk-brucke",
    "group" : "Fernmeldewesen",
    "name" : "Netzwerk Brücke",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Netzwerk_Brücke.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Netzwerk_Brücke.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Netzwerk_Brücke.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Netzwerk_Brücke.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:netzubergang" : {
    "id" : "jonas-koeritz:fernmeldewesen:netzubergang",
    "group" : "Fernmeldewesen",
    "name" : "Netzübergang",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Netzübergang.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Netzübergang.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Netzübergang.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Netzübergang.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-fahrzeuge:notarzteinsatzfahrzeug" : {
    "id" : "jonas-koeritz:rettungswesen-fahrzeuge:notarzteinsatzfahrzeug",
    "group" : "Rettungswesen Fahrzeuge",
    "name" : "Notarzteinsatzfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Fahrzeuge/Notarzteinsatzfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Fahrzeuge/Notarzteinsatzfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Fahrzeuge/Notarzteinsatzfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Fahrzeuge/Notarzteinsatzfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-fahrzeuge:notarztwagen" : {
    "id" : "jonas-koeritz:rettungswesen-fahrzeuge:notarztwagen",
    "group" : "Rettungswesen Fahrzeuge",
    "name" : "Notarztwagen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Fahrzeuge/Notarztwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Fahrzeuge/Notarztwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Fahrzeuge/Notarztwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Fahrzeuge/Notarztwagen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:ob-telefon" : {
    "id" : "jonas-koeritz:fernmeldewesen:ob-telefon",
    "group" : "Fernmeldewesen",
    "name" : "OB Telefon",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/OB_Telefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/OB_Telefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/OB_Telefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/OB_Telefon.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:ov-stab" : {
    "id" : "jonas-koeritz:thw-einheiten:ov-stab",
    "group" : "THW Einheiten",
    "name" : "OV Stab",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/OV_Stab.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/OV_Stab.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/OV_Stab.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/OV_Stab.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-gebaude:ov-unterkunft" : {
    "id" : "jonas-koeritz:thw-gebaude:ov-unterkunft",
    "group" : "THW Gebäude",
    "name" : "OV Unterkunft",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Gebäude/OV_Unterkunft.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Gebäude/OV_Unterkunft.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Gebäude/OV_Unterkunft.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Gebäude/OV_Unterkunft.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:organisatorischer-leiter-rettungsdienst" : {
    "id" : "jonas-koeritz:rettungswesen-personen:organisatorischer-leiter-rettungsdienst",
    "group" : "Rettungswesen Personen",
    "name" : "Organisatorischer Leiter Rettungsdienst",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Organisatorischer_Leiter_Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Organisatorischer_Leiter_Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Organisatorischer_Leiter_Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Organisatorischer_Leiter_Rettungsdienst.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:ortsfest" : {
    "id" : "jonas-koeritz:sonstiges:ortsfest",
    "group" : "Sonstiges",
    "name" : "Ortsfest",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Ortsfest.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Ortsfest.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Ortsfest.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Ortsfest.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:pc" : {
    "id" : "jonas-koeritz:fernmeldewesen:pc",
    "group" : "Fernmeldewesen",
    "name" : "PC",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/PC.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/PC.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/PC.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/PC.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:zoll-fahrzeuge:pkw" : {
    "id" : "jonas-koeritz:zoll-fahrzeuge:pkw",
    "group" : "Zoll Fahrzeuge",
    "name" : "PKW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Zoll_Fahrzeuge/PKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Zoll_Fahrzeuge/PKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Zoll_Fahrzeuge/PKW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Zoll_Fahrzeuge/PKW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:polizei-fahrzeuge:pkw-bundespolizei" : {
    "id" : "jonas-koeritz:polizei-fahrzeuge:pkw-bundespolizei",
    "group" : "Polizei Fahrzeuge",
    "name" : "PKW Bundespolizei",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Polizei_Fahrzeuge/PKW_Bundespolizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Polizei_Fahrzeuge/PKW_Bundespolizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Polizei_Fahrzeuge/PKW_Bundespolizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Polizei_Fahrzeuge/PKW_Bundespolizei.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:pkw-gelandegangig" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:pkw-gelandegangig",
    "group" : "THW Fahrzeuge",
    "name" : "PKW Geländegängig",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/PKW_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/PKW_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/PKW_Geländegängig.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/PKW_Geländegängig.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:polizei-fahrzeuge:pkw-polizei" : {
    "id" : "jonas-koeritz:polizei-fahrzeuge:pkw-polizei",
    "group" : "Polizei Fahrzeuge",
    "name" : "PKW Polizei",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Polizei_Fahrzeuge/PKW_Polizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Polizei_Fahrzeuge/PKW_Polizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Polizei_Fahrzeuge/PKW_Polizei.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Polizei_Fahrzeuge/PKW_Polizei.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:pkw-weitverkehrstrupp" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:pkw-weitverkehrstrupp",
    "group" : "THW Fahrzeuge",
    "name" : "PKW Weitverkehrstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/PKW_Weitverkehrstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/PKW_Weitverkehrstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/PKW_Weitverkehrstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/PKW_Weitverkehrstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einrichtungen:patientenablage" : {
    "id" : "jonas-koeritz:rettungswesen-einrichtungen:patientenablage",
    "group" : "Rettungswesen Einrichtungen",
    "name" : "Patientenablage",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einrichtungen/Patientenablage.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einrichtungen/Patientenablage.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einrichtungen/Patientenablage.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einrichtungen/Patientenablage.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:patiententransportgruppe" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:patiententransportgruppe",
    "group" : "Rettungswesen Einheiten",
    "name" : "Patiententransportgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Patiententransportgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Patiententransportgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Patiententransportgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Patiententransportgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:person" : {
    "id" : "jonas-koeritz:personen:person",
    "group" : "Personen",
    "name" : "Person",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Person.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:person-mit-sonderfunktion" : {
    "id" : "jonas-koeritz:personen:person-mit-sonderfunktion",
    "group" : "Personen",
    "name" : "Person mit Sonderfunktion",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Person_mit_Sonderfunktion.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Person_mit_Sonderfunktion.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Person_mit_Sonderfunktion.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Person_mit_Sonderfunktion.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:bundeswehr-personen:person-mit-sonderfunktion" : {
    "id" : "jonas-koeritz:bundeswehr-personen:person-mit-sonderfunktion",
    "group" : "Bundeswehr Personen",
    "name" : "Person mit Sonderfunktion",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Bundeswehr_Personen/Person_mit_Sonderfunktion.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Bundeswehr_Personen/Person_mit_Sonderfunktion.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Bundeswehr_Personen/Person_mit_Sonderfunktion.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Bundeswehr_Personen/Person_mit_Sonderfunktion.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:personenkraftwagen" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:personenkraftwagen",
    "group" : "THW Fahrzeuge",
    "name" : "Personenkraftwagen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Personenkraftwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Personenkraftwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Personenkraftwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Personenkraftwagen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:rs2" : {
    "id" : "jonas-koeritz:fernmeldewesen:rs2",
    "group" : "Fernmeldewesen",
    "name" : "RS2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/RS2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/RS2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/RS2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/RS2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:rs2-2m-2m" : {
    "id" : "jonas-koeritz:fernmeldewesen:rs2-2m-2m",
    "group" : "Fernmeldewesen",
    "name" : "RS2 2m-2m",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/RS2_2m-2m.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/RS2_2m-2m.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/RS2_2m-2m.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/RS2_2m-2m.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:rs2-2m-4m" : {
    "id" : "jonas-koeritz:fernmeldewesen:rs2-2m-4m",
    "group" : "Fernmeldewesen",
    "name" : "RS2 2m-4m",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/RS2_2m-4m.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/RS2_2m-4m.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/RS2_2m-4m.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/RS2_2m-4m.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:rs2-4m-4m" : {
    "id" : "jonas-koeritz:fernmeldewesen:rs2-4m-4m",
    "group" : "Fernmeldewesen",
    "name" : "RS2 4m-4m",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/RS2_4m-4m.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/RS2_4m-4m.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/RS2_4m-4m.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/RS2_4m-4m.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-fahrzeuge:rtw" : {
    "id" : "jonas-koeritz:rettungswesen-fahrzeuge:rtw",
    "group" : "Rettungswesen Fahrzeuge",
    "name" : "RTW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Fahrzeuge/RTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Fahrzeuge/RTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Fahrzeuge/RTW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Fahrzeuge/RTW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:radbagger" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:radbagger",
    "group" : "THW Fahrzeuge",
    "name" : "Radbagger",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Radbagger.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Radbagger.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Radbagger.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Radbagger.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:radlader" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:radlader",
    "group" : "THW Fahrzeuge",
    "name" : "Radlader",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Radlader.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Radlader.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Radlader.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Radlader.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:randtrummer" : {
    "id" : "jonas-koeritz:schaden:randtrummer",
    "group" : "Schäden",
    "name" : "Randtrümmer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Randtrümmer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Randtrümmer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Randtrümmer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Randtrümmer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:reaktorerkundungskraftwagen" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:reaktorerkundungskraftwagen",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Reaktorerkundungskraftwagen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Reaktorerkundungskraftwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Reaktorerkundungskraftwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Reaktorerkundungskraftwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Reaktorerkundungskraftwagen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-gebaude:regionalstelle" : {
    "id" : "jonas-koeritz:thw-gebaude:regionalstelle",
    "group" : "THW Gebäude",
    "name" : "Regionalstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Gebäude/Regionalstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Gebäude/Regionalstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Gebäude/Regionalstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Gebäude/Regionalstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:relaisfunkbetrieb" : {
    "id" : "jonas-koeritz:fernmeldewesen:relaisfunkbetrieb",
    "group" : "Fernmeldewesen",
    "name" : "Relaisfunkbetrieb",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Relaisfunkbetrieb.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Relaisfunkbetrieb.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Relaisfunkbetrieb.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Relaisfunkbetrieb.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:repeatersstation" : {
    "id" : "jonas-koeritz:fernmeldewesen:repeatersstation",
    "group" : "Fernmeldewesen",
    "name" : "Repeatersstation",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Repeatersstation.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Repeatersstation.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Repeatersstation.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Repeatersstation.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:ma-nahmen:retten-aus-hohen-und-tiefen" : {
    "id" : "jonas-koeritz:ma-nahmen:retten-aus-hohen-und-tiefen",
    "group" : "Maßnahmen",
    "name" : "Retten aus Höhen und Tiefen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Maßnahmen/Retten_aus_Höhen_und_Tiefen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Maßnahmen/Retten_aus_Höhen_und_Tiefen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Maßnahmen/Retten_aus_Höhen_und_Tiefen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Maßnahmen/Retten_aus_Höhen_und_Tiefen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:rettungs-und-sicherungsboot" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:rettungs-und-sicherungsboot",
    "group" : "THW Fahrzeuge",
    "name" : "Rettungs und Sicherungsboot",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Rettungs_und_Sicherungsboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Rettungs_und_Sicherungsboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Rettungs_und_Sicherungsboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Rettungs_und_Sicherungsboot.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:rettungsdienst" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:rettungsdienst",
    "group" : "Rettungswesen Einheiten",
    "name" : "Rettungsdienst",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Rettungsdienst.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Rettungsdienst.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:rettungshund" : {
    "id" : "jonas-koeritz:sonstiges:rettungshund",
    "group" : "Sonstiges",
    "name" : "Rettungshund",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Rettungshund.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Rettungshund.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Rettungshund.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Rettungshund.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:richtfunk" : {
    "id" : "jonas-koeritz:fernmeldewesen:richtfunk",
    "group" : "Fernmeldewesen",
    "name" : "Richtfunk",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Richtfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Richtfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Richtfunk.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Richtfunk.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:richtfunk-endstelle" : {
    "id" : "jonas-koeritz:fernmeldewesen:richtfunk-endstelle",
    "group" : "Fernmeldewesen",
    "name" : "Richtfunk Endstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Richtfunk_Endstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Richtfunk_Endstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Richtfunk_Endstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Richtfunk_Endstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:richtfunk-endstelle-mit-ubertragungstechnik" : {
    "id" : "jonas-koeritz:fernmeldewesen:richtfunk-endstelle-mit-ubertragungstechnik",
    "group" : "Fernmeldewesen",
    "name" : "Richtfunk Endstelle mit Übertragungstechnik",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Richtfunk_Endstelle_mit_Übertragungstechnik.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Richtfunk_Endstelle_mit_Übertragungstechnik.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Richtfunk_Endstelle_mit_Übertragungstechnik.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Richtfunk_Endstelle_mit_Übertragungstechnik.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:richtfunk-relaisstelle" : {
    "id" : "jonas-koeritz:fernmeldewesen:richtfunk-relaisstelle",
    "group" : "Fernmeldewesen",
    "name" : "Richtfunk Relaisstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Richtfunk_Relaisstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Richtfunk_Relaisstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Richtfunk_Relaisstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Richtfunk_Relaisstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:richtung" : {
    "id" : "jonas-koeritz:sonstiges:richtung",
    "group" : "Sonstiges",
    "name" : "Richtung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Richtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Richtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Richtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Richtung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:rotkreuzbeauftragter" : {
    "id" : "jonas-koeritz:rettungswesen-personen:rotkreuzbeauftragter",
    "group" : "Rettungswesen Personen",
    "name" : "Rotkreuzbeauftragter",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Rotkreuzbeauftragter.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Rotkreuzbeauftragter.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Rotkreuzbeauftragter.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Rotkreuzbeauftragter.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:router" : {
    "id" : "jonas-koeritz:fernmeldewesen:router",
    "group" : "Fernmeldewesen",
    "name" : "Router",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Router.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Router.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Router.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Router.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:rutschflache" : {
    "id" : "jonas-koeritz:schaden:rutschflache",
    "group" : "Schäden",
    "name" : "Rutschfläche",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Rutschfläche.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Rutschfläche.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Rutschfläche.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Rutschfläche.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:ma-nahmen:raumen" : {
    "id" : "jonas-koeritz:ma-nahmen:raumen",
    "group" : "Maßnahmen",
    "name" : "Räumen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Maßnahmen/Räumen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Maßnahmen/Räumen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Maßnahmen/Räumen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Maßnahmen/Räumen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:rustwagen" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:rustwagen",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Rüstwagen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Rüstwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Rüstwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Rüstwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Rüstwagen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:seeba" : {
    "id" : "jonas-koeritz:thw-einheiten:seeba",
    "group" : "THW Einheiten",
    "name" : "SEEBA",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/SEEBA.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/SEEBA.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/SEEBA.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/SEEBA.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:seelift" : {
    "id" : "jonas-koeritz:thw-einheiten:seelift",
    "group" : "THW Einheiten",
    "name" : "SEELift",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/SEELift.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/SEELift.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/SEELift.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/SEELift.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:seewa" : {
    "id" : "jonas-koeritz:thw-einheiten:seewa",
    "group" : "THW Einheiten",
    "name" : "SEEWA",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/SEEWA.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/SEEWA.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/SEEWA.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/SEEWA.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:sammeln" : {
    "id" : "jonas-koeritz:sonstiges:sammeln",
    "group" : "Sonstiges",
    "name" : "Sammeln",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Sammeln.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Sammeln.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Sammeln.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Sammeln.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einrichtungen:sammelplatz-fur-betroffene" : {
    "id" : "jonas-koeritz:rettungswesen-einrichtungen:sammelplatz-fur-betroffene",
    "group" : "Rettungswesen Einrichtungen",
    "name" : "Sammelplatz für betroffene",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einrichtungen/Sammelplatz_für_betroffene.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einrichtungen/Sammelplatz_für_betroffene.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einrichtungen/Sammelplatz_für_betroffene.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einrichtungen/Sammelplatz_für_betroffene.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:sammelstelle" : {
    "id" : "jonas-koeritz:einrichtungen:sammelstelle",
    "group" : "Einrichtungen",
    "name" : "Sammelstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Sammelstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Sammelstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Sammelstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Sammelstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:sanitatsgruppe" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:sanitatsgruppe",
    "group" : "Rettungswesen Einheiten",
    "name" : "Sanitätsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Sanitätsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Sanitätsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Sanitätsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Sanitätsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:sanitatsgruppe-arzt" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:sanitatsgruppe-arzt",
    "group" : "Rettungswesen Einheiten",
    "name" : "Sanitätsgruppe Arzt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Sanitätsgruppe_Arzt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Sanitätsgruppe_Arzt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Sanitätsgruppe_Arzt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Sanitätsgruppe_Arzt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:sanitatstrupp" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:sanitatstrupp",
    "group" : "Rettungswesen Einheiten",
    "name" : "Sanitätstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Sanitätstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Sanitätstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Sanitätstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Sanitätstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:sanitatszug" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:sanitatszug",
    "group" : "Rettungswesen Einheiten",
    "name" : "Sanitätszug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Sanitätszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Sanitätszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Sanitätszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Sanitätszug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:sanitatszug-arzt" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:sanitatszug-arzt",
    "group" : "Rettungswesen Einheiten",
    "name" : "Sanitätszug Arzt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Sanitätszug_Arzt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Sanitätszug_Arzt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Sanitätszug_Arzt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Sanitätszug_Arzt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto" : {
    "id" : "jonas-koeritz:wei-:schadenskonto",
    "group" : "weiß",
    "name" : "Schadenskonto",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto" : {
    "id" : "jonas-koeritz:rot:schadenskonto",
    "group" : "rot",
    "name" : "Schadenskonto",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto" : {
    "id" : "jonas-koeritz:gelb:schadenskonto",
    "group" : "gelb",
    "name" : "Schadenskonto",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-1" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-1",
    "group" : "weiß",
    "name" : "Schadenskonto 1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-1" : {
    "id" : "jonas-koeritz:rot:schadenskonto-1",
    "group" : "rot",
    "name" : "Schadenskonto 1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-1" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-1",
    "group" : "gelb",
    "name" : "Schadenskonto 1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-10" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-10",
    "group" : "weiß",
    "name" : "Schadenskonto 10",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_10.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-10" : {
    "id" : "jonas-koeritz:rot:schadenskonto-10",
    "group" : "rot",
    "name" : "Schadenskonto 10",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_10.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-10" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-10",
    "group" : "gelb",
    "name" : "Schadenskonto 10",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_10.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-11" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-11",
    "group" : "weiß",
    "name" : "Schadenskonto 11",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_11.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-11" : {
    "id" : "jonas-koeritz:rot:schadenskonto-11",
    "group" : "rot",
    "name" : "Schadenskonto 11",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_11.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-11" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-11",
    "group" : "gelb",
    "name" : "Schadenskonto 11",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_11.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-12" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-12",
    "group" : "weiß",
    "name" : "Schadenskonto 12",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_12.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-12" : {
    "id" : "jonas-koeritz:rot:schadenskonto-12",
    "group" : "rot",
    "name" : "Schadenskonto 12",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_12.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-12" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-12",
    "group" : "gelb",
    "name" : "Schadenskonto 12",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_12.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-13" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-13",
    "group" : "weiß",
    "name" : "Schadenskonto 13",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_13.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-13" : {
    "id" : "jonas-koeritz:rot:schadenskonto-13",
    "group" : "rot",
    "name" : "Schadenskonto 13",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_13.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-13" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-13",
    "group" : "gelb",
    "name" : "Schadenskonto 13",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_13.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-14" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-14",
    "group" : "weiß",
    "name" : "Schadenskonto 14",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_14.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-14" : {
    "id" : "jonas-koeritz:rot:schadenskonto-14",
    "group" : "rot",
    "name" : "Schadenskonto 14",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_14.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-14" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-14",
    "group" : "gelb",
    "name" : "Schadenskonto 14",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_14.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-15" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-15",
    "group" : "weiß",
    "name" : "Schadenskonto 15",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_15.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-15" : {
    "id" : "jonas-koeritz:rot:schadenskonto-15",
    "group" : "rot",
    "name" : "Schadenskonto 15",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_15.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-15" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-15",
    "group" : "gelb",
    "name" : "Schadenskonto 15",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_15.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-16" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-16",
    "group" : "weiß",
    "name" : "Schadenskonto 16",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_16.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-16" : {
    "id" : "jonas-koeritz:rot:schadenskonto-16",
    "group" : "rot",
    "name" : "Schadenskonto 16",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_16.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-16" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-16",
    "group" : "gelb",
    "name" : "Schadenskonto 16",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_16.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-17" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-17",
    "group" : "weiß",
    "name" : "Schadenskonto 17",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_17.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-17" : {
    "id" : "jonas-koeritz:rot:schadenskonto-17",
    "group" : "rot",
    "name" : "Schadenskonto 17",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_17.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-17" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-17",
    "group" : "gelb",
    "name" : "Schadenskonto 17",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_17.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-18" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-18",
    "group" : "weiß",
    "name" : "Schadenskonto 18",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_18.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-18" : {
    "id" : "jonas-koeritz:rot:schadenskonto-18",
    "group" : "rot",
    "name" : "Schadenskonto 18",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_18.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-18" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-18",
    "group" : "gelb",
    "name" : "Schadenskonto 18",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_18.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-19" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-19",
    "group" : "weiß",
    "name" : "Schadenskonto 19",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_19.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-19" : {
    "id" : "jonas-koeritz:rot:schadenskonto-19",
    "group" : "rot",
    "name" : "Schadenskonto 19",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_19.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-19" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-19",
    "group" : "gelb",
    "name" : "Schadenskonto 19",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_19.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-2" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-2",
    "group" : "weiß",
    "name" : "Schadenskonto 2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-2" : {
    "id" : "jonas-koeritz:rot:schadenskonto-2",
    "group" : "rot",
    "name" : "Schadenskonto 2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-2" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-2",
    "group" : "gelb",
    "name" : "Schadenskonto 2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-20" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-20",
    "group" : "weiß",
    "name" : "Schadenskonto 20",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_20.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-20" : {
    "id" : "jonas-koeritz:rot:schadenskonto-20",
    "group" : "rot",
    "name" : "Schadenskonto 20",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_20.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-20" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-20",
    "group" : "gelb",
    "name" : "Schadenskonto 20",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_20.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-3" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-3",
    "group" : "weiß",
    "name" : "Schadenskonto 3",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_3.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-3" : {
    "id" : "jonas-koeritz:rot:schadenskonto-3",
    "group" : "rot",
    "name" : "Schadenskonto 3",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_3.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-3" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-3",
    "group" : "gelb",
    "name" : "Schadenskonto 3",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_3.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-4" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-4",
    "group" : "weiß",
    "name" : "Schadenskonto 4",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_4.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-4" : {
    "id" : "jonas-koeritz:rot:schadenskonto-4",
    "group" : "rot",
    "name" : "Schadenskonto 4",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_4.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-4" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-4",
    "group" : "gelb",
    "name" : "Schadenskonto 4",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_4.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-5" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-5",
    "group" : "weiß",
    "name" : "Schadenskonto 5",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_5.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-5" : {
    "id" : "jonas-koeritz:rot:schadenskonto-5",
    "group" : "rot",
    "name" : "Schadenskonto 5",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_5.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-5" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-5",
    "group" : "gelb",
    "name" : "Schadenskonto 5",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_5.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-6" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-6",
    "group" : "weiß",
    "name" : "Schadenskonto 6",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_6.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-6" : {
    "id" : "jonas-koeritz:rot:schadenskonto-6",
    "group" : "rot",
    "name" : "Schadenskonto 6",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_6.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-6" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-6",
    "group" : "gelb",
    "name" : "Schadenskonto 6",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_6.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-7" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-7",
    "group" : "weiß",
    "name" : "Schadenskonto 7",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_7.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-7" : {
    "id" : "jonas-koeritz:rot:schadenskonto-7",
    "group" : "rot",
    "name" : "Schadenskonto 7",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_7.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-7" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-7",
    "group" : "gelb",
    "name" : "Schadenskonto 7",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_7.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-8" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-8",
    "group" : "weiß",
    "name" : "Schadenskonto 8",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_8.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-8" : {
    "id" : "jonas-koeritz:rot:schadenskonto-8",
    "group" : "rot",
    "name" : "Schadenskonto 8",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_8.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-8" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-8",
    "group" : "gelb",
    "name" : "Schadenskonto 8",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_8.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenskonto-9" : {
    "id" : "jonas-koeritz:wei-:schadenskonto-9",
    "group" : "weiß",
    "name" : "Schadenskonto 9",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenskonto_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenskonto_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenskonto_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenskonto_9.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenskonto-9" : {
    "id" : "jonas-koeritz:rot:schadenskonto-9",
    "group" : "rot",
    "name" : "Schadenskonto 9",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenskonto_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenskonto_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenskonto_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenskonto_9.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenskonto-9" : {
    "id" : "jonas-koeritz:gelb:schadenskonto-9",
    "group" : "gelb",
    "name" : "Schadenskonto 9",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenskonto_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenskonto_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenskonto_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenskonto_9.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle" : {
    "id" : "jonas-koeritz:wei-:schadenstelle",
    "group" : "weiß",
    "name" : "Schadenstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle" : {
    "id" : "jonas-koeritz:rot:schadenstelle",
    "group" : "rot",
    "name" : "Schadenstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle" : {
    "id" : "jonas-koeritz:gelb:schadenstelle",
    "group" : "gelb",
    "name" : "Schadenstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-1" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-1",
    "group" : "weiß",
    "name" : "Schadenstelle 1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-1" : {
    "id" : "jonas-koeritz:rot:schadenstelle-1",
    "group" : "rot",
    "name" : "Schadenstelle 1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-1" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-1",
    "group" : "gelb",
    "name" : "Schadenstelle 1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-10" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-10",
    "group" : "weiß",
    "name" : "Schadenstelle 10",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_10.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-10" : {
    "id" : "jonas-koeritz:rot:schadenstelle-10",
    "group" : "rot",
    "name" : "Schadenstelle 10",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_10.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-10" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-10",
    "group" : "gelb",
    "name" : "Schadenstelle 10",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_10.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_10.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-11" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-11",
    "group" : "weiß",
    "name" : "Schadenstelle 11",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_11.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-11" : {
    "id" : "jonas-koeritz:rot:schadenstelle-11",
    "group" : "rot",
    "name" : "Schadenstelle 11",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_11.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-11" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-11",
    "group" : "gelb",
    "name" : "Schadenstelle 11",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_11.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_11.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-12" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-12",
    "group" : "weiß",
    "name" : "Schadenstelle 12",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_12.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-12" : {
    "id" : "jonas-koeritz:rot:schadenstelle-12",
    "group" : "rot",
    "name" : "Schadenstelle 12",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_12.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-12" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-12",
    "group" : "gelb",
    "name" : "Schadenstelle 12",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_12.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_12.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-13" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-13",
    "group" : "weiß",
    "name" : "Schadenstelle 13",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_13.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-13" : {
    "id" : "jonas-koeritz:rot:schadenstelle-13",
    "group" : "rot",
    "name" : "Schadenstelle 13",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_13.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-13" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-13",
    "group" : "gelb",
    "name" : "Schadenstelle 13",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_13.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_13.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-14" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-14",
    "group" : "weiß",
    "name" : "Schadenstelle 14",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_14.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-14" : {
    "id" : "jonas-koeritz:rot:schadenstelle-14",
    "group" : "rot",
    "name" : "Schadenstelle 14",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_14.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-14" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-14",
    "group" : "gelb",
    "name" : "Schadenstelle 14",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_14.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_14.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-15" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-15",
    "group" : "weiß",
    "name" : "Schadenstelle 15",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_15.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-15" : {
    "id" : "jonas-koeritz:rot:schadenstelle-15",
    "group" : "rot",
    "name" : "Schadenstelle 15",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_15.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-15" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-15",
    "group" : "gelb",
    "name" : "Schadenstelle 15",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_15.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_15.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-16" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-16",
    "group" : "weiß",
    "name" : "Schadenstelle 16",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_16.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-16" : {
    "id" : "jonas-koeritz:rot:schadenstelle-16",
    "group" : "rot",
    "name" : "Schadenstelle 16",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_16.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-16" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-16",
    "group" : "gelb",
    "name" : "Schadenstelle 16",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_16.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_16.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-17" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-17",
    "group" : "weiß",
    "name" : "Schadenstelle 17",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_17.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-17" : {
    "id" : "jonas-koeritz:rot:schadenstelle-17",
    "group" : "rot",
    "name" : "Schadenstelle 17",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_17.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-17" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-17",
    "group" : "gelb",
    "name" : "Schadenstelle 17",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_17.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_17.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-18" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-18",
    "group" : "weiß",
    "name" : "Schadenstelle 18",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_18.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-18" : {
    "id" : "jonas-koeritz:rot:schadenstelle-18",
    "group" : "rot",
    "name" : "Schadenstelle 18",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_18.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-18" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-18",
    "group" : "gelb",
    "name" : "Schadenstelle 18",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_18.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_18.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-19" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-19",
    "group" : "weiß",
    "name" : "Schadenstelle 19",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_19.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-19" : {
    "id" : "jonas-koeritz:rot:schadenstelle-19",
    "group" : "rot",
    "name" : "Schadenstelle 19",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_19.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-19" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-19",
    "group" : "gelb",
    "name" : "Schadenstelle 19",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_19.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_19.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-2" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-2",
    "group" : "weiß",
    "name" : "Schadenstelle 2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-2" : {
    "id" : "jonas-koeritz:rot:schadenstelle-2",
    "group" : "rot",
    "name" : "Schadenstelle 2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-2" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-2",
    "group" : "gelb",
    "name" : "Schadenstelle 2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-20" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-20",
    "group" : "weiß",
    "name" : "Schadenstelle 20",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_20.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-20" : {
    "id" : "jonas-koeritz:rot:schadenstelle-20",
    "group" : "rot",
    "name" : "Schadenstelle 20",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_20.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-20" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-20",
    "group" : "gelb",
    "name" : "Schadenstelle 20",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_20.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_20.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-3" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-3",
    "group" : "weiß",
    "name" : "Schadenstelle 3",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_3.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-3" : {
    "id" : "jonas-koeritz:rot:schadenstelle-3",
    "group" : "rot",
    "name" : "Schadenstelle 3",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_3.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-3" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-3",
    "group" : "gelb",
    "name" : "Schadenstelle 3",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_3.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_3.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-4" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-4",
    "group" : "weiß",
    "name" : "Schadenstelle 4",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_4.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-4" : {
    "id" : "jonas-koeritz:rot:schadenstelle-4",
    "group" : "rot",
    "name" : "Schadenstelle 4",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_4.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-4" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-4",
    "group" : "gelb",
    "name" : "Schadenstelle 4",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_4.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_4.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-5" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-5",
    "group" : "weiß",
    "name" : "Schadenstelle 5",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_5.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-5" : {
    "id" : "jonas-koeritz:rot:schadenstelle-5",
    "group" : "rot",
    "name" : "Schadenstelle 5",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_5.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-5" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-5",
    "group" : "gelb",
    "name" : "Schadenstelle 5",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_5.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_5.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-6" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-6",
    "group" : "weiß",
    "name" : "Schadenstelle 6",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_6.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-6" : {
    "id" : "jonas-koeritz:rot:schadenstelle-6",
    "group" : "rot",
    "name" : "Schadenstelle 6",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_6.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-6" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-6",
    "group" : "gelb",
    "name" : "Schadenstelle 6",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_6.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_6.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-7" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-7",
    "group" : "weiß",
    "name" : "Schadenstelle 7",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_7.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-7" : {
    "id" : "jonas-koeritz:rot:schadenstelle-7",
    "group" : "rot",
    "name" : "Schadenstelle 7",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_7.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-7" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-7",
    "group" : "gelb",
    "name" : "Schadenstelle 7",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_7.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_7.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-8" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-8",
    "group" : "weiß",
    "name" : "Schadenstelle 8",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_8.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-8" : {
    "id" : "jonas-koeritz:rot:schadenstelle-8",
    "group" : "rot",
    "name" : "Schadenstelle 8",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_8.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-8" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-8",
    "group" : "gelb",
    "name" : "Schadenstelle 8",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_8.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_8.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wei-:schadenstelle-9" : {
    "id" : "jonas-koeritz:wei-:schadenstelle-9",
    "group" : "weiß",
    "name" : "Schadenstelle 9",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/weiß/Schadenstelle_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/weiß/Schadenstelle_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/weiß/Schadenstelle_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/weiß/Schadenstelle_9.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rot:schadenstelle-9" : {
    "id" : "jonas-koeritz:rot:schadenstelle-9",
    "group" : "rot",
    "name" : "Schadenstelle 9",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/rot/Schadenstelle_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/rot/Schadenstelle_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/rot/Schadenstelle_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/rot/Schadenstelle_9.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gelb:schadenstelle-9" : {
    "id" : "jonas-koeritz:gelb:schadenstelle-9",
    "group" : "gelb",
    "name" : "Schadenstelle 9",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schadenskonten/gelb/Schadenstelle_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schadenskonten/gelb/Schadenstelle_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schadenskonten/gelb/Schadenstelle_9.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schadenskonten/gelb/Schadenstelle_9.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:schichtung" : {
    "id" : "jonas-koeritz:schaden:schichtung",
    "group" : "Schäden",
    "name" : "Schichtung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Schichtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Schichtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Schichtung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Schichtung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:schienenfahrzeug" : {
    "id" : "jonas-koeritz:fahrzeuge:schienenfahrzeug",
    "group" : "Fahrzeuge",
    "name" : "Schienenfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Schienenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Schienenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Schienenfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Schienenfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:katastrophenschutz-einheiten:schlacht-und-untersuchungsgruppe" : {
    "id" : "jonas-koeritz:katastrophenschutz-einheiten:schlacht-und-untersuchungsgruppe",
    "group" : "Katastrophenschutz Einheiten",
    "name" : "Schlacht und Untersuchungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Katastrophenschutz_Einheiten/Schlacht_und_Untersuchungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Katastrophenschutz_Einheiten/Schlacht_und_Untersuchungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Katastrophenschutz_Einheiten/Schlacht_und_Untersuchungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Katastrophenschutz_Einheiten/Schlacht_und_Untersuchungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:schlauchboot" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:schlauchboot",
    "group" : "THW Fahrzeuge",
    "name" : "Schlauchboot",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Schlauchboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Schlauchboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Schlauchboot.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Schlauchboot.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:schlauchtruppfuhrer" : {
    "id" : "jonas-koeritz:feuerwehr-personen:schlauchtruppfuhrer",
    "group" : "Feuerwehr Personen",
    "name" : "Schlauchtruppführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Schlauchtruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Schlauchtruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Schlauchtruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Schlauchtruppführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:schlauchtruppmann" : {
    "id" : "jonas-koeritz:feuerwehr-personen:schlauchtruppmann",
    "group" : "Feuerwehr Personen",
    "name" : "Schlauchtruppmann",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Schlauchtruppmann.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Schlauchtruppmann.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Schlauchtruppmann.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Schlauchtruppmann.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:schlauchwagen" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:schlauchwagen",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Schlauchwagen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Schlauchwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Schlauchwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Schlauchwagen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Schlauchwagen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:schnell-einsatz-gruppe-betreuung" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:schnell-einsatz-gruppe-betreuung",
    "group" : "Rettungswesen Einheiten",
    "name" : "Schnell-Einsatz-Gruppe Betreuung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Schnell-Einsatz-Gruppe_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Schnell-Einsatz-Gruppe_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Schnell-Einsatz-Gruppe_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Schnell-Einsatz-Gruppe_Betreuung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:server" : {
    "id" : "jonas-koeritz:fernmeldewesen:server",
    "group" : "Fernmeldewesen",
    "name" : "Server",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Server.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Server.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Server.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Server.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:sprechfunkbetriebstrupp" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:sprechfunkbetriebstrupp",
    "group" : "Feuerwehr Einheiten",
    "name" : "Sprechfunkbetriebstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Sprechfunkbetriebstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Sprechfunkbetriebstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Sprechfunkbetriebstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Sprechfunkbetriebstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:ma-nahmen:sprengen" : {
    "id" : "jonas-koeritz:ma-nahmen:sprengen",
    "group" : "Maßnahmen",
    "name" : "Sprengen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Maßnahmen/Sprengen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Maßnahmen/Sprengen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Maßnahmen/Sprengen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Maßnahmen/Sprengen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:stab" : {
    "id" : "jonas-koeritz:thw-einheiten:stab",
    "group" : "THW Einheiten",
    "name" : "Stab",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Stab.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Stab.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Stab.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Stab.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-ab" : {
    "id" : "jonas-koeritz:thw-personen:stab-ab",
    "group" : "THW Personen",
    "name" : "Stab AB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_AB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_AB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_AB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_AB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-boh" : {
    "id" : "jonas-koeritz:thw-personen:stab-boh",
    "group" : "THW Personen",
    "name" : "Stab BÖH",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_BÖH.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_BÖH.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_BÖH.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_BÖH.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-ko" : {
    "id" : "jonas-koeritz:thw-personen:stab-ko",
    "group" : "THW Personen",
    "name" : "Stab Ko",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_Ko.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_Ko.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_Ko.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_Ko.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-ob" : {
    "id" : "jonas-koeritz:thw-personen:stab-ob",
    "group" : "THW Personen",
    "name" : "Stab OB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_OB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-ojb" : {
    "id" : "jonas-koeritz:thw-personen:stab-ojb",
    "group" : "THW Personen",
    "name" : "Stab OJB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_OJB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_OJB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_OJB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_OJB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-s1" : {
    "id" : "jonas-koeritz:thw-personen:stab-s1",
    "group" : "THW Personen",
    "name" : "Stab S1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_S1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_S1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_S1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_S1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-s2" : {
    "id" : "jonas-koeritz:thw-personen:stab-s2",
    "group" : "THW Personen",
    "name" : "Stab S2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_S2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_S2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_S2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_S2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-s3" : {
    "id" : "jonas-koeritz:thw-personen:stab-s3",
    "group" : "THW Personen",
    "name" : "Stab S3",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_S3.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_S3.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_S3.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_S3.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-s4" : {
    "id" : "jonas-koeritz:thw-personen:stab-s4",
    "group" : "THW Personen",
    "name" : "Stab S4",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_S4.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_S4.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_S4.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_S4.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-s5" : {
    "id" : "jonas-koeritz:thw-personen:stab-s5",
    "group" : "THW Personen",
    "name" : "Stab S5",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_S5.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_S5.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_S5.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_S5.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-s6" : {
    "id" : "jonas-koeritz:thw-personen:stab-s6",
    "group" : "THW Personen",
    "name" : "Stab S6",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_S6.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_S6.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_S6.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_S6.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-sm" : {
    "id" : "jonas-koeritz:thw-personen:stab-sm",
    "group" : "THW Personen",
    "name" : "Stab SM",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_SM.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_SM.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_SM.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_SM.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-stv-ob" : {
    "id" : "jonas-koeritz:thw-personen:stab-stv-ob",
    "group" : "THW Personen",
    "name" : "Stab Stv OB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_Stv_OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_Stv_OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_Stv_OB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_Stv_OB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:stab-vw" : {
    "id" : "jonas-koeritz:thw-personen:stab-vw",
    "group" : "THW Personen",
    "name" : "Stab Vw",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Stab_Vw.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Stab_Vw.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Stab_Vw.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Stab_Vw.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:staffel" : {
    "id" : "jonas-koeritz:thw-einheiten:staffel",
    "group" : "THW Einheiten",
    "name" : "Staffel",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Staffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Staffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Staffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Staffel.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einheiten:staffel" : {
    "id" : "jonas-koeritz:einheiten:staffel",
    "group" : "Einheiten",
    "name" : "Staffel",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einheiten/Staffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einheiten/Staffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einheiten/Staffel.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einheiten/Staffel.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:staffelfuhrer" : {
    "id" : "jonas-koeritz:feuerwehr-personen:staffelfuhrer",
    "group" : "Feuerwehr Personen",
    "name" : "Staffelführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Staffelführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Staffelführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Staffelführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Staffelführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:bundeswehr-personen:staffelfuhrer" : {
    "id" : "jonas-koeritz:bundeswehr-personen:staffelfuhrer",
    "group" : "Bundeswehr Personen",
    "name" : "Staffelführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Bundeswehr_Personen/Staffelführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Bundeswehr_Personen/Staffelführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Bundeswehr_Personen/Staffelführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Bundeswehr_Personen/Staffelführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:stapler" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:stapler",
    "group" : "THW Fahrzeuge",
    "name" : "Stapler",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Stapler.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Stapler.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Stapler.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Stapler.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:stapler-2t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:stapler-2t",
    "group" : "THW Fahrzeuge",
    "name" : "Stapler 2t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Stapler_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Stapler_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Stapler_2t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Stapler_2t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:stapler-3t" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:stapler-3t",
    "group" : "THW Fahrzeuge",
    "name" : "Stapler 3t",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Stapler_3t.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Stapler_3t.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Stapler_3t.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Stapler_3t.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:stark-erhohte-aktivitat" : {
    "id" : "jonas-koeritz:sonstiges:stark-erhohte-aktivitat",
    "group" : "Sonstiges",
    "name" : "Stark erhöhte Aktivität",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Stark_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Stark_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Stark_erhöhte_Aktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Stark_erhöhte_Aktivität.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:stelle" : {
    "id" : "jonas-koeritz:einrichtungen:stelle",
    "group" : "Einrichtungen",
    "name" : "Stelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Stelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Stelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Stelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Stelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:stelle-betrieb" : {
    "id" : "jonas-koeritz:einrichtungen:stelle-betrieb",
    "group" : "Einrichtungen",
    "name" : "Stelle Betrieb",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Stelle_Betrieb.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Stelle_Betrieb.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Stelle_Betrieb.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Stelle_Betrieb.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:stelle-infrastruktur" : {
    "id" : "jonas-koeritz:einrichtungen:stelle-infrastruktur",
    "group" : "Einrichtungen",
    "name" : "Stelle Infrastruktur",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Stelle_Infrastruktur.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Stelle_Infrastruktur.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Stelle_Infrastruktur.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Stelle_Infrastruktur.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:stromungsrettungstrupp" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:stromungsrettungstrupp",
    "group" : "Wasserrettung Einheiten",
    "name" : "Strömungsrettungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Strömungsrettungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Strömungsrettungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Strömungsrettungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Strömungsrettungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:stroumgsrettungsgruppe" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:stroumgsrettungsgruppe",
    "group" : "Wasserrettung Einheiten",
    "name" : "Ströumgsrettungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Ströumgsrettungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Ströumgsrettungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Ströumgsrettungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Ströumgsrettungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:switch" : {
    "id" : "jonas-koeritz:fernmeldewesen:switch",
    "group" : "Fernmeldewesen",
    "name" : "Switch",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Switch.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Switch.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Switch.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Switch.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:sysbr" : {
    "id" : "jonas-koeritz:thw-einheiten:sysbr",
    "group" : "THW Einheiten",
    "name" : "SysBR",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/SysBR.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/SysBR.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/SysBR.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/SysBR.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:tel" : {
    "id" : "jonas-koeritz:fuhrungsstellen:tel",
    "group" : "Führungsstellen",
    "name" : "TEL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/TEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/TEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/TEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/TEL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:thv" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:thv",
    "group" : "THW Fahrzeuge",
    "name" : "THV",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/THV.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/THV.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/THV.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/THV.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:thv-bereitschaft" : {
    "id" : "jonas-koeritz:thw-einheiten:thv-bereitschaft",
    "group" : "THW Einheiten",
    "name" : "THV Bereitschaft",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/THV_Bereitschaft.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/THV_Bereitschaft.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/THV_Bereitschaft.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/THV_Bereitschaft.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:tlf-16-25" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:tlf-16-25",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "TLF 16-25",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/TLF_16-25.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/TLF_16-25.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/TLF_16-25.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/TLF_16-25.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:tlf-2000" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:tlf-2000",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "TLF 2000",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/TLF_2000.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/TLF_2000.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/TLF_2000.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/TLF_2000.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:tlf-3000" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:tlf-3000",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "TLF 3000",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/TLF_3000.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/TLF_3000.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/TLF_3000.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/TLF_3000.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:tlf-4000" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:tlf-4000",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "TLF 4000",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/TLF_4000.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/TLF_4000.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/TLF_4000.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/TLF_4000.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-fahrzeuge:tankloschfahrzeug" : {
    "id" : "jonas-koeritz:feuerwehr-fahrzeuge:tankloschfahrzeug",
    "group" : "Feuerwehr Fahrzeuge",
    "name" : "Tanklöschfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Fahrzeuge/Tanklöschfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Fahrzeuge/Tanklöschfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Fahrzeuge/Tanklöschfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Fahrzeuge/Tanklöschfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:tauchgruppe" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:tauchgruppe",
    "group" : "Wasserrettung Einheiten",
    "name" : "Tauchgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Tauchgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Tauchgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Tauchgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Tauchgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:tauchtrupp" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:tauchtrupp",
    "group" : "Wasserrettung Einheiten",
    "name" : "Tauchtrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Tauchtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Tauchtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Tauchtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Tauchtrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-bruckenbau" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-bruckenbau",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Brückenbau",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Brückenbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Brückenbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Brückenbau.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Brückenbau.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-elektroversorgung" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-elektroversorgung",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Elektroversorgung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Elektroversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Elektroversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Elektroversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Elektroversorgung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-infrastruktur" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-infrastruktur",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Infrastruktur",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Infrastruktur.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Infrastruktur.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Infrastruktur.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Infrastruktur.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-ortung" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-ortung",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Ortung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-ortung-a" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-ortung-a",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Ortung A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-ortung-b" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-ortung-b",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Ortung B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-ortung-c" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-ortung-c",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Ortung C",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Ortung_C.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-raumen" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-raumen",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Räumen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-raumen-a" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-raumen-a",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Räumen A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-raumen-b" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-raumen-b",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Räumen B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-raumen-c" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-raumen-c",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Räumen C",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Räumen_C.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-sprengen" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-sprengen",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Sprengen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Sprengen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Sprengen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Sprengen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Sprengen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-trinkwasser" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-trinkwasser",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Trinkwasser",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Trinkwasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Trinkwasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Trinkwasser.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Trinkwasser.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wassergefahren" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wassergefahren",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Wassergefahren",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wassergefahren-a" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wassergefahren-a",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Wassergefahren A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wassergefahren-b" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wassergefahren-b",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Wassergefahren B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Wassergefahren_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wasserschaden-pumpen" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wasserschaden-pumpen",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Wasserschaden Pumpen",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wasserschaden-pumpen-a" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wasserschaden-pumpen-a",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Wasserschaden Pumpen A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wasserschaden-pumpen-b" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wasserschaden-pumpen-b",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Wasserschaden Pumpen B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wasserschaden-pumpen-c" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-wasserschaden-pumpen-c",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Wasserschaden Pumpen C",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Wasserschaden_Pumpen_C.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-olschaden" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-olschaden",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Ölschaden",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-olschaden-a" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-olschaden-a",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Ölschaden A",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_A.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_A.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-olschaden-b" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-olschaden-b",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Ölschaden B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-olschaden-c" : {
    "id" : "jonas-koeritz:thw-einheiten:technischer-zug-mit-fgr-olschaden-c",
    "group" : "THW Einheiten",
    "name" : "Technischer Zug mit FGr Ölschaden C",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_C.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Technischer_Zug_mit_FGr_Ölschaden_C.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:teilblockiert" : {
    "id" : "jonas-koeritz:schaden:teilblockiert",
    "group" : "Schäden",
    "name" : "Teilblockiert",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Teilblockiert.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Teilblockiert.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Teilblockiert.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Teilblockiert.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:teilzerstort" : {
    "id" : "jonas-koeritz:schaden:teilzerstort",
    "group" : "Schäden",
    "name" : "Teilzerstört",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Teilzerstört.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Teilzerstört.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Teilzerstört.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Teilzerstört.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:telelader" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:telelader",
    "group" : "THW Fahrzeuge",
    "name" : "Telelader",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Telelader.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Telelader.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Telelader.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Telelader.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:tendenz-fallend" : {
    "id" : "jonas-koeritz:sonstiges:tendenz-fallend",
    "group" : "Sonstiges",
    "name" : "Tendenz fallend",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Tendenz_fallend.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Tendenz_fallend.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Tendenz_fallend.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Tendenz_fallend.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:tendenz-steigend" : {
    "id" : "jonas-koeritz:sonstiges:tendenz-steigend",
    "group" : "Sonstiges",
    "name" : "Tendenz steigend",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Tendenz_steigend.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Tendenz_steigend.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Tendenz_steigend.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Tendenz_steigend.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:tendenz-unverandert" : {
    "id" : "jonas-koeritz:sonstiges:tendenz-unverandert",
    "group" : "Sonstiges",
    "name" : "Tendenz unverändert",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Tendenz_unverändert.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Tendenz_unverändert.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Tendenz_unverändert.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Tendenz_unverändert.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:tier" : {
    "id" : "jonas-koeritz:sonstiges:tier",
    "group" : "Sonstiges",
    "name" : "Tier",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Tier.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Tier.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Tier.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Tier.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:katastrophenschutz-einheiten:tier-dekon-und-transportgruppe" : {
    "id" : "jonas-koeritz:katastrophenschutz-einheiten:tier-dekon-und-transportgruppe",
    "group" : "Katastrophenschutz Einheiten",
    "name" : "Tier Dekon und Transportgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Katastrophenschutz_Einheiten/Tier_Dekon_und_Transportgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Katastrophenschutz_Einheiten/Tier_Dekon_und_Transportgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Katastrophenschutz_Einheiten/Tier_Dekon_und_Transportgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Katastrophenschutz_Einheiten/Tier_Dekon_und_Transportgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:tote-person" : {
    "id" : "jonas-koeritz:personen:tote-person",
    "group" : "Personen",
    "name" : "Tote Person",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Tote_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Tote_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Tote_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Tote_Person.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:totes-tier" : {
    "id" : "jonas-koeritz:sonstiges:totes-tier",
    "group" : "Sonstiges",
    "name" : "Totes Tier",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Totes_Tier.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Totes_Tier.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Totes_Tier.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Totes_Tier.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:ma-nahmen:transportieren" : {
    "id" : "jonas-koeritz:ma-nahmen:transportieren",
    "group" : "Maßnahmen",
    "name" : "Transportieren",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Maßnahmen/Transportieren.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Maßnahmen/Transportieren.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Maßnahmen/Transportieren.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Maßnahmen/Transportieren.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:transportierte-person" : {
    "id" : "jonas-koeritz:personen:transportierte-person",
    "group" : "Personen",
    "name" : "Transportierte Person",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Transportierte_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Transportierte_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Transportierte_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Transportierte_Person.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:trinkwasserversorgung" : {
    "id" : "jonas-koeritz:einrichtungen:trinkwasserversorgung",
    "group" : "Einrichtungen",
    "name" : "Trinkwasserversorgung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Trinkwasserversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Trinkwasserversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Trinkwasserversorgung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Trinkwasserversorgung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:trupp" : {
    "id" : "jonas-koeritz:thw-einheiten:trupp",
    "group" : "THW Einheiten",
    "name" : "Trupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Trupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Trupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Trupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Trupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einheiten:trupp" : {
    "id" : "jonas-koeritz:einheiten:trupp",
    "group" : "Einheiten",
    "name" : "Trupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einheiten/Trupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einheiten/Trupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einheiten/Trupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einheiten/Trupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:trupp-ess" : {
    "id" : "jonas-koeritz:thw-einheiten:trupp-ess",
    "group" : "THW Einheiten",
    "name" : "Trupp ESS",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Trupp_ESS.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Trupp_ESS.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Trupp_ESS.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Trupp_ESS.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:trupp-mhp" : {
    "id" : "jonas-koeritz:thw-einheiten:trupp-mhp",
    "group" : "THW Einheiten",
    "name" : "Trupp MHP",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Trupp_MHP.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Trupp_MHP.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Trupp_MHP.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Trupp_MHP.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:trupp-ts" : {
    "id" : "jonas-koeritz:thw-einheiten:trupp-ts",
    "group" : "THW Einheiten",
    "name" : "Trupp TS",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Trupp_TS.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Trupp_TS.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Trupp_TS.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Trupp_TS.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:trupp-technik-und-sicherheit" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:trupp-technik-und-sicherheit",
    "group" : "Rettungswesen Einheiten",
    "name" : "Trupp Technik und Sicherheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Trupp_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Trupp_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Trupp_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Trupp_Technik_und_Sicherheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:trupp-ul" : {
    "id" : "jonas-koeritz:thw-einheiten:trupp-ul",
    "group" : "THW Einheiten",
    "name" : "Trupp UL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Trupp_UL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Trupp_UL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Trupp_UL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Trupp_UL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:trupp-fur-soziale-betreuung" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:trupp-fur-soziale-betreuung",
    "group" : "Rettungswesen Einheiten",
    "name" : "Trupp für soziale Betreuung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Trupp_für_soziale_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Trupp_für_soziale_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Trupp_für_soziale_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Trupp_für_soziale_Betreuung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:truppfuhrer" : {
    "id" : "jonas-koeritz:personen:truppfuhrer",
    "group" : "Personen",
    "name" : "Truppführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Truppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Truppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Truppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Truppführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:bundeswehr-personen:truppfuhrer" : {
    "id" : "jonas-koeritz:bundeswehr-personen:truppfuhrer",
    "group" : "Bundeswehr Personen",
    "name" : "Truppführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Bundeswehr_Personen/Truppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Bundeswehr_Personen/Truppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Bundeswehr_Personen/Truppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Bundeswehr_Personen/Truppführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-b" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-b",
    "group" : "THW Personen",
    "name" : "Truppführer B",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_B.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_B.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-b-1" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-b-1",
    "group" : "THW Personen",
    "name" : "Truppführer B 1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_B_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_B_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_B_1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_B_1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-b-2" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-b-2",
    "group" : "THW Personen",
    "name" : "Truppführer B 2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_B_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_B_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_B_2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_B_2.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:truppfuhrer-betreuungstrupp" : {
    "id" : "jonas-koeritz:rettungswesen-personen:truppfuhrer-betreuungstrupp",
    "group" : "Rettungswesen Personen",
    "name" : "Truppführer Betreuungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Truppführer_Betreuungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Truppführer_Betreuungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Truppführer_Betreuungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Truppführer_Betreuungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-brb" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-brb",
    "group" : "THW Personen",
    "name" : "Truppführer BrB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_BrB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_BrB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_BrB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_BrB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-e" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-e",
    "group" : "THW Personen",
    "name" : "Truppführer E",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_E.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_E.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_E.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_E.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-ess" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-ess",
    "group" : "THW Personen",
    "name" : "Truppführer ESS",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_ESS.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_ESS.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_ESS.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_ESS.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-f" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-f",
    "group" : "THW Personen",
    "name" : "Truppführer F",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_F.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_F.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_F.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_F.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-fm" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-fm",
    "group" : "THW Personen",
    "name" : "Truppführer Fm",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_Fm.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_Fm.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_Fm.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_Fm.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-fugeh" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-fugeh",
    "group" : "THW Personen",
    "name" : "Truppführer FüGeh",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_FüGeh.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_FüGeh.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_FüGeh.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_FüGeh.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-i" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-i",
    "group" : "THW Personen",
    "name" : "Truppführer I",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_I.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-k" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-k",
    "group" : "THW Personen",
    "name" : "Truppführer K",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_K.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_K.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_K.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_K.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-ldf" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-ldf",
    "group" : "THW Personen",
    "name" : "Truppführer LdF",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_LdF.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_LdF.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_LdF.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_LdF.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-logm" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-logm",
    "group" : "THW Personen",
    "name" : "Truppführer LogM",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_LogM.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_LogM.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_LogM.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_LogM.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-logv" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-logv",
    "group" : "THW Personen",
    "name" : "Truppführer LogV",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_LogV.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_LogV.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_LogV.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_LogV.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-mhp" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-mhp",
    "group" : "THW Personen",
    "name" : "Truppführer MHP",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_MHP.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_MHP.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_MHP.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_MHP.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-n" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-n",
    "group" : "THW Personen",
    "name" : "Truppführer N",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_N.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_N.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_N.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_N.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-o" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-o",
    "group" : "THW Personen",
    "name" : "Truppführer O",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_O.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_O.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_O.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_O.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-r" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-r",
    "group" : "THW Personen",
    "name" : "Truppführer R",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_R.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_R.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_R.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_R.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-sb" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-sb",
    "group" : "THW Personen",
    "name" : "Truppführer SB",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_SB.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_SB.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_SB.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_SB.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:truppfuhrer-sanitatstrupp" : {
    "id" : "jonas-koeritz:rettungswesen-personen:truppfuhrer-sanitatstrupp",
    "group" : "Rettungswesen Personen",
    "name" : "Truppführer Sanitätstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Truppführer_Sanitätstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Truppführer_Sanitätstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Truppführer_Sanitätstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Truppführer_Sanitätstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-sp" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-sp",
    "group" : "THW Personen",
    "name" : "Truppführer Sp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_Sp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_Sp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_Sp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_Sp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-ts" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-ts",
    "group" : "THW Personen",
    "name" : "Truppführer TS",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_TS.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_TS.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_TS.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_TS.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-tw" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-tw",
    "group" : "THW Personen",
    "name" : "Truppführer TW",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_TW.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_TW.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_TW.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_TW.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:truppfuhrer-technik-und-sicherheit" : {
    "id" : "jonas-koeritz:rettungswesen-personen:truppfuhrer-technik-und-sicherheit",
    "group" : "Rettungswesen Personen",
    "name" : "Truppführer Technik und Sicherheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Truppführer_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Truppführer_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Truppführer_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Truppführer_Technik_und_Sicherheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-ul" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-ul",
    "group" : "THW Personen",
    "name" : "Truppführer UL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_UL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_UL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_UL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_UL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:truppfuhrer-verpflegung" : {
    "id" : "jonas-koeritz:rettungswesen-personen:truppfuhrer-verpflegung",
    "group" : "Rettungswesen Personen",
    "name" : "Truppführer Verpflegung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Truppführer_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Truppführer_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Truppführer_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Truppführer_Verpflegung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-w" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-w",
    "group" : "THW Personen",
    "name" : "Truppführer W",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_W.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_W.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_W.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_W.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-wp" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-wp",
    "group" : "THW Personen",
    "name" : "Truppführer WP",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_WP.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_WP.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_WP.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_WP.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:truppfuhrer-ol" : {
    "id" : "jonas-koeritz:thw-personen:truppfuhrer-ol",
    "group" : "THW Personen",
    "name" : "Truppführer Öl",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Truppführer_Öl.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Truppführer_Öl.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Truppführer_Öl.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Truppführer_Öl.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:uav-erkundungsgruppe" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:uav-erkundungsgruppe",
    "group" : "Feuerwehr Einheiten",
    "name" : "UAV Erkundungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/UAV_Erkundungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/UAV_Erkundungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/UAV_Erkundungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/UAV_Erkundungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:ueal" : {
    "id" : "jonas-koeritz:fuhrungsstellen:ueal",
    "group" : "Führungsstellen",
    "name" : "UEAL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/UEAL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/UEAL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/UEAL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/UEAL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:untereinsatzabschnittsleiter" : {
    "id" : "jonas-koeritz:personen:untereinsatzabschnittsleiter",
    "group" : "Personen",
    "name" : "Untereinsatzabschnittsleiter",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Untereinsatzabschnittsleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Untereinsatzabschnittsleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Untereinsatzabschnittsleiter.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Untereinsatzabschnittsleiter.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gebaude:unterkunft" : {
    "id" : "jonas-koeritz:gebaude:unterkunft",
    "group" : "Gebäude",
    "name" : "Unterkunft",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gebäude/Unterkunft.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gebäude/Unterkunft.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gebäude/Unterkunft.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gebäude/Unterkunft.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:untersuchungsstelle" : {
    "id" : "jonas-koeritz:fernmeldewesen:untersuchungsstelle",
    "group" : "Fernmeldewesen",
    "name" : "Untersuchungsstelle",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Untersuchungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Untersuchungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Untersuchungsstelle.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Untersuchungsstelle.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:vost" : {
    "id" : "jonas-koeritz:thw-einheiten:vost",
    "group" : "THW Einheiten",
    "name" : "VOST",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/VOST.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/VOST.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/VOST.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/VOST.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:verband-feldlager" : {
    "id" : "jonas-koeritz:thw-einheiten:verband-feldlager",
    "group" : "THW Einheiten",
    "name" : "Verband Feldlager",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Verband_Feldlager.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Verband_Feldlager.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Verband_Feldlager.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Verband_Feldlager.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:verband-logistik" : {
    "id" : "jonas-koeritz:thw-einheiten:verband-logistik",
    "group" : "THW Einheiten",
    "name" : "Verband Logistik",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Verband_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Verband_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Verband_Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Verband_Logistik.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:verbandfuhrer-i" : {
    "id" : "jonas-koeritz:personen:verbandfuhrer-i",
    "group" : "Personen",
    "name" : "Verbandführer I",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Verbandführer_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Verbandführer_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Verbandführer_I.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Verbandführer_I.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:verbandfuhrer-ii" : {
    "id" : "jonas-koeritz:personen:verbandfuhrer-ii",
    "group" : "Personen",
    "name" : "Verbandführer II",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Verbandführer_II.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Verbandführer_II.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Verbandführer_II.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Verbandführer_II.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:verbandfuhrer-iii" : {
    "id" : "jonas-koeritz:personen:verbandfuhrer-iii",
    "group" : "Personen",
    "name" : "Verbandführer III",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Verbandführer_III.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Verbandführer_III.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Verbandführer_III.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Verbandführer_III.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:verletzte-person" : {
    "id" : "jonas-koeritz:personen:verletzte-person",
    "group" : "Personen",
    "name" : "Verletzte Person",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Verletzte_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Verletzte_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Verletzte_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Verletzte_Person.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:sonstiges:verletztes-tier" : {
    "id" : "jonas-koeritz:sonstiges:verletztes-tier",
    "group" : "Sonstiges",
    "name" : "Verletztes Tier",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Sonstiges/Verletztes_Tier.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Sonstiges/Verletztes_Tier.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Sonstiges/Verletztes_Tier.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Sonstiges/Verletztes_Tier.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:vermisste-person" : {
    "id" : "jonas-koeritz:personen:vermisste-person",
    "group" : "Personen",
    "name" : "Vermisste Person",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Vermisste_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Vermisste_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Vermisste_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Vermisste_Person.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vermutete-gefahr" : {
    "id" : "jonas-koeritz:gefahren:vermutete-gefahr",
    "group" : "Gefahren",
    "name" : "Vermutete Gefahr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vermutete_Gefahr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vermutete_Gefahr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vermutete_Gefahr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vermutete_Gefahr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vermutete-gefahr-durch-explosion" : {
    "id" : "jonas-koeritz:gefahren:vermutete-gefahr-durch-explosion",
    "group" : "Gefahren",
    "name" : "Vermutete Gefahr durch Explosion",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vermutete_Gefahr_durch_Explosion.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vermutete_Gefahr_durch_Explosion.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vermutete_Gefahr_durch_Explosion.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vermutete_Gefahr_durch_Explosion.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vermutete-gefahr-durch-explosivstoffe" : {
    "id" : "jonas-koeritz:gefahren:vermutete-gefahr-durch-explosivstoffe",
    "group" : "Gefahren",
    "name" : "Vermutete Gefahr durch Explosivstoffe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vermutete_Gefahr_durch_Explosivstoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vermutete_Gefahr_durch_Explosivstoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vermutete_Gefahr_durch_Explosivstoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vermutete_Gefahr_durch_Explosivstoffe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vermutete-gefahr-durch-gas" : {
    "id" : "jonas-koeritz:gefahren:vermutete-gefahr-durch-gas",
    "group" : "Gefahren",
    "name" : "Vermutete Gefahr durch Gas",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vermutete_Gefahr_durch_Gas.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vermutete_Gefahr_durch_Gas.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vermutete_Gefahr_durch_Gas.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vermutete_Gefahr_durch_Gas.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vermutete-gefahr-durch-luftmangel" : {
    "id" : "jonas-koeritz:gefahren:vermutete-gefahr-durch-luftmangel",
    "group" : "Gefahren",
    "name" : "Vermutete Gefahr durch Luftmangel",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vermutete_Gefahr_durch_Luftmangel.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vermutete_Gefahr_durch_Luftmangel.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vermutete_Gefahr_durch_Luftmangel.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vermutete_Gefahr_durch_Luftmangel.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vermutete-gefahr-durch-mineralol" : {
    "id" : "jonas-koeritz:gefahren:vermutete-gefahr-durch-mineralol",
    "group" : "Gefahren",
    "name" : "Vermutete Gefahr durch Mineralöl",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vermutete_Gefahr_durch_Mineralöl.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vermutete_Gefahr_durch_Mineralöl.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vermutete_Gefahr_durch_Mineralöl.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vermutete_Gefahr_durch_Mineralöl.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vermutete-gefahr-durch-radioaktivitat" : {
    "id" : "jonas-koeritz:gefahren:vermutete-gefahr-durch-radioaktivitat",
    "group" : "Gefahren",
    "name" : "Vermutete Gefahr durch Radioaktivität",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vermutete_Gefahr_durch_Radioaktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vermutete_Gefahr_durch_Radioaktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vermutete_Gefahr_durch_Radioaktivität.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vermutete_Gefahr_durch_Radioaktivität.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vermutete-gefahr-durch-wassereinbruch" : {
    "id" : "jonas-koeritz:gefahren:vermutete-gefahr-durch-wassereinbruch",
    "group" : "Gefahren",
    "name" : "Vermutete Gefahr durch Wassereinbruch",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vermutete_Gefahr_durch_Wassereinbruch.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vermutete_Gefahr_durch_Wassereinbruch.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vermutete_Gefahr_durch_Wassereinbruch.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vermutete_Gefahr_durch_Wassereinbruch.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vermutete-gefahr-durch-elektrischen-strom" : {
    "id" : "jonas-koeritz:gefahren:vermutete-gefahr-durch-elektrischen-strom",
    "group" : "Gefahren",
    "name" : "Vermutete Gefahr durch elektrischen Strom",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vermutete_Gefahr_durch_elektrischen_Strom.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vermutete_Gefahr_durch_elektrischen_Strom.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vermutete_Gefahr_durch_elektrischen_Strom.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vermutete_Gefahr_durch_elektrischen_Strom.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vermutete-gefahr-durch-gefahrliche-stoffe" : {
    "id" : "jonas-koeritz:gefahren:vermutete-gefahr-durch-gefahrliche-stoffe",
    "group" : "Gefahren",
    "name" : "Vermutete Gefahr durch gefährliche Stoffe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vermutete_Gefahr_durch_gefährliche_Stoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vermutete_Gefahr_durch_gefährliche_Stoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vermutete_Gefahr_durch_gefährliche_Stoffe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vermutete_Gefahr_durch_gefährliche_Stoffe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:verpflegungsgruppe" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:verpflegungsgruppe",
    "group" : "Rettungswesen Einheiten",
    "name" : "Verpflegungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Verpflegungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Verpflegungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Verpflegungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Verpflegungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:verpflegungstrupp" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:verpflegungstrupp",
    "group" : "Rettungswesen Einheiten",
    "name" : "Verpflegungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Verpflegungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Verpflegungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Verpflegungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Verpflegungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:verpflegungszug" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:verpflegungszug",
    "group" : "Rettungswesen Einheiten",
    "name" : "Verpflegungszug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Verpflegungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Verpflegungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Verpflegungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Verpflegungszug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:verschuttete-person" : {
    "id" : "jonas-koeritz:personen:verschuttete-person",
    "group" : "Personen",
    "name" : "Verschüttete Person",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Verschüttete_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Verschüttete_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Verschüttete_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Verschüttete_Person.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:versorgungsstelle-materialerhaltung" : {
    "id" : "jonas-koeritz:einrichtungen:versorgungsstelle-materialerhaltung",
    "group" : "Einrichtungen",
    "name" : "Versorgungsstelle Materialerhaltung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Versorgungsstelle_Materialerhaltung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Versorgungsstelle_Materialerhaltung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Versorgungsstelle_Materialerhaltung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Versorgungsstelle_Materialerhaltung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:versorgungsstelle-verbrauchsguter" : {
    "id" : "jonas-koeritz:einrichtungen:versorgungsstelle-verbrauchsguter",
    "group" : "Einrichtungen",
    "name" : "Versorgungsstelle Verbrauchsgüter",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Versorgungsstelle_Verbrauchsgüter.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Versorgungsstelle_Verbrauchsgüter.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Versorgungsstelle_Verbrauchsgüter.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Versorgungsstelle_Verbrauchsgüter.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:versorgungsstelle-verpflegung" : {
    "id" : "jonas-koeritz:einrichtungen:versorgungsstelle-verpflegung",
    "group" : "Einrichtungen",
    "name" : "Versorgungsstelle Verpflegung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Versorgungsstelle_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Versorgungsstelle_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Versorgungsstelle_Verpflegung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Versorgungsstelle_Verpflegung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:versorgungstrupp-materialerhaltung" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:versorgungstrupp-materialerhaltung",
    "group" : "Feuerwehr Einheiten",
    "name" : "Versorgungstrupp Materialerhaltung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Versorgungstrupp_Materialerhaltung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Versorgungstrupp_Materialerhaltung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Versorgungstrupp_Materialerhaltung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Versorgungstrupp_Materialerhaltung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-einheiten:versorgungstrupp-verbrauchsguter" : {
    "id" : "jonas-koeritz:feuerwehr-einheiten:versorgungstrupp-verbrauchsguter",
    "group" : "Feuerwehr Einheiten",
    "name" : "Versorgungstrupp Verbrauchsgüter",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Einheiten/Versorgungstrupp_Verbrauchsgüter.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Einheiten/Versorgungstrupp_Verbrauchsgüter.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Einheiten/Versorgungstrupp_Verbrauchsgüter.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Einheiten/Versorgungstrupp_Verbrauchsgüter.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:verteiler" : {
    "id" : "jonas-koeritz:fernmeldewesen:verteiler",
    "group" : "Fernmeldewesen",
    "name" : "Verteiler",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Verteiler.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Verteiler.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Verteiler.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Verteiler.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:katastrophenschutz-einheiten:veterinarzug" : {
    "id" : "jonas-koeritz:katastrophenschutz-einheiten:veterinarzug",
    "group" : "Katastrophenschutz Einheiten",
    "name" : "Veterinärzug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Katastrophenschutz_Einheiten/Veterinärzug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Katastrophenschutz_Einheiten/Veterinärzug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Katastrophenschutz_Einheiten/Veterinärzug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Katastrophenschutz_Einheiten/Veterinärzug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:gefahren:vollbrand" : {
    "id" : "jonas-koeritz:gefahren:vollbrand",
    "group" : "Gefahren",
    "name" : "Vollbrand",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Gefahren/Vollbrand.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Gefahren/Vollbrand.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Gefahren/Vollbrand.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Gefahren/Vollbrand.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:wache" : {
    "id" : "jonas-koeritz:einrichtungen:wache",
    "group" : "Einrichtungen",
    "name" : "Wache",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Wache.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Wache.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Wache.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Wache.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:wasserfahrzeug" : {
    "id" : "jonas-koeritz:fahrzeuge:wasserfahrzeug",
    "group" : "Fahrzeuge",
    "name" : "Wasserfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Wasserfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Wasserfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Wasserfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Wasserfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:wasserrettungsgruppe" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:wasserrettungsgruppe",
    "group" : "Wasserrettung Einheiten",
    "name" : "Wasserrettungsgruppe",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Wasserrettungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Wasserrettungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Wasserrettungsgruppe.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Wasserrettungsgruppe.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:wasserrettungstrupp" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:wasserrettungstrupp",
    "group" : "Wasserrettung Einheiten",
    "name" : "Wasserrettungstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Wasserrettungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Wasserrettungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Wasserrettungstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Wasserrettungstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:wasserrettungsverband" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:wasserrettungsverband",
    "group" : "Wasserrettung Einheiten",
    "name" : "Wasserrettungsverband",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Wasserrettungsverband.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Wasserrettungsverband.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Wasserrettungsverband.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Wasserrettungsverband.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:wasserrettungszug" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:wasserrettungszug",
    "group" : "Wasserrettung Einheiten",
    "name" : "Wasserrettungszug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Wasserrettungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Wasserrettungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Wasserrettungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Wasserrettungszug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:wassertruppfuhrer" : {
    "id" : "jonas-koeritz:feuerwehr-personen:wassertruppfuhrer",
    "group" : "Feuerwehr Personen",
    "name" : "Wassertruppführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Wassertruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Wassertruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Wassertruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Wassertruppführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:wassertruppmann" : {
    "id" : "jonas-koeritz:feuerwehr-personen:wassertruppmann",
    "group" : "Feuerwehr Personen",
    "name" : "Wassertruppmann",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Wassertruppmann.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Wassertruppmann.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Wassertruppmann.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Wassertruppmann.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fahrzeuge:wechselladerfahrzeug" : {
    "id" : "jonas-koeritz:fahrzeuge:wechselladerfahrzeug",
    "group" : "Fahrzeuge",
    "name" : "Wechselladerfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fahrzeuge/Wechselladerfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fahrzeuge/Wechselladerfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fahrzeuge/Wechselladerfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fahrzeuge/Wechselladerfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:kommunal-fahrzeuge:wechselladerfahrzeug" : {
    "id" : "jonas-koeritz:kommunal-fahrzeuge:wechselladerfahrzeug",
    "group" : "Kommunal Fahrzeuge",
    "name" : "Wechselladerfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Kommunal_Fahrzeuge/Wechselladerfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Kommunal_Fahrzeuge/Wechselladerfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Kommunal_Fahrzeuge/Wechselladerfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Kommunal_Fahrzeuge/Wechselladerfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:wechselladerfahrzeug" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:wechselladerfahrzeug",
    "group" : "THW Fahrzeuge",
    "name" : "Wechselladerfahrzeug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/Wechselladerfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/Wechselladerfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/Wechselladerfahrzeug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/Wechselladerfahrzeug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:weitverkehrstrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:weitverkehrstrupp",
    "group" : "THW Einheiten",
    "name" : "Weitverkehrstrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Weitverkehrstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Weitverkehrstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Weitverkehrstrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Weitverkehrstrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einrichtungen:werkstatt" : {
    "id" : "jonas-koeritz:einrichtungen:werkstatt",
    "group" : "Einrichtungen",
    "name" : "Werkstatt",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einrichtungen/Werkstatt.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einrichtungen/Werkstatt.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einrichtungen/Werkstatt.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einrichtungen/Werkstatt.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:wahltelefon" : {
    "id" : "jonas-koeritz:fernmeldewesen:wahltelefon",
    "group" : "Fernmeldewesen",
    "name" : "Wähltelefon",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Wähltelefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Wähltelefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Wähltelefon.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Wähltelefon.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:wahltelefon-awitel" : {
    "id" : "jonas-koeritz:fernmeldewesen:wahltelefon-awitel",
    "group" : "Fernmeldewesen",
    "name" : "Wähltelefon AWITEL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Wähltelefon_AWITEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Wähltelefon_AWITEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Wähltelefon_AWITEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Wähltelefon_AWITEL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:wahltelefon-ip" : {
    "id" : "jonas-koeritz:fernmeldewesen:wahltelefon-ip",
    "group" : "Fernmeldewesen",
    "name" : "Wähltelefon IP",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Wähltelefon_IP.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Wähltelefon_IP.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Wähltelefon_IP.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Wähltelefon_IP.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:wahltelefon-isdn" : {
    "id" : "jonas-koeritz:fernmeldewesen:wahltelefon-isdn",
    "group" : "Fernmeldewesen",
    "name" : "Wähltelefon ISDN",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Wähltelefon_ISDN.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Wähltelefon_ISDN.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Wähltelefon_ISDN.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Wähltelefon_ISDN.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:wahltelefon-satellit" : {
    "id" : "jonas-koeritz:fernmeldewesen:wahltelefon-satellit",
    "group" : "Fernmeldewesen",
    "name" : "Wähltelefon Satellit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Wähltelefon_Satellit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Wähltelefon_Satellit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Wähltelefon_Satellit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Wähltelefon_Satellit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:wahltelefon-up0" : {
    "id" : "jonas-koeritz:fernmeldewesen:wahltelefon-up0",
    "group" : "Fernmeldewesen",
    "name" : "Wähltelefon Up0",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Wähltelefon_Up0.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Wähltelefon_Up0.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Wähltelefon_Up0.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Wähltelefon_Up0.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:wahltelefon-analog" : {
    "id" : "jonas-koeritz:fernmeldewesen:wahltelefon-analog",
    "group" : "Fernmeldewesen",
    "name" : "Wähltelefon analog",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Wähltelefon_analog.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Wähltelefon_analog.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Wähltelefon_analog.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Wähltelefon_analog.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:zerstort" : {
    "id" : "jonas-koeritz:schaden:zerstort",
    "group" : "Schäden",
    "name" : "Zerstört",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Zerstört.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Zerstört.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Zerstört.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Zerstört.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:zu-transportierende-person" : {
    "id" : "jonas-koeritz:personen:zu-transportierende-person",
    "group" : "Personen",
    "name" : "Zu transportierende Person",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Zu_transportierende_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Zu_transportierende_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Zu_transportierende_Person.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Zu_transportierende_Person.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:zug" : {
    "id" : "jonas-koeritz:thw-einheiten:zug",
    "group" : "THW Einheiten",
    "name" : "Zug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Zug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:einheiten:zug" : {
    "id" : "jonas-koeritz:einheiten:zug",
    "group" : "Einheiten",
    "name" : "Zug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Einheiten/Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Einheiten/Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Einheiten/Zug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Einheiten/Zug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:zug-technik-und-sicherheit" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:zug-technik-und-sicherheit",
    "group" : "Rettungswesen Einheiten",
    "name" : "Zug Technik und Sicherheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Zug_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Zug_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Zug_Technik_und_Sicherheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Zug_Technik_und_Sicherheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-einheiten:zug-fur-soziale-betreuung" : {
    "id" : "jonas-koeritz:rettungswesen-einheiten:zug-fur-soziale-betreuung",
    "group" : "Rettungswesen Einheiten",
    "name" : "Zug für Soziale Betreuung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Einheiten/Zug_für_Soziale_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Einheiten/Zug_für_Soziale_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Einheiten/Zug_für_Soziale_Betreuung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Einheiten/Zug_für_Soziale_Betreuung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:personen:zugfuhrer" : {
    "id" : "jonas-koeritz:personen:zugfuhrer",
    "group" : "Personen",
    "name" : "Zugführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Personen/Zugführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Personen/Zugführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Personen/Zugführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Personen/Zugführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:bundeswehr-personen:zugfuhrer" : {
    "id" : "jonas-koeritz:bundeswehr-personen:zugfuhrer",
    "group" : "Bundeswehr Personen",
    "name" : "Zugführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Bundeswehr_Personen/Zugführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Bundeswehr_Personen/Zugführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Bundeswehr_Personen/Zugführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Bundeswehr_Personen/Zugführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:zugfuhrer" : {
    "id" : "jonas-koeritz:thw-personen:zugfuhrer",
    "group" : "THW Personen",
    "name" : "Zugführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Zugführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Zugführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Zugführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Zugführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:zugfuhrer-betreuungszug" : {
    "id" : "jonas-koeritz:rettungswesen-personen:zugfuhrer-betreuungszug",
    "group" : "Rettungswesen Personen",
    "name" : "Zugführer Betreuungszug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Zugführer_Betreuungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Zugführer_Betreuungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Zugführer_Betreuungszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Zugführer_Betreuungszug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:zugfuhrer-einsatzeinheit" : {
    "id" : "jonas-koeritz:rettungswesen-personen:zugfuhrer-einsatzeinheit",
    "group" : "Rettungswesen Personen",
    "name" : "Zugführer Einsatzeinheit",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Zugführer_Einsatzeinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Zugführer_Einsatzeinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Zugführer_Einsatzeinheit.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Zugführer_Einsatzeinheit.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:zugfuhrer-fz-fk" : {
    "id" : "jonas-koeritz:thw-personen:zugfuhrer-fz-fk",
    "group" : "THW Personen",
    "name" : "Zugführer FZ FK",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Zugführer_FZ_FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Zugführer_FZ_FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Zugführer_FZ_FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Zugführer_FZ_FK.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:feuerwehr-personen:zugfuhrer-loschzug" : {
    "id" : "jonas-koeritz:feuerwehr-personen:zugfuhrer-loschzug",
    "group" : "Feuerwehr Personen",
    "name" : "Zugführer Löschzug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Feuerwehr_Personen/Zugführer_Löschzug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Feuerwehr_Personen/Zugführer_Löschzug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Feuerwehr_Personen/Zugführer_Löschzug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Feuerwehr_Personen/Zugführer_Löschzug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:rettungswesen-personen:zugfuhrer-sanitatszug" : {
    "id" : "jonas-koeritz:rettungswesen-personen:zugfuhrer-sanitatszug",
    "group" : "Rettungswesen Personen",
    "name" : "Zugführer Sanitätszug",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Rettungswesen_Personen/Zugführer_Sanitätszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Rettungswesen_Personen/Zugführer_Sanitätszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Rettungswesen_Personen/Zugführer_Sanitätszug.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Rettungswesen_Personen/Zugführer_Sanitätszug.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:zugtrupp" : {
    "id" : "jonas-koeritz:thw-einheiten:zugtrupp",
    "group" : "THW Einheiten",
    "name" : "Zugtrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Zugtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Zugtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Zugtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Zugtrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:katastrophenschutz-einheiten:zugtrupp" : {
    "id" : "jonas-koeritz:katastrophenschutz-einheiten:zugtrupp",
    "group" : "Katastrophenschutz Einheiten",
    "name" : "Zugtrupp",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Katastrophenschutz_Einheiten/Zugtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Katastrophenschutz_Einheiten/Zugtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Katastrophenschutz_Einheiten/Zugtrupp.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Katastrophenschutz_Einheiten/Zugtrupp.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:zugtrupp-fz-fk" : {
    "id" : "jonas-koeritz:thw-einheiten:zugtrupp-fz-fk",
    "group" : "THW Einheiten",
    "name" : "Zugtrupp FZ-FK",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Zugtrupp_FZ-FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Zugtrupp_FZ-FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Zugtrupp_FZ-FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Zugtrupp_FZ-FK.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:zugtrupp-fz-logistik" : {
    "id" : "jonas-koeritz:thw-einheiten:zugtrupp-fz-logistik",
    "group" : "THW Einheiten",
    "name" : "Zugtrupp FZ-Logistik",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Zugtrupp_FZ-Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Zugtrupp_FZ-Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Zugtrupp_FZ-Logistik.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Zugtrupp_FZ-Logistik.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:wasserrettung-einheiten:zugtrupp-wasserrettung" : {
    "id" : "jonas-koeritz:wasserrettung-einheiten:zugtrupp-wasserrettung",
    "group" : "Wasserrettung Einheiten",
    "name" : "Zugtrupp Wasserrettung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Wasserrettung_Einheiten/Zugtrupp_Wasserrettung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Wasserrettung_Einheiten/Zugtrupp_Wasserrettung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Wasserrettung_Einheiten/Zugtrupp_Wasserrettung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Wasserrettung_Einheiten/Zugtrupp_Wasserrettung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:zugtruppfuhrer" : {
    "id" : "jonas-koeritz:thw-personen:zugtruppfuhrer",
    "group" : "THW Personen",
    "name" : "Zugtruppführer",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Zugtruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Zugtruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Zugtruppführer.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Zugtruppführer.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-personen:zugtruppfuhrer-fz-fk" : {
    "id" : "jonas-koeritz:thw-personen:zugtruppfuhrer-fz-fk",
    "group" : "THW Personen",
    "name" : "Zugtruppführer FZ FK",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Personen/Zugtruppführer_FZ_FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Personen/Zugtruppführer_FZ_FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Personen/Zugtruppführer_FZ_FK.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Personen/Zugtruppführer_FZ_FK.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-fahrzeuge:kleines-boot" : {
    "id" : "jonas-koeritz:thw-fahrzeuge:kleines-boot",
    "group" : "THW Fahrzeuge",
    "name" : "kleines Boot",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Fahrzeuge/kleines_Boot.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Fahrzeuge/kleines_Boot.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Fahrzeuge/kleines_Boot.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Fahrzeuge/kleines_Boot.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fuhrungsstellen:oel" : {
    "id" : "jonas-koeritz:fuhrungsstellen:oel",
    "group" : "Führungsstellen",
    "name" : "ÖEL",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Führungsstellen/ÖEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Führungsstellen/ÖEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Führungsstellen/ÖEL.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Führungsstellen/ÖEL.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:ma-nahmen:olschadenbekampfung" : {
    "id" : "jonas-koeritz:ma-nahmen:olschadenbekampfung",
    "group" : "Maßnahmen",
    "name" : "Ölschadenbekämpfung",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Maßnahmen/Ölschadenbekämpfung.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Maßnahmen/Ölschadenbekämpfung.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Maßnahmen/Ölschadenbekämpfung.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Maßnahmen/Ölschadenbekämpfung.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:thw-einheiten:ortliche-gefahrenabwehr" : {
    "id" : "jonas-koeritz:thw-einheiten:ortliche-gefahrenabwehr",
    "group" : "THW Einheiten",
    "name" : "Örtliche Gefahrenabwehr",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/THW_Einheiten/Örtliche_Gefahrenabwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/THW_Einheiten/Örtliche_Gefahrenabwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/THW_Einheiten/Örtliche_Gefahrenabwehr.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/THW_Einheiten/Örtliche_Gefahrenabwehr.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:schaden:uberschwemmtes-gebiet" : {
    "id" : "jonas-koeritz:schaden:uberschwemmtes-gebiet",
    "group" : "Schäden",
    "name" : "Überschwemmtes Gebiet",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Schäden/Überschwemmtes_Gebiet.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Schäden/Überschwemmtes_Gebiet.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Schäden/Überschwemmtes_Gebiet.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Schäden/Überschwemmtes_Gebiet.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:ubertrager-1" : {
    "id" : "jonas-koeritz:fernmeldewesen:ubertrager-1",
    "group" : "Fernmeldewesen",
    "name" : "Übertrager-1",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Übertrager-1.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Übertrager-1.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Übertrager-1.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Übertrager-1.svg",
      "fileType" : "svg"
    } ]
  },
  "jonas-koeritz:fernmeldewesen:ubertrager-2" : {
    "id" : "jonas-koeritz:fernmeldewesen:ubertrager-2",
    "group" : "Fernmeldewesen",
    "name" : "Übertrager-2",
    "author" : {
      "id" : "jonas-koeritz",
      "name" : "Jonas Köritz",
      "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
      "license" : "Apache License 2.0"
    },
    "sizes" : [ {
      "path" : "jonas-koeritz/png/256/Fernmeldewesen/Übertrager-2.png",
      "fileType" : "png",
      "size" : {
        "width" : 256,
        "height" : 256
      }
    }, {
      "path" : "jonas-koeritz/png/512/Fernmeldewesen/Übertrager-2.png",
      "fileType" : "png",
      "size" : {
        "width" : 512,
        "height" : 512
      }
    }, {
      "path" : "jonas-koeritz/png/1024/Fernmeldewesen/Übertrager-2.png",
      "fileType" : "png",
      "size" : {
        "width" : 1024,
        "height" : 1024
      }
    }, {
      "path" : "jonas-koeritz/svg/Fernmeldewesen/Übertrager-2.svg",
      "fileType" : "svg"
    } ]
  }
};

export const groups: string[] = [ "Bundeswehr Einheiten", "Bundeswehr Fahrzeuge", "Bundeswehr Personen", "Einheiten", "Einrichtungen", "Fahrzeuge", "Fernmeldewesen", "Feuerwehr Einheiten", "Feuerwehr Fahrzeuge", "Feuerwehr Gebäude", "Feuerwehr Personen", "Führungsstellen", "Gebäude", "Gefahren", "Katastrophenschutz Einheiten", "Kommunal Fahrzeuge", "Maßnahmen", "Personen", "Polizei Einheiten", "Polizei Fahrzeuge", "Rettungswesen Einheiten", "Rettungswesen Einrichtungen", "Rettungswesen Fahrzeuge", "Rettungswesen Personen", "Schäden", "Sonstiges", "THW Einheiten", "THW Fahrzeuge", "THW Gebäude", "THW Personen", "Wasserrettung Einheiten", "Zoll Einheiten", "Zoll Fahrzeuge", "gelb", "rot", "weiß" ];

export const authors: {[key: string]: Author} = {
  "jonas-koeritz" : {
    "id" : "jonas-koeritz",
    "name" : "Jonas Köritz",
    "sourceUrl" : "https://github.com/jonas-koeritz/Taktische-Zeichen",
    "license" : "Apache License 2.0"
  }
};
