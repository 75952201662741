
import {computed, defineComponent, ref, watch} from 'vue';
import MainNavigation from "@/components/MainNavigation.vue";
import MainTabsNavigation from "@/components/MainTabsNavigation.vue";
import {SearchIcon} from "@heroicons/vue/outline";
import {useRoute, useRouter} from "vue-router";
import {useMeta} from "vue-meta";

export default defineComponent({
  name: 'App',
  components: {
    MainNavigation,
    MainTabsNavigation,
    SearchIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const developmentWarningDismissed = ref<boolean>(false);

    try {
      developmentWarningDismissed.value = !!sessionStorage.getItem("DEVELOPMENT_WARNING_DISMISSED")
    } catch (e) {
      console.warn("Failed to read session storage", e);
    }

    watch(developmentWarningDismissed, newValue => {
      try {
        sessionStorage.setItem("DEVELOPMENT_WARNING_DISMISSED", `${newValue}`);
      } catch (e) {
        console.warn("Failed to write to session storage", e);
      }
    });

    const searchKeywordQuery = computed<string | null>(() => {
      const q = route.query.q;
      if (Array.isArray(q)) {
        return q[0] || "";
      } else {
        return q || "";
      }
    });

    const searchKeyword = ref<string | null>(searchKeywordQuery.value);

    watch(searchKeywordQuery, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        searchKeyword.value = newValue;
      }
    });

    useMeta({
      title: 'Taktische-Zeichen.org'
    });

    const search = function() {
      const q = searchKeyword.value;
      const l = undefined;
      const path = route.path;
      const params = route.params;
      const query = Object.assign({}, route.query, {q, l});
      router.push({path, params, query});
    };

    return {
      developmentWarningDismissed,
      searchKeywordQuery,
      searchKeyword,
      search
    };
  }
});
