<template>
  <div class="flex flex-row p-2">
    <router-link
        :to="{ name: 'search' }"
        class="mx-2 px-3 flex flex-row items-center bg-blue-300 hover:bg-blue-700 hover:text-white rounded"
        active-class="bg-blue-700 text-white"
    >
      <SearchIcon class="h-5 w-5 mr-1"/>
      Durchsuchen
    </router-link>
    <router-link
        :to="{ name: 'print' }"
        class="mx-2 px-3 flex flex-row items-center bg-red-300 hover:bg-red-700 hover:text-white rounded"
        active-class="bg-red-700 text-white"
    >
      <PrinterIcon class="h-5 w-5 mr-1"/>
      Druckvorlage
    </router-link>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {SearchIcon, PrinterIcon} from "@heroicons/vue/outline";

export default defineComponent({
  name: 'MainNavigation',
  components: {
    SearchIcon,
    PrinterIcon,
  }
});
</script>
