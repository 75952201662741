<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex flex-col items-center justify-center
                mt-5 md:mt-32 p-5
                bg-white
                w-full sm:w-auto
                sm:rounded-xl
                shadow-md">
      <h1 class="text-xl text-center">
        Willkommen auf<br/>
        <strong class="text-3xl my-2 inline-block">Taktische-Zeichen.org</strong><br/>
        deine Quelle für &hellip;
      </h1>

      <div class="mt-5 p-3 h-32 flex flex-row items-center justify-around">
        <transition-group name="fading">
          <img
              v-for="symbol of preview"
              :key="symbol.id"
              :src="symbol.src"
              :alt="symbol.alt"
              :title="symbol.title"
              class="w-14 md:w-20 m-2"
          >
        </transition-group>
      </div>

      <div class="mt-8 flex flex-col md:flex-row justify-center">
        <router-link
            :to="{ name: 'search' }"
            class="m-2 flex flex-row items-center px-4 py-2 bg-blue-700 text-white rounded"
        >
          <SearchIcon class="h-5 w-5 mr-1"/>
          Taktische Zeichen durchsuchen
        </router-link>
        <router-link
            :to="{ name: 'print' }"
            class="m-2 flex flex-row items-center px-4 py-2 bg-red-700 text-white rounded"
        >
          <PrinterIcon class="h-5 w-5 mr-1"/>
          Druckvorlage erstellen
        </router-link>
      </div>
    </div>

    <div class="flex flex-col
                mt-5 p-5
                bg-white
                w-full sm:w-auto
                sm:rounded-xl
                shadow-md">
      <p class="mb-2">Version: {{ appVersion }}.</p>

      <p class="mb-2">Disclaimer: Taktische-Zeichen.org ist ein <strong>private Projekt</strong>.</p>

      <p class="mb-2">Taktische-Zeichen.org von <a href="https://gitlab.com/tristanlins/taktische-zeichen.org" target="_blank" rel="noreferrer nofollow noopener" class="text-blue-500">Tristan Lins</a>.<br>
      Lizensiert unter der Apache License 2.0.</p>

      <p v-for="author of authors" :key="author.id">Taktische Zeichen von <a :href="author.sourceUrl" target="_blank" rel="noreferrer nofollow noopener" class="text-blue-500">{{ author.name }}</a>.<br>
        Lizensiert unter der {{ author.license }}.</p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';
import {PrinterIcon, SearchIcon} from '@heroicons/vue/outline'
import {symbols, authors} from '@/symbols';

interface PreviewSymbol {
  id: string;
  src: string;
  alt: string;
  title: string;
}

const appVersion = process.env.VUE_APP_VERSION || "<unknown>"

export default defineComponent({
  name: 'Home',
  components: {
    SearchIcon,
    PrinterIcon
  },
  setup() {
    let keys = Object.keys(symbols);

    const nextPreviewSymbols = function (): PreviewSymbol[] {
      if (keys.length < 3) {
        keys = Object.keys(symbols);
      }

      const randomKeys: string[] = [];
      while (randomKeys.length < 3) {
        randomKeys.push(...keys.splice(Math.floor(Math.random() * keys.length), 1));
      }

      return randomKeys.map(randomKey => {
        const symbol = symbols[randomKey];
        const id = symbol.id;
        const sizes = symbol.sizes;
        const src = sizes[sizes.length - 1].path;
        const alt = symbol.group + ": " + symbol.name;
        const title = symbol.group + ": " + symbol.name;
        return {id, src, alt, title};
      });
    }

    const preview = ref<PreviewSymbol[]>(nextPreviewSymbols());

    let interval = 0;

    onMounted(() => {
      interval = setInterval(() => {
        preview.value.splice(0);

        setTimeout(() => {
          preview.value.push(...nextPreviewSymbols());
        }, 500);
      }, 6000);
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    return {
      preview,
      authors,
      appVersion
    };
  }
});
</script>
