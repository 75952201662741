
import {defineComponent} from 'vue';
import {SearchIcon, PrinterIcon, HomeIcon} from "@heroicons/vue/outline";

export default defineComponent({
  name: 'MainNavigation',
  components: {
    HomeIcon,
    SearchIcon,
    PrinterIcon,
  }
});
