<template>
  <div class="flex flex-row items-stretch justify-center">
    <router-link
        :to="{ name: 'home' }"
        class="p-3 flex flex-row items-center text-blue-300 hover:text-blue-700 text-center"
        active-class="text-blue-700 bg-gradient-to-t from-gray-100 to-gray-200"
        title="Startseite"
    >
      <HomeIcon class="h-5 w-5 mr-1"/>
    </router-link>
    <router-link
        :to="{ name: 'search' }"
        class="p-3 flex flex-row items-center text-blue-300 hover:text-blue-700 text-center"
        active-class="text-blue-700 bg-gradient-to-t from-gray-100 to-gray-200"
        title="Durchsuchen"
    >
      <SearchIcon class="h-5 w-5 mr-1"/>
    </router-link>
    <router-link
        :to="{ name: 'print' }"
        class="p-3 flex flex-row items-center text-red-300 hover:text-red-700 text-center"
        active-class="text-red-700 bg-gradient-to-t from-gray-100 to-gray-200"
        title="Druckvorlage"
    >
      <PrinterIcon class="h-5 w-5 mr-1"/>
    </router-link>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {SearchIcon, PrinterIcon, HomeIcon} from "@heroicons/vue/outline";

export default defineComponent({
  name: 'MainNavigation',
  components: {
    HomeIcon,
    SearchIcon,
    PrinterIcon,
  }
});
</script>
