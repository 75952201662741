import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
    {
        name: 'home',
        path: '/',
        component: Home
    },
    {
        name: 'search',
        path: '/suchen',
        component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue')
    },
    {
        name: 'symbol-details',
        path: '/suchen/:id',
        component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue')
    },
    {
        name: 'print',
        path: '/drucken',
        component: () => import(/* webpackChunkName: "print-template" */ '../views/PrintTemplate.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
