
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';
import {PrinterIcon, SearchIcon} from '@heroicons/vue/outline'
import {symbols, authors} from '@/symbols';

interface PreviewSymbol {
  id: string;
  src: string;
  alt: string;
  title: string;
}

const appVersion = process.env.VUE_APP_VERSION || "<unknown>"

export default defineComponent({
  name: 'Home',
  components: {
    SearchIcon,
    PrinterIcon
  },
  setup() {
    let keys = Object.keys(symbols);

    const nextPreviewSymbols = function (): PreviewSymbol[] {
      if (keys.length < 3) {
        keys = Object.keys(symbols);
      }

      const randomKeys: string[] = [];
      while (randomKeys.length < 3) {
        randomKeys.push(...keys.splice(Math.floor(Math.random() * keys.length), 1));
      }

      return randomKeys.map(randomKey => {
        const symbol = symbols[randomKey];
        const id = symbol.id;
        const sizes = symbol.sizes;
        const src = sizes[sizes.length - 1].path;
        const alt = symbol.group + ": " + symbol.name;
        const title = symbol.group + ": " + symbol.name;
        return {id, src, alt, title};
      });
    }

    const preview = ref<PreviewSymbol[]>(nextPreviewSymbols());

    let interval = 0;

    onMounted(() => {
      interval = setInterval(() => {
        preview.value.splice(0);

        setTimeout(() => {
          preview.value.push(...nextPreviewSymbols());
        }, 500);
      }, 6000);
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    return {
      preview,
      authors,
      appVersion
    };
  }
});
